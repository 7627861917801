<template>
  <div class="analytics-overview">
    <perfect-scrollbar>
      <b-spinner label="Spinning" v-if="isLoading"></b-spinner>
      <div :class="[{ loading: isLoading }]">
        <div class="kpi-container">
          <div class="kpi-item">
            <div class="left">
              <div class="value">
                {{
                  $options.setNumberFormat(
                    data.kpi.total_searches.value,
                    0,
                    ",",
                    "."
                  )
                }}
              </div>
              <div class="label">
                {{ $t("analytics.overview.total_searches") }}
              </div>
            </div>
            <div class="right">
              <div
                :class="[
                  'chip-rate',
                  { negative: data.kpi.total_searches.rate < 0 },
                ]"
              >
                <span class="icon"> </span>
                {{
                  $options.setNumberFormat(
                    data.kpi.total_searches.rate,
                    1,
                    ",",
                    "."
                  )
                }}%
              </div>
            </div>
          </div>
          <div class="kpi-item">
            <div class="left">
              <div class="value">
                {{
                  $options.setNumberFormat(
                    data.kpi.search_intents.value,
                    0,
                    ",",
                    "."
                  )
                }}
              </div>
              <div class="label">
                {{ $t("analytics.overview.search_intents") }}
              </div>
            </div>
            <div class="right">
              <div
                :class="[
                  'chip-rate',
                  { negative: data.kpi.search_intents.rate < 0 },
                ]"
              >
                <span class="icon"> </span>
                {{
                  $options.setNumberFormat(
                    data.kpi.search_intents.rate,
                    1,
                    ",",
                    "."
                  )
                }}%
              </div>
            </div>
          </div>
          <div class="kpi-item">
            <div class="left">
              <div class="value">
                {{
                  $options.setNumberFormat(
                    data.kpi.total_clicks.value,
                    0,
                    ",",
                    "."
                  )
                }}
              </div>
              <div class="label">
                {{ $t("analytics.overview.total_clicks") }}
              </div>
            </div>
            <div class="right">
              <div
                :class="[
                  'chip-rate',
                  { negative: data.kpi.total_clicks.rate < 0 },
                ]"
              >
                <span class="icon"> </span>
                {{
                  $options.setNumberFormat(
                    data.kpi.total_clicks.rate,
                    1,
                    ",",
                    "."
                  )
                }}%
              </div>
            </div>
          </div>
          <div class="kpi-item">
            <div class="left">
              <div class="value">
                {{
                  $options.setNumberFormat(
                    data.kpi.exit_rate.value,
                    2,
                    ",",
                    "."
                  )
                }}
              </div>
              <div class="label">{{ $t("analytics.overview.exit_rate") }}</div>
            </div>
            <div class="right">
              <div
                :class="[
                  'chip-rate',
                  { negative: data.kpi.exit_rate.rate < 0 },
                ]"
              >
                <span class="icon"> </span>
                {{
                  $options.setNumberFormat(
                    data.kpi.exit_rate.rate,
                    1,
                    ",",
                    "."
                  )
                }}%
              </div>
            </div>
          </div>
        </div>
        <div
          :class="[
            'funnel-container',
            { with_conversions: !!data.kpi.detail.withConversions },
          ]"
        >
          <div class="left">
            <div class="content-container">
              <div class="value-container">
                <div class="value">
                  {{
                    $options.setNumberFormat(
                      data.kpi.use_search.value,
                      1,
                      ",",
                      "."
                    )
                  }}%
                </div>
                <div
                  :class="[
                    'chip-rate',
                    { negative: data.kpi.use_search.rate < 0 },
                  ]"
                >
                  <span class="icon"> </span>
                  {{
                    $options.setNumberFormat(
                      data.kpi.use_search.rate,
                      1,
                      ",",
                      "."
                    )
                  }}%
                </div>
              </div>
              <div class="label">
                <span class="icon"> </span>
                {{ $t("analytics.overview.use_search") }}
              </div>
              <div class="dontuse-container">
                <div class="value">
                  {{
                    $options.setNumberFormat(
                      100 - data.kpi.use_search.value,
                      1,
                      ",",
                      "."
                    )
                  }}%
                </div>
                <div class="label">
                  {{ $t("analytics.overview.dont_use_search") }}
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="with-results">
              <span class="value">
                {{
                  $options.setNumberFormat(
                    data.kpi.detail.withResults,
                    1,
                    ",",
                    "."
                  )
                }}%
              </span>
              w/ Results
            </div>
            <div class="with-clicks">
              <span class="value">{{
                $options.setNumberFormat(
                  data.kpi.detail.withClicks,
                  1,
                  ",",
                  "."
                )
              }}</span
              >% w/ Clicks
            </div>
            <div
              class="with-conversions"
              v-if="!!data.kpi.detail.withConversions"
            >
              <span class="value">{{
                $options.setNumberFormat(
                  data.kpi.detail.withConversions,
                  1,
                  ",",
                  "."
                )
              }}</span
              >% w/ Conversions
            </div>
            <div class="without-results">
              <span class="value">{{
                $options.setNumberFormat(
                  100 - data.kpi.detail.withResults,
                  1,
                  ",",
                  "."
                )
              }}</span
              >% w/o Results
            </div>
            <div class="without-clicks">
              <span class="value">{{
                $options.setNumberFormat(
                  100 - data.kpi.detail.withClicks,
                  1,
                  ",",
                  "."
                )
              }}</span
              >% w/o Clicks
            </div>
            <div
              class="without-conversions"
              v-if="!!data.kpi.detail.withConversions"
            >
              <span class="value">{{
                $options.setNumberFormat(
                  100 - data.kpi.detail.withConversions,
                  1,
                  ",",
                  "."
                )
              }}</span>
              % w/o Conversions
            </div>
          </div>
        </div>
        <div :class="['charts-container']">
          <apexchart
            class="chart"
            height="300"
            :options="searchChartOptions"
            :series="searchChartData"
          ></apexchart>
          <apexchart
            class="chart"
            height="300"
            :options="totalSearchesChartOptions"
            :series="totalSearchesChartData"
          ></apexchart>
          <apexchart
            class="chart"
            height="300"
            :options="rateChartOptions"
            :series="rateChartData"
          ></apexchart>
          <!-- <div v-if="conversionsChartData[0].data.length>0">
            <div class="conversion-selector-container">
              <div :class="['conversion-selector',{selected:selectedConversions=='conversions'}]" @click="()=>selectedConversions='conversions'"> {{$t('analytics.overview.conversions')}} </div>
              <div :class="['conversion-selector',{selected:selectedConversions=='averageValue'}]" @click="()=>selectedConversions='averageValue'"> {{$t('analytics.overview.average_value')}} </div>
              <div :class="['conversion-selector',{selected:selectedConversions=='totalValue'}]" @click="()=>selectedConversions='totalValue'"> {{$t('analytics.overview.total_value')}} </div>
            </div>
          </div>
          <apexchart class="chart" v-if="conversionsChartData[0].data.length>0" height="300" :options="conversionsChartOptions" :series="conversionsChartData"></apexchart> -->
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import Utils from "@/utils/Utils"
import axios from "@/utils/Axios"
import axiosLib from "axios"
import lodash from "lodash"
import { mapGetters } from "vuex"

export default {
  name: "AnalyticsOverview",
  setNumberFormat: Utils.setNumberFormat,
  data() {
    return {
      isLoading: false,
      cancelToken: null,
      baseChartOptions: {
        chart: {
          zoom: { enabled: false },
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 400,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          row: {
            opacity: 0.5,
          },
        },
        legend: {
          position: "top",
        },
      },
      data: {
        columns: [],
        data: {
          total_searches: [],
          search_intents: [],
          total_clicks: [],
          conversions: [],
          average_value: [],
          total_value: [],
          use_vearch: [],
          exit_rate: [],
        },
      },
      selectedConversions: "conversions",
    }
  },
  computed: {
    ...mapGetters([
      "getAnalyticsSelectedDevice",
      "getAnalyticsSelectedDate",
      "getCurrentConfiguration",
    ]),
    searchChartOptions() {
      return {
        ...this.baseChartOptions,
        xaxis: { categories: this.chartColumns },
        colors: ["#FF7F09", "#825EEA"],
        chart: {
          ...this.baseChartOptions.chart,
          // stacked: true,
          type: "area",
        },
      }
    },
    totalSearchesChartOptions() {
      return {
        ...this.baseChartOptions,
        xaxis: { categories: this.chartColumns },
        colors: ["#398FF4"],
        legend: { showForSingleSeries: true, position: "top" },
        chart: {
          ...this.baseChartOptions.chart,
          // stacked: true,
          type: "area",
        },
      }
    },
    conversionsChartOptions() {
      return {
        ...this.baseChartOptions,
        xaxis: { categories: this.chartColumns },
        yaxis: { min: 0 },
        colors: ["#26B570"],
        chart: {
          ...this.baseChartOptions.chart,
          type: "area",
        },
      }
    },
    rateChartOptions() {
      return {
        ...this.baseChartOptions,
        colors: ["#398FF4", "#C94057"],
        xaxis: { categories: this.chartColumns },
        chart: {
          ...this.baseChartOptions.chart,
          type: "line",
        },
        yaxis: {
          min: 0,
          max: (max) => max,
        },
      }
    },
    searchChartData() {
      var dataToReturn = []
      dataToReturn.push(
        {
          name: this.$t("analytics.overview.total_clicks"),
          data: this.data.data["totalClicks"],
        },
        {
          name: this.$t("analytics.overview.search_intents"),
          data: this.data.data["searchIntents"],
        }
      )
      return dataToReturn
    },
    totalSearchesChartData() {
      return [
        {
          name: this.$t("analytics.overview.total_searches"),
          data: this.data.data["totalSearches"],
        },
      ]
    },

    conversionsChartData() {
      return [
        {
          name: this.selectedConversions,
          data: this.data.data[this.selectedConversions],
        },
      ]
    },
    rateChartData() {
      var dataToReturn = []
      dataToReturn.push(
        {
          name: this.$t("analytics.overview.use_search"),
          data: this.data.data["useSearch"],
        },
        {
          name: this.$t("analytics.overview.exit_rate"),
          data: this.data.data["exitRate"],
        }
      )
      return dataToReturn
    },
    chartColumns() {
      return this.data.columns ?? []
    },
  },
  methods: {
    async getInfo() {
      this.isLoading = true
      this.cancelToken?.cancel("cancelled")
      this.cancelToken = axiosLib.CancelToken.source()
      var postObject = {
        device: this.getAnalyticsSelectedDevice,
        date: Utils.fromDateToTimestamp(this.getAnalyticsSelectedDate),
      }
      const response = await axios.post(
        `/analytics/${this.getCurrentConfiguration.id}/overview`,
        postObject,
        { cancelToken: this.cancelToken.token }
      )
      this.data = response.data
      this.isLoading = false
    },
  },
  mounted() {
    this.$root.$on("changed-website", this.getInfo)
    this.$root.$on("update-analytics", this.getInfo)
    this.data = lodash.cloneDeep(Utils.EMPTY_ANALYTICS)
    this.getInfo()
  },
  beforeDestroy() {
    this.$root.$off("changed-website", this.getInfo)
    this.$root.$off("update-analytics", this.getInfo)
  },
}
</script>

<style src="./AnalyticsOverview.less" lang="less" scoped></style>
