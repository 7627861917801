<template>
    <div class="terms-conditions">
    <!-- <div class="terms-conditions" v-if="$i18n.locale=='it'"> -->
        <div class="sub-title"> 1. Premessa </div>
        <div class="paragraph"> 
            Le presenti condizioni contrattuali - unitamente al piano tariffario contestuale o successivo (modifiche in upgrade o downgrade) alla sottoscrizione del presente contratto, nonché agli ulteriori eventuali documenti relativi all’attivazione dei servizi, tutti da intendersi parte integrante e sostanziale del presente contratto -  disciplinano il rapporto che si perfeziona tra AccelaSearch SRL, (PI: 11919200961), con sede in Milano al Viale Sarca 336/F, in persona dell’amministratore p.t. (da questo momento in poi denominato “ACCELASEARCH”) ed il Cliente che sottoscrive le presenti condizioni contrattuali. Col presente contratto ACCELA si obbliga ad eseguire i servizi indicati nel piano a favore del Cliente (da intendersi anche i piani successivi alla sottoscrizione del presente contratto e che rimandano alle presenti clausole) a fronte del corrispettivo concordato nel piano medesimo. 
        </div>

        <div class="sub-title"> 2. Oggetto </div>
        <div class="paragraph"> 
            <p>
            Oggetto del contratto è la fornitura al Cliente del servizio di potenziamento del motore di ricerca per ecommerce denominato “ACCELASEARCH”. Il servizio viene reso in modalità SAAS ovvero “software as service” (di seguito denominato per semplicità “Servizio”). Col presente contratto il ACCELASEARCH si obbliga ad erogare il Servizio, in base al canone prescelto dal Cliente, concedendo relativa licenza d’uso del Software, la quale è da intendersi pertanto limitata al piano prescelto dal Cliente,  non esclusiva e non trasferibile. 
            </p><p>
            La Licenza consente di utilizzare il Software soltanto per gli usi previsti o specificamente autorizzati da ACCELASEARCH.
            </p><p>
            Le funzionalità tecniche del Servizio sono meglio descritte nella scheda tecnica rintracciabile all’url <a href="https://accelasearch.com/#features" target="_blank"> www.accelasearch.com/#features </a>. La gestione delle funzionalità è demandata alla insindacabile valutazione di ACCELASEARCH, in linea con l’intrinseco spirito fiduciario che governa il presente contratto e nel rispetto del know how di ACCELASEARCH, nonché nel rispetto delle risultanti dell’eventuale consulenza fornita al Cliente laddove prevista nel preventivo.
            </p><p>
            Il Cliente dichiara di ricevere i Servizi erogati da ACCELASEARCH ai fini dell’esercizio di un’attività commerciale e non per uso personale, domestico o familiare. Inoltre il Cliente prende atto che ACCELASEARCH utilizzerà l'indirizzo email fornito come metodo di comunicazione.
            </p>
        </div>

        <div class="sub-title"> 3. Tempistiche di attivazione </div>
        <div class="paragraph"> 
            Il Servizio è attivato nel più breve tempo possibile. Il Cliente dichiara di essere al corrente e di accettare che i termini per l'attivazione del servizio, eventualmente prospettati, devono considerarsi meramente indicativi. Il Cliente esonera ACCELASEARCH da qualsivoglia responsabilità in merito alle condizioni, ai tempi di attivazione del servizio o al malfunzionamento dovuto a piattaforme o provider terzi non potendo quindi configurarsi, in capo ad ACCELASEARCH, un’ipotesi di inadempimento contrattuale ex art. 1218 e seguenti del codice civile italiano. 
        </div>

        <div class="sub-title"> 4. Utilizzazione del Software </div>
        <div class="paragraph"> 
            <p>
            Il Cliente ha il diritto di utilizzare il Software concesso in uso tramite l’installazione del Software su un unico sito internet, salvo il caso di multilicenza, così come previsto dalla normativa vigente.
            </p><p>
            Al Cliente è vietato compiere qualsiasi modifica, traduzione, decompilazione, copiatura e/o qualsiasi altra attività non espressamente autorizzata da ACCELASEARCH.
            </p><p>
            Per tutte le funzionalità rientranti nel Servizio, il Cliente accetta che l’attivazione ed il relativo funzionamento del Servizio sono subordinati al corretto funzionamento del sito ecommerce del medesimo.
            </p><p>
            Per il corretto funzionamento del Servizio, il Cliente deve correttamente configurare e abilitare le funzionalità che ACCELASEARCH mette a disposizione. In caso di malfunzionamento derivante dalla errata o mancata esecuzione delle istruzioni fornite da ACCELASEARCH, quest’ultima non potrà essere considerata responsabile per qualsivoglia danno diretto o indiretto.
            </p>
        </div>
        
        <div class="sub-title"> 5. Creazione dell’account </div>
        <div class="paragraph"> 
            <p>
            Per utilizzare il software, il Cliente dovrà procedere alla registrazione del proprio account personale seguendo i passaggi riportati nell’apposita guida presente sul sito. Per completare la registrazione dell’Account il Cliente dovrà indicare i seguenti dati: nome, cognome, indirizzo email, nome dell’azienda, indirizzo sito web, settore merceologico ed eventuali informazioni rilevanti riguardanti l’attività per cui si richiede il servizio. ACCELASEARCH ha la facoltà di rifiutare la richiesta di un Account, o di cancellare un Account esistente, per qualsiasi motivo e a sua esclusiva discrezione. 
            </p><p>
            Ai fini della registrazione e creazione dell’Account Cliente dichiara di avere i seguenti requisiti: (i) almeno 18 anni o (ii) la maggiore età nell’ordinamento del paese in cui risiede e dal quale usa i Servizi. 
            </p><p>
            A seguito della registrazione e del pagamento del piano prescelto, il Cliente è l’unico responsabile della gestione delle credenziali e relativo Account, pertanto è tenuto a conservare la password con diligenza, mantenendo segreti i dati di accesso e sarà, comunque, esclusivamente responsabile di qualsiasi danno causato dalla conoscenza, ovvero dall'utilizzo delle credenziali di utilizzo del servizio da parte di terzi.
            </p>
        </div>

        <div class="sub-title"> 6. Sottoscrizione dell’Abbonamento </div>
        <div class="paragraph"> 
            <p>
            Al termine della prova gratuita, il Cliente potrà accedere ai servizi tramite la sottoscrizione di un abbonamento dietro corrispettivo di un canone mensile, ovvero annuale, selezionando uno dei piani a disposizione. A seconda del piano scelto, il cliente avrà accesso alle funzionalità indicate nel relativo piano scelto ed offerto da ACCELASEARCH per come meglio descritto all’url <a href="https://accelasearch.com/pricing" target="_blank"> https://accelasearch.com/pricing </a>. 
            </p><p>
            Lo sforamento delle funzionalità, anche dette query, oggetto dell’abbonamento prescelto, comporta in automatico l’attivazione del c.d. pacchetto “extra” di queries una tantum nel mese in corso con un costo conforme al  piano scelto e  meglio descritto all’url <a href="https://accelasearch.com/pricing" target="_blank"> https://accelasearch.com/pricing </a>. Il Cliente dichiara di accettare tale attivazione automatica necessaria alla prosecuzione del servizio, fermo restando la possibilità di acquisto, in ogni momento, di un nuovo piano in abbonamento e la restituzione del costo delle queries eventualmente non utilizzate durante la validità del piano extra attivato in automatico.
            </p><p>
            Il pagamento avverrà attraverso i canali selezionati dal Cliente al momento della sottoscrizione: carta di debito o credito.  
            </p><p>
            Qualora non sia possibile elaborare il pagamento delle Commissioni tramite un Metodo di pagamento autorizzato entro 28 giorni dal tentativo iniziale, ACCELASEARCH potrà sospendere e revocare l’accesso all’Account e ai Servizi. L’account verrà riattivato dopo il pagamento di tutte le Commissioni in sospeso. Se le Commissioni in sospeso rimangono non pagate per 60 giorni dalla data di sospensione, ACCELASEARCH si riserva il diritto di chiudere l’Account.
            </p>
        </div>

        <div class="sub-title"> 7. Divieto di cessione </div>
        <div class="paragraph"> 
            È fatto espresso divieto al Cliente di cedere e/o dare in sublicenza il Software a terzi o comunque consentirne l’uso da parte di terzi, sia a titolo gratuito che a titolo oneroso, senza il preventivo consenso scritto di ACCELASEARCH, pena la facoltà in capo ad ACCELASEARCH di risolvere il presente contratto.
        </div>

        <div class="sub-title"> 8. Responsabilità </div>
        <div class="paragraph"> 
            <p>
            La raccolta dei dati avviene materialmente ad opera del Cliente ed è sotto la sua esclusiva responsabilità. Nella raccolta dati il Cliente, responsabile del trattamento, dovrà rispettare quanto previsto dal Regolamento UE 2016/679 (GDPR).
            </p><p>
            ACCELASEARCH non risponde dei danni, diretti o indiretti, comunque subiti dal Cliente o da terzi in dipendenza dell’uso ovvero del mancato uso del Software, fatto salvo quanto inderogabilmente previsto dalla legge.
            </p><p>
            Il Cliente è personalmente e direttamente responsabile per gli atti commessi in violazione delle condizioni per l’utilizzo del Software previste nel precedente art. 3 da parte dei propri dipendenti e/o consulenti e/o incaricati a qualunque titolo.
            </p><p>
            ACCELASEARCH non può essere ritenuta responsabile per brevi malfunzionamenti del Software causati da problemi tecnici su macchinari, server, router, linee telefoniche, reti telematiche, etc.
            </p><p>
            Non sono attribuibili a ACCELASEARCH malfunzionamenti del Software, perdite di dati, diffusione accidentale di dati personali o sensibili, e qualsiasi altro tipo di danno verificatosi a seguito di attacchi da parte di pirati informatici, ladri, hacker, cracker, virus, etc.
            </p><p>
            ACCELASEARCH non assume alcuna responsabilità per disservizi, interruzioni e/o danni imputabili a causa di forza maggiore quali incidenti, incendi, esplosioni, scioperi, serrate, terremoti, disastri, alluvioni, sommosse, ed altri eventi di difficile o impossibile previsione che impedisca l’utilizzo del Software, da parte del Cliente.
            </p><p>
            ACCELASEARCH non è responsabile del malfunzionamento del Software a causa di non conformità e/o obsolescenza degli apparecchi dei quali il Cliente è dotato. Non è altresì responsabile di malfunzionamenti del software derivanti da incompatibilità con il software del portale internet su cui viene installato.
            </p>
        </div>

        <div class="sub-title"> 9. Durata </div>
        <div class="paragraph"> 
            Il presente contratto ha validità a decorrere dalla sottoscrizione dello stesso e la durata segue il piano di abbonamento prescelto dal Cliente al momento della sottoscrizione delle presenti condizioni legali o di quello modificato in un momento successivo alla sottoscrizione (per come previsto al precedente art. 6). . Alla scadenza, il contratto - e dunque il relativo abbonamento prescelto o modificato -  si rinnoverà automaticamente per una durata pari all’ultimo abbonamento attivo. Il Cliente può recedere dal rinnovo automatico tramite l'apposita funzionalità di recesso dall’abbonamento presente in dashboard.
            <br>
            In caso di recesso prima della scadenza naturale dell’abbonamento, ACCELASEARCH non effettuerà alcun rimborso di quanto già corrisposto dal Cliente e l’abbonamento già in corso rimarrà attivo per la durata prevista nel piano prescelto o modificato con relativo obbligo di pagamento, fatta salva la suddetta possibilità di disdetta con preavviso prima del rinnovo automatico.  
        </div>

        <div class="sub-title"> 10. Proprietà </div>
        <div class="paragraph"> 
            Il Cliente riconosce che tutti i diritti di proprietà industriale ed intellettuale e di sfruttamento economico del Software appartengono ad ACCELASEARCH in via esclusiva.
            <br>
            ACCELASEARCH dichiara di aver depositato il marchio “ACCELASEARCH” (infra e per brevità “il Marchio Depositato”), e pertanto il Cliente si impegna a non depositare, né far depositare, in qualsiasi luogo, il Marchio Depositato e/o i segni distintivi di ACCELASEARCH, né a depositare o far depositare eventuali marchi, nomi o segni distintivi confondibili con quelli di ACCELASEARCH.
            <br>
            La Licenza d’uso non concede alcun diritto sul codice sorgente originale. Tutte le tecniche, gli algoritmi e i procedimenti contenuti nel Software e nella relativa documentazione d’uso sono di proprietà di ACCELASEARCH, e pertanto non possono essere usati dal Cliente per scopi diversi e/o ulteriori rispetto a quelli indicati nel presente Contratto.
        </div>

        <div class="sub-title"> 11. Assistenza </div>
        <div class="paragraph"> 
            ACCELASEARCH eroga un servizio di assistenza tecnica sul Software previa richiesta da parte del Cliente e secondo le modalità economiche di volta in volta concordate tra le parti secondo pacchetti orari prepagati di attività.
        </div>

        <div class="sub-title"> 12. Trattamento dei dati personali </div>
        <div class="paragraph"> 
            <p>
            Con la sottoscrizione del Contratto la sottoscrizione del Contratto, ciascuna Parte:
            </p><p>
            a) presta il proprio consenso al trattamento di dati, informazioni e notizie che la riguardano ad opera dell’altra, e dichiara di aver ricevuto idonea informativa ai sensi del regolamento (UE) 2016/679 di seguito GDPR;
            </p><p>
            b) garantisce di aver acquisito il consenso al trattamento dei dati di dipendenti, collaboratori, coinvolti dall’esecuzione dell’oggetto del presente Contratto ed il cui trattamento sia comunque necessario alla Parte che lo effettua per adempiere correttamente agli obblighi di cui al presente Contratto;
            </p><p>
            c) si impegna a rispettare ogni e tutti gli obblighi previsti dal GDPR qualora nell’esecuzione del presente Contratto sia necessario trattare dati di soggetti terzi, assumendosene in proprio ogni connessa responsabilità.
            </p>
        </div>

        <div class="sub-title"> 13. Manleva e limitazione di responsabilità in caso di disservizio </div>
        <div class="paragraph"> 
            <p>
            Nei limiti consentiti dalle leggi applicabili, ACCELASEARCH non sarà responsabile, neppure in caso di negligenza, per danni – diretti, indiretti, incidentali, speciali, consequenziali o esemplari, inclusi, a titolo esemplificativo e non esaustivo, danni per perdita di profitti, avviamento, utilizzo, dati o altre perdite immateriali – imputabili o connessi all’utilizzo o all’impossibilità di utilizzare il Servizio, o ai presenti Termini e condizioni del servizio.
            </p><p>
            Il Cliente accetta di risarcire e tenere indenne ACCELASEARCH in relazione a qualsiasi rivendicazione o richiesta – comprese le spese legali in misura ragionevole – avanzata da terze parti per: (a) violazione dei presenti Termini e condizioni del servizio o dei documenti ivi incorporati mediante rinvio (compresi i Termini d’uso); (b) violazione di leggi o diritti di terze parti; o (c) qualsiasi aspetto della transazione fra il Cliente e gli utenti del proprio sito web, come rimborsi, transazioni fraudolente, violazione accertata o presunta delle leggi applicabili (incluse a titolo esemplificativo e non esaustivo le leggi federali e statali a tutela dei consumatori), o la violazione dei Termini e condizioni del servizio
            </p><p>
            ACCELASEARCH non sarà responsabile per eventuali obblighi o responsabilità fiscali a tuo carico in connessione all’uso dei Servizi offerti dal proprio software.
            </p><p>
            L’utilizzo dei Servizi avviene ad esclusivo rischio del Cliente. I Servizi sono forniti “così come sono” e “se disponibili”, senza alcuna garanzia o condizione, espressa, implicita o derivante dalla legge.
            </p><p>
            ACCELASEARCH non garantisce che i Servizi saranno ininterrotti, tempestivi, sicuri o privi di errori.
            </p><p>
            ACCELASEARCH non garantisce che i risultati conseguibili mediante l’uso dei Servizi siano accurati o affidabili.
            </p><p>
            ACCELASEARCH non garantisce che la qualità di qualsiasi prodotto, servizio, informazione o altro materiale da te acquistato o	ottenuto attraverso i Servizi soddisferà le tue aspettative.
            </p>
        </div>

        <div class="sub-title"> 14. Modifiche </div>
        <div class="paragraph"> 
            ACCELASEARCH si riserva il diritto, ad esclusiva discrezione, di aggiornare o modificare qualsiasi parte dei Termini e condizioni del servizio in qualunque momento. Verrà dato un ragionevole preavviso delle modifiche ai Termini e condizioni del servizio che dovessero incidere materialmente e negativamente sull’ utilizzo dei Servizi o sui diritti ai sensi dei Termini e Condizioni del servizio. Tuttavia, ACCELASEARCH ha la facoltà di apportare modifiche che incidono sull’utilizzo dei Servizi o sui diritti ai sensi dei Termini e condizioni del servizio in qualsiasi momento e con effetto immediato (i) per motivi legali, normativi, di prevenzione di frodi e abusi o di sicurezza; o (ii) per escludere dalla piattaforma prodotti o attività che ritenga non sicuri, inappropriati o offensivi. Salvo diversa comunicazione (se applicabile), qualsiasi modifica ai Termini e condizioni del servizio diventerà efficace subito dopo la pubblicazione dei termini aggiornati su questo sito. La prosecuzione nell’utilizzo dei Servizi dopo tale comunicazione da parte di ACCELASEARCH, equivale ad una accettazione delle modifiche e al consenso del Cliente a vincolarsi ai Termini e condizioni del servizio modificati.
        </div>

        <div class="sub-title"> 15. Clausola risolutiva espressa </div>
        <div class="paragraph"> 
            La violazione di una qualsiasi delle previsioni di cui ai punti precedenti, comporta la facoltà per ACCELASEARCH di risolvere il Contratto ai sensi di dell’art. 1456 c.c. con semplice comunicazione, fermo restando il diritto al risarcimento di ogni eventuale maggior danno.
        </div>
        <div class="sub-title"> 16. Foro Competente </div>
        <div class="paragraph"> 
            Le presenti condizioni generali sono regolate dalla legge italiana. Il Cliente accetta che qualsiasi controversia concernente le presenti condizioni sarà devoluta alla competenza esclusiva del Foro di Milano.
        </div>
    </div>
</template>

<script>

export default {
  name: 'TermsConditions',
}
</script>

<style src="./TermsConditions.less" lang="less" scoped></style>