<template>
  <perfect-scrollbar class="billing-information">
    <div class="warning-container err suspended" v-if="!selectedPlanValue.isActive">
      <div class="info-box">
        <div class="desc"> {{$t('billing.information.accountIsSuspended')}} <br> {{$t('billing.information.contactCustomer')}} </div>
        <router-link class="cta" to="/billing/plans"> {{$t('header.ourPlans')}} </router-link> 
      </div>
    </div>
    <div class="warning-container err" v-if="checkInfo && !fetchingInfos">
      <div class="info-box">
        <div class="icon"></div>
        <div class="label"> {{selectedPlanValue.isRegular?$t('billing.overview.updateBillingInfoDanger'):$t('billing.overview.updatePaymentInfoDanger')}} </div>
      </div>
    </div>
    <div class="warning-container err" v-if="selectedPlanValue.isAutomatic && cards.length==0 && !fetchingInfos">
      <div class="info-box">
        <div class="icon"></div>
        <div class="label"> {{$t('billing.information.updateCardsInfoDanger')}} </div>
      </div>
    </div>
    <div class="warning-container err suspended" v-if="selectedPlanValue.isActive && getBillingInfo.isDeactivating">
      <div class="info-box">
        <div class="desc" v-html="$t('billing.information.accountSuspended',{var1:getDateFormat(selectedPlanValue.endTimestamp*1000)})"> </div>
        <div class="cta" @click="reactivatePlan"> {{$t('billing.information.reactivate')}} </div>
      </div>
    </div>
    <template v-else-if="selectedPlanValue.isActive">
      <div class="info-box" v-if="selectedPlanKey=='trial'">
        <div class="desc" v-html="$t('billing.information.trialEnds',{var1:getDateFormat(selectedPlanValue.endTimestamp*1000)})">  </div>
      </div>
      <div class="info-box" v-else>
        <div class="desc" v-html="$t('billing.information.currentlyOn',{var1:selectedPlanValue.title,var2:getDateFormat(selectedPlanValue.endTimestamp*1000)})"> </div>
        <div class="cta" id="popover-reactive-1" ></div>
        <b-popover target="popover-reactive-1" triggers="hover"> 
        <!-- <b-popover target="popover-reactive-1" triggers="hover" :show.sync="showPopOver">  -->
          <template>
            <div class="list">
              <div class="list-item upgrade" @click="()=>$router.push({name:'billing-plans'})"> <span class="icon"> </span> {{$t('billing.information.upgrade')}} </div>
              <div class="list-item cancel" @click="()=>{planConfirmModal=true;cancelMode=true}"> <span class="icon"> </span> {{$t('billing.information.cancel')}} </div>
              <!-- <div class="list-item cancel" @click="()=>{showPopOver=false;planConfirmModal=true;cancelMode=true}"> <span class="icon"> </span> {{$t('billing.information.cancel')}} </div> -->
            </div>
          </template> 
        </b-popover>
      </div>
    </template>
    <div class="card-container" v-if="selectedPlanValue.isAutomatic">
      <div class="title"> {{$t('billing.information.paymentMethods')}} </div>
      <div class="desc"> {{$t('billing.information.paymentMethodsDesc')}} </div>
      <draggable v-model="cards" class="cards-container" @end="reorderCards">
        <div class="card" v-for="(card,index) in cards" :key="'card_'+card.id">
          <div class="top">
            <div class="hamburger"></div>
            <div :class="['brand',card.brand]"> </div>
            <div class="last4"> •••• {{card.lastDigits}} </div>
            <div class="default" v-if="index==0"> Default </div>
          </div>
          <div class="middle">
            {{$t('billing.information.expires')}}
            <span> {{card.month}}/{{card.year}}</span>
          </div>
          <div class="bottom" v-if="cards.length>1">
            <div class="delete" v-if="!showConfirm[index]" @click.stop="()=>{resetShowConfirm();showConfirm[index]=true;}"> {{$t('billing.information.delete')}} </div> 
            <div class="delete-group" v-else @click.stop="">
              {{$t('billing.information.deleteCard')}}
              <div class="yes" @click.stop="()=>{deleteCard(card);resetShowConfirm();}"> {{$t('billing.information.yes')}} </div>
              <div class="no" @click.stop="resetShowConfirm"> {{$t('billing.information.no')}} </div>
            </div>
          </div>
        </div>
      </draggable>
      <div :class="['collapse-toggle title',{'collapse-card':collapseCard}]" @click="collapseCard=!collapseCard"> {{$t('billing.information.addCard')}} <span class="icon"> </span> </div>
      <b-collapse v-model="collapseCard" class="card-column">
        <div class="info-row">
          <div class="input-element">
            <label> {{$t('billing.information.cardNumber')}} </label>
            <div id="card-number"></div>
          </div>
          <div class="input-element">
            <label> {{$t('billing.information.cardExpiry')}} </label>
            <div id="card-expiry"></div>
          </div>
          <div class="input-element">
            <label> {{$t('billing.information.cardCVC')}} </label>
            <div id="card-cvc"></div>
          </div>
        </div>
        <div class="card-error" v-if="cardError!=''"> {{cardError}} </div>
        <div class="info-row">
          <b-button @click="createToken"> 
            <b-spinner v-if="addingCard"/> 
            <span v-else> {{$t('billing.information.cardCTA')}} </span>
          </b-button>
        </div>
      </b-collapse>
    </div>
    <div class="billing-column">
      <div class="title"> {{$t('billing.information.billingInformation')}} </div>
      <div class="info-row">
        <div class="input-element">
          <div class="label"> *{{$t('billing.information.firstName')}} </div>
          <b-input :placeholder="$t('billing.information.firstName')" v-model="billingInfo.firstName"></b-input>
        </div>
        <div class="input-element">
          <div class="label"> *{{$t('billing.information.lastName')}} </div>
          <b-input :placeholder="$t('billing.information.lastName')" v-model="billingInfo.lastName"></b-input>
        </div>
        <div class="input-element">
          <div class="label"> *{{$t('billing.information.company')}} </div>
          <b-input :placeholder="$t('billing.information.company')" v-model="billingInfo.companyName"></b-input>
        </div>
      </div>
      <div class="info-row">
        <div class="input-element">
          <div class="label"> *{{$t('billing.information.email')}} </div>
          <b-input :placeholder="$t('billing.information.email')" v-model="billingInfo.email"></b-input>
        </div>
        <div class="input-element">
          <div class="label"> *{{$t('billing.information.address')}} </div>
          <b-input :placeholder="$t('billing.information.address')" v-model="billingInfo.address"></b-input>
        </div>
        <div class="input-element">
          <div class="label"> *{{$t('billing.information.country')}} </div>
          <b-select :placeholder="$t('billing.information.country')" v-model="billingInfo.country" :options="countryOptions" @change="billingInfo.state=''"></b-select>
        </div>
      </div>
      <div class="info-row">
        <div class="input-element">
          <div class="label"> {{$t('billing.information.zipCode')}} </div>
          <b-input :placeholder="$t('billing.information.zipCodePlaceholder')" v-model="billingInfo.zipCode"></b-input>
        </div>
        <div class="input-element" v-if="regionsToUse.length==0">
          <div class="label"> {{$t('billing.information.state')}} </div>
          <b-input :placeholder="$t('billing.information.statePlaceholder')" v-model="billingInfo.state"></b-input>
        </div>
        <div class="input-element" v-else>
          <div class="label"> *{{$t('billing.information.state')}} </div>
          <b-select :placeholder="$t('billing.information.statePlaceholder')" v-model="billingInfo.state" :options="regionsToUse"></b-select>
        </div>
      </div>
      <div class="info-row">
        <div class="input-element">
          <div class="label"> *{{$t('billing.information.city')}} </div>
          <b-input :placeholder="$t('billing.information.city')" v-model="billingInfo.city"></b-input>
        </div>
        <div class="input-element">
          <div class="label"> *{{$t('billing.information.vat')}} </div>
          <b-input v-model="billingInfo.vat"></b-input>
        </div>
      </div>
      <div class="info-row" v-if="['IT','VA','SM'].includes(billingInfo.country)">
        <div class="input-element">
          <div class="label"> *{{$t('billing.information.sdi')}} </div>
          <b-input :state="sdiState" maxlength=7 :placeholder="$t('billing.information.sdi')" v-model="billingInfo.sdi"></b-input>
        </div>
        <div class="input-element" v-if="'IT'==billingInfo.country">
          <div class="label"> *{{$t('billing.information.pec')}} </div>
          <b-input :placeholder="$t('billing.information.pec')" v-model="billingInfo.pec"></b-input>
        </div>
      </div>
      <b-button :disabled="disabled" @click="updateInfo"> 
        <b-spinner v-if="updatingInfo"/> 
        <span v-else> {{$t('billing.information.update')}} </span> 
      </b-button>
    </div>
    <b-modal id="cancel-plan-confirm" :visible="planConfirmModal" hide-footer hide-header size="lg" @hide="()=>planConfirmModal=false"> 
      <div class="close" @click="()=>planConfirmModal=false"></div>
      <template v-if="cancelMode">
        <div class="title"> {{$t('billing.information.areYouSure')}} </div>   
        <div class="desc" v-html="$t('billing.information.ifYouConfirm',{var1:getDateFormat(selectedPlanValue.endTimestamp*1000)})"> </div>
      </template>
      <template v-else>
        <div class="title"> {{$t('billing.information.weReSorry')}} </div>   
        <div class="desc" v-html="$t('billing.information.subscriptionCancelled',{var1:getDateFormat(selectedPlanValue.endTimestamp*1000)})"> </div>
      </template>
      <div class="button-container">
        <b-button class="delete" @click="onCancelClick" v-if="cancelMode"> {{$t('billing.information.cancelSubscription')}} </b-button>
        <b-button @click="()=>planConfirmModal=false"> {{$t('billing.information.close')}} </b-button>
      </div>
    </b-modal>
  </perfect-scrollbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Utils from '@/utils/Utils';
import axios from '@/utils/Axios';
import lodash from 'lodash';
import moment from 'moment';

export default {
  name: 'BillingInformation',
  data(){
    return {
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      updatingInfo:false,
      countries:[],
      cardError:'',
      collapseCard:false,
      addingCard:false,
      showConfirm:[],
      fetchingInfos:false,
      cards:[],
      // showPopOver:false,
      planConfirmModal:false,
      cancelMode:true,
      billingInfo:{
        firstName:"",
        lastName:"",
        address:"",
        city:"",
        state:"",
        zipCode:"",
        country:"",
        sdi:"",
        companyName:"",
        email:"",
        vat:"",
        pec:""
      }
    }
  },
  computed:{
    ...mapGetters(['getBillingInfo','getUserInfo','getSelectedPlan']),
    selectedPlanValue(){ return Object.values(this.getSelectedPlan)[0] },
    selectedPlanKey(){ return Object.keys(this.getSelectedPlan)[0] },
    disabled(){ 
      return Utils.objectEquality(this.billingInfo,this.getBillingInfo) ||
        (!Utils.objectEquality(this.billingInfo,this.getBillingInfo) && this.emptyForm) ||
        this.updatingInfo
    },
    emptyForm(){
      return Utils.checkBillingInfo(this.billingInfo)
    },
    stripeElements () {
      return this.$stripe.elements();
    },
    countryOptions(){
      var arrayToReturn=lodash.cloneDeep(this.countries);
      arrayToReturn.sort((a,b)=> a.text === b.text ? 0 : a.text < b.text ? -1 : 1)
      arrayToReturn.splice(0,0,{value: null, text: '-- Select --', disabled:true})
      return arrayToReturn;
    },
    checkInfo(){
      return Utils.checkBillingInfo(this.getBillingInfo) || !this.selectedPlanValue.isRegular
    },
    sdiState(){
      return this.billingInfo.sdi?this.billingInfo.sdi.length==7:false
    },
    regionsToUse(){
      var arrayToReturn=lodash.cloneDeep(Utils.REGIONS);
      arrayToReturn=arrayToReturn.filter(item=>item.country_id==this.billingInfo.country)
      arrayToReturn=arrayToReturn.map(state=>({
        value:state.code,
        text:state.default_name
      }));
      if(arrayToReturn.length>0) arrayToReturn.splice(0,0,{value: "", text: '-- Select --', disabled:true})
      return arrayToReturn
    }
  },
  methods:{
    ...mapActions(['setBillingInfo']),
    async updateInfo(){ 
      this.updatingInfo=true;
      await axios.put(Utils.CUSTOMERS+Utils.ME,{...this.billingInfo})
      .then(response=>{
        if(response.data.status=="OK"){
          this.$bvToast.toast(this.$t('billing.information.billingInfoUpdated'), {
            title: '',
            autoHideDelay: 2000,
            solid:true,
            variant:'success'
          })
          this.setBillingInfo(lodash.cloneDeep(this.billingInfo));
        } else 
          this.$bvToast.toast(this.$t('billing.information.somethingWentWrong'), {
            title: '',
            autoHideDelay: 2000,
            solid:true,
            variant:'danger'
          })
      })
      .catch(()=>
        this.$bvToast.toast(this.$t('billing.information.somethingWentWrong'), {
          title: '',
          autoHideDelay: 2000,
          solid:true,
          variant:'danger'
        })
      )
      .finally(()=>this.updatingInfo=false)
    },
    async tokenCreated (token) {
      await axios.post(Utils.CUSTOMERS+Utils.ME+Utils.PAYMENT+Utils.METHODS+Utils.STRIPE,{"token":token.id})
      // .then(response=>console.log(response.data))
      await axios.get(Utils.CUSTOMERS+Utils.ME+Utils.PAYMENT+Utils.METHODS)
      .then(response=>this.cards=response.data)
      this.cardNumber.clear();
      this.cardExpiry.clear();
      this.cardCvc.clear();
      this.showConfirm.push(false);
      this.addingCard=false;
    },
    async reactivatePlan(){
      await axios.post(Utils.CUSTOMERS+Utils.ME+Utils.ACTIVE)
      await axios.get(Utils.CUSTOMERS+Utils.ME)
      .then(response=>this.setBillingInfo(response.data));
    },
    onCancelClick(){
      this.cancelMode=false;
      this.cancelPlan();
    },
    async cancelPlan(){
      await axios.delete(Utils.CUSTOMERS+Utils.ME+Utils.ACTIVE)
      await axios.get(Utils.CUSTOMERS+Utils.ME)
      .then(response=>this.setBillingInfo(response.data));
    },
    async createToken () {
      this.addingCard=true
      this.cardError = "";
      const { token, error } = await this.$stripe.createToken(this.cardNumber);
      if (error) {
        // handle error here
        this.cardError = error.message;
        this.addingCard=false;
        return;
      }
      this.tokenCreated(token);
    },
    async deleteCard(card){
      this.addingCard=true
      await axios.delete(Utils.CUSTOMERS+Utils.ME+Utils.PAYMENT+Utils.METHODS+"/"+card.id)
      await axios.get(Utils.CUSTOMERS+Utils.ME+Utils.PAYMENT+Utils.METHODS)
      .then(response=>this.cards=response.data)
      this.addingCard=false;
    },
    async reorderCards(){
      await axios.put(Utils.CUSTOMERS+Utils.ME+Utils.PAYMENT+Utils.METHODS, {"paymentMethods": this.cards})
      this.resetShowConfirm();
    },
    resetShowConfirm(){
      this.showConfirm=this.cards.map(()=>false);
    },
    getDateFormat(timestamp){
      var momentFormat="L";
      return moment(timestamp).format(momentFormat)
    },
  },
  async mounted(){ 
    this.fetchingInfos=true;
    var locale=['it','en'].includes(this.$i18n.locale)?this.$i18n.locale:'en';
    this.countries=Utils.COUNTRIES.map(country=>({
      value:country.value,
      text:country[locale]
    }))
    this.billingInfo=lodash.cloneDeep(this.getBillingInfo);
    var filteredCountry=this.countries.filter(country=>country.value==Intl.DateTimeFormat().resolvedOptions().locale.toUpperCase());
    if(!this.billingInfo.country && this.billingInfo.country!=='') this.billingInfo.country=filteredCountry.length>0?filteredCountry[0].iso:null;
    if(this.selectedPlanValue.isAutomatic){
      this.$stripe._locale=this.getUserInfo.languageIso;
      const style = {
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      };
      this.cardNumber = this.stripeElements.create('cardNumber', { style });
      this.cardNumber.mount('#card-number');
      this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
      this.cardExpiry.mount('#card-expiry');
      this.cardCvc = this.stripeElements.create('cardCvc', { style });
      this.cardCvc.mount('#card-cvc');
    }
    if(!this.billingInfo.firstName || this.billingInfo.firstName=='') this.billingInfo.firstName=this.getUserInfo.firstName
    if(!this.billingInfo.lastName || this.billingInfo.lastName=='') this.billingInfo.lastName=this.getUserInfo.lastName
    this.showConfirm=this.cards.map(()=>false);
    await axios.get(Utils.CUSTOMERS+Utils.ME+Utils.PAYMENT+Utils.METHODS)
    .then(response=>this.cards=response.data)
    this.fetchingInfos=false;
  },
  beforeDestroy () {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  }
}
</script>

<style src="./BillingInformation.less" lang="less" scoped></style>