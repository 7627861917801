<template>
  <div class="forgot-password">
    <div class="label" v-if="!send"> {{$t('home.forgotPassword.forgotPassword')}} </div>
    <div class="label" v-else> <span class="icon"></span> {{$t('home.forgotPassword.resetSend')}} </div>
    <div class="desc"> {{send?$t('home.forgotPassword.emailed'):$t('home.forgotPassword.justType')}} </div>
    <div class="mind" v-if="send"> <span class="icon"></span> {{$t('home.forgotPassword.mind')}} </div>
    <b-input v-model="email" :state="EMAIL_REGEX.test(email)" :placeholder="$t('home.forgotPassword.enterEmail')"></b-input>
    <b-button class="cta" @click="sendMailAction" :disabled="disabled">  {{send?$t('home.forgotPassword.sendAgain'):$t('home.forgotPassword.submit')}} </b-button>
    <div class="login"> {{$t('home.forgotPassword.or')}} <router-link to="/"> {{$t('home.forgotPassword.logIn')}} </router-link> </div>
  </div>
</template>

<script>
import Utils from '@/utils/Utils';
import axios from '@/utils/Axios';

export default { 
  name: 'ForgotPassword' ,
  data(){
    return {
      EMAIL_REGEX:Utils.EMAIL_REGEX,
      send:false,
      email:""
    }
  },
  methods:{
    sendMailAction(){
      axios.post(Utils.USERS+Utils.PWD+Utils.RECOVERY,{email:this.email})
      .then((response)=>{
        if(response.data.status!==Utils.ERROR)
          this.send=true
        else
          this.$bvToast.toast(this.$t('home.signup.something'), {
            title: '',
            autoHideDelay: 2000,
            solid:true,
            variant:'danger'
          })
      })
    }
  },
  computed:{
    disabled(){
      return !Utils.EMAIL_REGEX.test(this.email)
    },
    
  }
}
</script>

<style src="./ForgotPassword.less" lang="less" scoped></style>