<template>
  <div class="header" :class="{'hide':isWizardHeaderInactive}" v-if="$route.matched[0].name=='wizard'">
    <router-link to="/wizard/cms" >{{$t('header.cms')}}</router-link>
    <router-link to="/wizard/shop" >{{$t('header.shop')}}</router-link>
    <router-link to="/wizard/settings" > {{$t('header.configurationSettings')}}</router-link>
  </div>
  <div class="header billing" v-else-if="$route.matched[0].name=='billing'">
    <router-link to="/billing/overview"> {{$t('header.overview')}} </router-link>
    <router-link to="/billing/plans"> {{$t('header.ourPlans')}} </router-link>
    <router-link to="/billing/information" :class="['information',{'with-icon':checkInfo}]"> {{$t('header.information')}} <span class="icon"></span> </router-link>
  </div>
  <div class="header visual-machine" v-else-if="$route.matched[0].name=='visualMachine'">
    <router-link to="/visualMachine/campaigns"> {{$t('header.campaigns')}} </router-link>
    <router-link to="/visualMachine/banners"> {{$t('header.banners')}} </router-link>
    <router-link to="/visualMachine/recommendations"> {{$t('header.recommendations')}} </router-link>
  </div>
  <div class="header analytics" v-else-if="$route.matched[0].name=='analytics'">
    <router-link to="/analytics/overview"> {{$t('header.overview')}} </router-link>
    <router-link to="/analytics/queries"> {{$t('header.queries')}} </router-link>
    <router-link to="/analytics/filters"> {{$t('header.filters')}} </router-link>
    <div class="preview" @click="toPreview"> {{$t("saveChanges.seePreview")}} <span class="icon"/> </div>
  </div>
  <div class="header layoutDesign" v-else-if="$route.matched[0].name=='layoutDesign'">
    <router-link to="/layoutDesign/general">{{$t('header.general')}}</router-link>
    <router-link to="/layoutDesign/desktop">{{$t('header.desktop')}}</router-link>
    <router-link to="/layoutDesign/mobile">{{$t('header.mobile')}}</router-link>
    <router-link to="/layoutDesign/card">{{$t('header.card')}}</router-link>
  </div>
  <div class="header setup" v-else-if="$route.matched[0].name=='setup'">
    <router-link to="/setup/search">{{$t('header.search')}}</router-link>
    <router-link to="/setup/settings">{{$t('header.settings')}}</router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Utils from '@/utils/Utils';
import axios from '@/utils/Axios';

export default { 
  name: 'Header',
  data (){
    return {
      cards:[]
    }
  },
  computed:{
    ...mapGetters(['getBillingInfo','getSelectedPlan','getCurrentConfiguration']),
    checkInfo(){
      return Utils.checkBillingInfo(this.getBillingInfo) || (Object.values(this.getSelectedPlan)[0].isAutomatic && this.cards.length==0) || !Object.values(this.getSelectedPlan)[0].isActive || this.getBillingInfo.isDeactivating
    },
    isWizardHeaderInactive(){ return this.$route.name==="wizard-start"; },
  },
  methods:{
    toPreview(){  
      var windowReference = window.open();
      Utils.fromConfigToConfig(this['getCurrentConfiguration'])
      .then(config=>{
        localStorage.setItem('config', JSON.stringify(config));
        let routeData = this.$router.resolve({name: 'preview'});
        windowReference.location=routeData.href; 
      })
    },
  },
  async mounted(){
    await axios.get(Utils.CUSTOMERS+Utils.ME+Utils.PAYMENT+Utils.METHODS)
    .then(response=>this.cards=response.data)
  }
}
</script>

<style src="./Header.less" lang="less" scoped></style>