<template>
  <perfect-scrollbar class="search-configuration" ref="search-configuration">
    <div class="config-container">
      <div class="config-row">
        <div class="api">
          <div class="title"> {{$t('setup.api')}} </div>
          <div class="copy" @click="copyApi"></div>
          <b-input id="api-key" type="text" class="value" :value="getBillingInfo.api_key" disabled />
        </div>
        <ConfigItem v-if="getCurrentConfiguration.editable_widgets.includes('cms_data.feedUrl')" :title="$t('setup.feedTitle')" :desc="$t('setup.feedDesc')" type="feed-url" :modalTitle="$t('setup.feedTitle')" setupKey="feedUrl" :isConfig="false" />
      </div>
      <div class="config-row">
        <ConfigItem :title="$t('setup.selectorTitle')" :desc="$t('setup.selectorDesc')" type="input-selector" :modalTitle="$t('setup.selectorModal')" setupKey="selector" :isConfig="true" />
        <ConfigItem :title="$t('setup.layerTitle')" :desc="$t('setup.layerDesc')" type="import" :modalTitle="$t('setup.layerTitle')" setupKey="" :isConfig="true" />
      </div>
      <div class="config-row">
        <ConfigItem :title="$t('setup.queryTitle')" :desc="$t('setup.queryDesc')" type="list" :modalTitle="$t('setup.queryModal')" setupKey="query" :isConfig="false" />
        <ConfigItem :title="$t('setup.resultsTitle')" :desc="$t('setup.resultsDesc')" type="list" :modalTitle="$t('setup.resultsModal')" setupKey="results" :isConfig="false" />
      </div>
      <div class="config-row">
        <ConfigItem :title="$t('setup.facetsTitle')" :desc="$t('setup.facetsDesc')" type="list" :modalTitle="$t('setup.facetsTitle')" setupKey="facets" :isConfig="false" />
        <ConfigItem :title="$t('setup.dynamicFacetsTitle')" :desc="$t('setup.dynamicFacetsDesc')" type="dynamic-list"  modalTitle="Dynamic Facets" setupKey="dynamicFacets" :isConfig="true" />
      </div>
    </div>
    <div class="footer">
      <b-button class="btn delete" @click="deleteShop">  {{$t('setup.deleteShop')}} </b-button>
    </div>
  </perfect-scrollbar>
</template>

<script>
import ConfigItem from '@/components/ConfigItem/ConfigItem.vue'
import Utils from '@/utils/Utils'
import axios from '@/utils/Axios'
import { mapActions, mapGetters } from 'vuex'

export default { 
  name: 'SearchConfiguration',
  components: {ConfigItem },
  computed:{
    ...mapGetters(['getCurrentConfiguration','getBillingInfo']),
  },
  methods:{
    ...mapActions(['setOpenedWebsitesList','setDeleteShopModalVisible','setWebsiteAttributes']),
    deleteShop(){
      this.setDeleteShopModalVisible(true);
    },
    copyApi(){
      var copyText = document.getElementById("api-key");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      this.$bvToast.toast(this.$t('setup.copied'), {
        title: '',
        autoHideDelay: 2000,
        solid:true,
        variant:'success'
      })
    },
    getAttributes(){
      axios.get(Utils.SHOPS+'/'+this.getCurrentConfiguration.id+Utils.ATTR)
      .then((response)=>{ 
        this.setWebsiteAttributes(response.data); 
      })
    }
  },
  mounted(){
    this.getAttributes();
    this.$root.$on('not-busy',this.getAttributes);
  }
}
</script>

<style src="./SearchConfiguration.less" lang="less" scoped></style>