<template>
  <div class="activation">
      <div class="label"> {{activatedLabel}} </div>
      <div class="register" v-if="activated=='ok'"> <router-link to="/login"> {{$t('home.activation.clickHere')}} </router-link> {{$t('home.activation.toLogin')}} </div>
  </div>
</template>

<script>
import Utils from '@/utils/Utils';
import axios from '@/utils/Axios';

export default { 
  name: 'Activation' ,
  data(){
    return {
      activated:'running'
    }
  },
  computed:{
    activatedLabel(){
      switch(this.activated){
        case 'running': return this.$t('home.activation.pleaseWait');
        case 'ok': return this.$t('home.activation.accountActivated');
        case 'ko': return this.$t('home.activation.errorOccurred');
        default: return '';
      }
    }
  },
  mounted(){
    var params=window.location.search.substr(1,window.location.search.length-1).split('&').map(item=>item.split('=')[1]);
    axios.post(Utils.CUSTOMERS+'/'+params[0]+Utils.ACTIVATION,{key:params[1]})
    .then(response=>{
      if(response.data.status=='ERROR')
        this.activated='ko'
      else
        this.activated='ok';
    })
  }
}
</script>

<style src="./Activation.less" lang="less" scoped></style>