<template>
  <perfect-scrollbar class="wizard-settings">
    <ConfigModal/>
    <div class="title"> {{$t('wizard.settings.indexing')}} </div>
    <div class="desc" v-html="$t('wizard.settings.searchAttributes')"> </div>
    <div class="box" v-html="$t('wizard.settings.worry')"> </div>
    <div class="config-container">
      <div class="config-row">
        <!-- <ConfigItem title="*Selector Id" desc="Assicurati di inserire l'id del componente html (input text o bottone) che si vuole utilizzare come trigger per il layer di ricerca" type="input-selector"  modalTitle="Selector Id" setupKey="selector" :isConfig="true" />
        <ConfigItem title="Layer Custom Import" desc="Se hai bisogno di installare manualmente il layer di ricerca" type="import"  modalTitle="Layer Custom Import" setupKey="" :isConfig="true" /> -->
        <ConfigItem :title="$t('setup.selectorTitle')" :desc="$t('setup.selectorDesc')" type="input-selector" :modalTitle="$t('setup.selectorModal')" setupKey="selector" :isConfig="true" />
        <ConfigItem :title="$t('setup.layerTitle')" :desc="$t('setup.layerDesc')" type="import" :modalTitle="$t('setup.layerTitle')" setupKey="" :isConfig="true" />
      </div>
      <div class="config-row">
        <ConfigItem :title="$t('setup.queryTitle')" :desc="$t('setup.queryDesc')" type="list" :modalTitle="$t('setup.queryModal')" setupKey="query" :isConfig="false" />
        <ConfigItem :title="$t('setup.resultsTitle')" :desc="$t('setup.resultsDesc')" type="list" :modalTitle="$t('setup.resultsModal')" setupKey="results" :isConfig="false" />
      </div>
      <!-- <div class="config-row">
        <ConfigItem title="*Product Image" desc="Choose the main facets that you’re willing to individually customize later on." type="single-list" modalTitle="Product Image" setupKey="productImage" :isConfig="false" />
        <ConfigItem title="*Product URL" desc="We take the product URLs from the CMS by default, but feel free to replace it" type="single-list"  modalTitle="Product URL" setupKey="productUrl" :isConfig="true" />
      </div> -->
      <div class="config-row">
        <ConfigItem :title="$t('setup.facetsTitle')" :desc="$t('setup.facetsDesc')" type="list" :modalTitle="$t('setup.facetsTitle')" setupKey="facets" :isConfig="false" />
        <ConfigItem :title="$t('setup.boostingTitle')" :desc="$t('setup.boostingDesc')" type="single-list" :modalTitle="$t('setup.boostingTitle')" setupKey="boosting" :isConfig="false" />
        <!-- <ConfigItem title="Facets" desc="Choose the main facets that you’re willing to individually customize later on." type="list" modalTitle="Facets" setupKey="facets" :isConfig="false" /> -->
        <!-- <ConfigItem title="Dynamic Facets" desc="If enabled this feature allows to show all the other filters dynamically and accordingly to the searches made by users." type="boolean"  modalTitle="Dynamic Facets" setupKey="dynamicFacets" :isConfig="false" /> -->
      </div>
      <div class="config-row">
        <ConfigItem :title="$t('setup.languageTitle')" :desc="$t('setup.languageDesc')" type="lang" :modalTitle="$t('setup.languageTitle')" setupKey="lang" :isConfig="false" />
        <ConfigItem :title="$t('setup.categoryTitle')" :desc="$t('setup.categoryDesc')" type="input" :modalTitle="$t('setup.categoryTitle')" setupKey="separator" :isConfig="false" />
        <!-- <ConfigItem title="Boosting" desc="Promote special products by altering the default ranking by relevancy. Select an numerical attribute to enable this feature" type="single-list" modalTitle="Boosting" setupKey="boosting" :isConfig="false" /> -->
        <!-- <ConfigItem title="Category symbol separator" desc="If you added any category fields, be sure to type down the same symbol you use to divide them." type="input"  modalTitle="Category symbol separator" setupKey="separator" :isConfig="false" /> -->
      </div>
      <div class="config-row">
        <ConfigItem :title="$t('setup.groupPriceTitle')" :desc="$t('setup.groupPriceDesc')" type="groupPrice" :modalTitle="$t('setup.groupPriceTitle')" setupKey="groupPrice" :isConfig="true" />
        <ConfigItem :title="$t('setup.priceTitle')" :desc="$t('setup.priceDesc')" type="webhook" :modalTitle="$t('setup.priceTitle')" setupKey="webhook" :isConfig="true" />
        <!-- <ConfigItem title="Out of stock Products" desc="Import also products with quantity less or equal to zero" type="boolean"  modalTitle="Out of stock Products" setupKey="oosProducts" :isConfig="false" /> -->
        <!-- <ConfigItem title="*Selector Id" desc="Insert the selector Id" type="input"  modalTitle="Selector Id" setupKey="selectorId" :isConfig="true" /> -->
      </div>
      <div class="config-row">
        <ConfigItem :title="$t('setup.stockTitle')" :desc="$t('setup.stockDesc')" type="boolean" :modalTitle="$t('setup.stockTitle')" setupKey="oosProducts" :isConfig="false" />
      </div>
    </div>
    <div class="footer">
      <div class="btn back" @click="backClick"> {{$t('wizard.settings.back')}} </div>
      <b-button class="btn finish" @click="finishClick" :disabled="disabled"> {{$t('wizard.settings.finish')}} </b-button>
    </div>
  </perfect-scrollbar>
</template>

<script>
import ConfigItem from '@/components/ConfigItem/ConfigItem.vue'
import ConfigModal from '@/components/ConfigModal/ConfigModal.vue'
import Utils from '@/utils/Utils'
import axios from '@/utils/Axios'
import { mapActions, mapGetters } from 'vuex'
import lodash from 'lodash'

export default {
  name: 'WizardSettings',
  components: { ConfigItem,ConfigModal },
  computed:{
    ...mapGetters(['getCurrentConfiguration','getConfiguration','getDisplayedWebsites','getWebsites']),
    disabled(){
      return Object.keys(this.getCurrentConfiguration.setup.query).length==0 || Object.keys(this.getCurrentConfiguration.setup.results).length==0 || this.getCurrentConfiguration.setup.config.selectorId==""
        // lodash.isEqual(Utils.getConfigurationFromWebsite(this.getDisplayedWebsites,this.getCurrentConfiguration.url),this.getCurrentConfiguration)
    }
  },
  methods:{
    ...mapActions(['setWebsiteConfiguration','setOpenedWebsitesList','setWebsites','setCurrentConfiguration']),
    backClick(){ this.$router.push({name:'wizard-shop'}) },
    async finishClick(){
      var objectToSend={};
      objectToSend.setup={...this.getCurrentConfiguration.setup};
      objectToSend.setup.boosting=objectToSend.setup.boosting?objectToSend.setup.boosting[0]:null;
      // objectToSend.setup.productUrl=objectToSend.setup.productUrl?objectToSend.setup.productUrl[0]:null;
      objectToSend.setup.config.productUrl=objectToSend.setup.productUrl={url:"url"};
      objectToSend.setup.productImage={
        main:{ image:"image"},
        hover:{ image:"image"}
      }
      await axios.put(Utils.SHOPS+'/'+this.getCurrentConfiguration.id+Utils.CONF,objectToSend)
      .then(response=> this.setWebsites(response.data))
      await this.setCurrentConfiguration(lodash.clone(Utils.getConfigurationFromWebsite(this.getWebsites,this.getCurrentConfiguration)));
      this.setOpenedWebsitesList(false);
      this.$router.push({name:'fineTuning'})
    }
  },
  async mounted(){
    await axios.get(Utils.SHOPS)
    .then(async response=> {
      await this.setWebsites(response.data)
      await this.setCurrentConfiguration(lodash.clone(Utils.getConfigurationFromWebsite(this.getWebsites,this.getCurrentConfiguration)));
      if(!this.disabled) this.finishClick();
    })
  }
}
</script>

<style src="./WizardSettings.less" lang="less" scoped></style>