<template>
  <div class="wizard">
      <Header />
      <router-view/>
  </div>
</template>

<script>
import Header from '@/components/Header/Header'
import { mapGetters } from 'vuex'

export default { 
  name: 'Wizard',
  components:{ Header },
  computed:{
    ...mapGetters(['getCurrentConfiguration'])
  }
}
</script>

<style src="./Wizard.less" lang="less" scoped></style>