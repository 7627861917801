var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"notifications-modal"},on:{"click":_vm.closeModal}},[_c('div',{staticClass:"modal-container",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("notificationsModal.notificationCentre"))+" "),(_vm.getNewNotificationCounter > 0)?_c('span',{on:{"click":_vm.readAll}},[_vm._v(" "+_vm._s(_vm.$t("notificationsModal.readAll"))+" ")]):_vm._e()]),_c('perfect-scrollbar',{staticClass:"notifications-container",on:{"ps-y-reach-end":_vm.onReachEnd}},[_vm._l((_vm.getNotifications),function(notification){return _c('div',{key:'notification_' + notification.id,class:[
          'notification-item-container',
          { new: !notification.readAt },
          {'with-detail':notification.detail && notification.detail!=='' && Object.keys(notification.detail).length>0},
          _vm.fromCodeToParam(notification.code, 'status')
        ]},[_c('div',{staticClass:"notification-item"},[_c('div',{staticClass:"created"},[_vm._v(" "+_vm._s(_vm.fromTimestapToTimeAgo(notification.createdAt * 1000))+" "),_c('span',[_vm._v(" "+_vm._s(_vm.$t("notificationsModal.new"))+" ")])]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("notificationsModal." + _vm.fromCodeToParam(notification.code, "title")))+" ")]),_c('div',{staticClass:"desc",domProps:{"innerHTML":_vm._s(
              _vm.$t(
                'notificationsModal.' + _vm.getMessage(notification),
                _vm.getPayload(notification)
              )
            )}}),_c('div',{staticClass:"button-container"},[(_vm.fromCodeToParam(notification.code, 'redirect') != '')?_c('div',{staticClass:"redirect",on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.redirect(notification); })($event)}}},[_vm._v(" "+_vm._s(_vm.$t("notificationsModal.viewIssue"))+" ")]):_vm._e(),(notification.detail && notification.detail!=='' && Object.keys(notification.detail).length>0)?_c('div',{staticClass:"to-detail",on:{"click":function () { return _vm.goToDetail(notification); }}},[_vm._v(" "+_vm._s(_vm.$t("notificationsModal.detail"))+" ")]):_vm._e()])])])}),(!_vm.endNotification)?_c('div',{staticClass:"end-notifications"},[_vm._v(" 🎉 "+_vm._s(_vm.$t("notificationsModal.endNotifications"))+" ")]):_vm._e()],2),(_vm.getNotifications.length == 0)?_c('div',{staticClass:"no-notifications"},[_vm._v(" 📭 "+_vm._s(_vm.$t("notificationsModal.noNotification"))+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }