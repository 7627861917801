<template>
  <div :class="['list-table',{zero:info.key.startsWith('no')||info.key.startsWith('least')},{isDetail:isDetail}]">
    <div class="title"> {{ $t("analytics.queries."+info.key) }} </div>
    <div class="sub-title"> {{ $t("analytics.queries."+info.key+"_desc") }} </div>
    <div class="item-list" v-for="(value,index) in info.values" :key="value.value+'_'+index">
      <div class="num"> {{index+1}} </div>
      <div class="value" @click="()=>selectItem(value.value)"> {{value.value?value.value:"N/A"}} </div>
      <div class="count"> {{value.count}} </div>
    </div>
    <div class="show-all" v-if="!isDetail"> 
      <span @click="()=>showAll(info.key)"> {{ $t("analytics.showAll") }} </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListTable',
  props:['info','isDetail'],
  methods:{
    showAll(key){
      this.$emit('showAll',key)
    },
    selectItem(value){
      if(!String(this.info.key).includes("prod"))
        this.$emit('selectItem',value)
    }
  }
}
</script>

<style src="./ListTable.less" lang="less" scoped></style>