<template>
  <div class="weight-sliders">
    <div :class="['slider-item']" v-for="(query,index) in queries" :key="'slider_'+index">
      <div class="info">
        <div class="label"> {{query}} </div>
        <b-input class="value" type="number" :value="getCurrentConfiguration.fineTuning.weights[queries[index]]" @change="value=>{sliderValue=parseInt(value);changeWeightValue(index)}"/>
      </div>
      <div class="slider-container">
        <vue-slider class="v-slider" :value="getCurrentConfiguration.fineTuning.weights[queries[index]]" @drag-start="dragging=true" @drag-end="()=>{dragging=false;changeWeightValue(index)}" @change="value=>onChangeListenerSlider(value,index)"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import lodash from 'lodash'
export default {
  name: 'WeightSliders',
  data(){
    return{
      // values:[],
      sliderValue:0,
      dragging:false
    }
  },
  computed:{
    ...mapGetters(['getCurrentConfiguration','getHistoryChanges']),
    queries(){return Object.keys(this.getCurrentConfiguration.setup.query)}
    },
  methods:{
    ...mapActions(['setCurrentConfigurationByField','setHistoryChanges']),
    onChangeListenerSlider(value,index){
      this.sliderValue=value;
      if(!this.dragging) this.changeWeightValue(index);
    },
    changeWeightValue(index){
      var historyChanges=lodash.cloneDeep(this.getHistoryChanges)
      historyChanges.push(lodash.cloneDeep(this.getCurrentConfiguration));
      this.setHistoryChanges(historyChanges);
      var currentConfiguration=lodash.cloneDeep(this.getCurrentConfiguration.fineTuning);
      currentConfiguration.weights=currentConfiguration.weights?currentConfiguration.weights:{};
      currentConfiguration.weights[this.queries[index]]=this.sliderValue;
      this.setCurrentConfigurationByField({field:'fineTuning',value:currentConfiguration});
    }
  },
  mounted(){
    var that=this;
    this.queries.forEach((item,index)=>{
      that.sliderValue=1;
      if(!that.getCurrentConfiguration.fineTuning.weights[item]) that.changeWeightValue(index)
    })
  }
}
</script>

<style src="./WeightSliders.less" lang="less" scoped></style>