var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['as-panel', { show: _vm.showPanel }]},[_c('div',{staticClass:"overlay",on:{"click":_vm.closePanel}}),_c('div',{class:['panel', { bannerCard: _vm.actionType == 'bannerCard' }]},[(_vm.actionType == 'bannerCard')?_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("asPanel.bannerPosition")))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.$t("asPanel.youCanOrder")))]),_c('div',{staticClass:"icon close",on:{"click":_vm.closePanel}})]):_c('div',{staticClass:"header"},[(_vm.searchWord !== '')?_c('div',{staticClass:"delete",on:{"click":_vm.deleteWord}}):_vm._e(),_c('b-input',{staticClass:"as-ipnut",on:{"keyup":_vm.onInputKeyUp},model:{value:(_vm.searchWord),callback:function ($$v) {_vm.searchWord=$$v},expression:"searchWord"}}),_c('div',{staticClass:"icon close",on:{"click":_vm.closePanel}})],1),(_vm.actionType == 'bannerCard')?_c('div',{staticClass:"results"},[_c('div',{staticClass:"results-container"},[_c('perfect-scrollbar',{staticClass:"results-list"},[_c('draggable',_vm._b({attrs:{"move":_vm.onMove},model:{value:(_vm.bannerArray),callback:function ($$v) {_vm.bannerArray=$$v},expression:"bannerArray"}},'draggable',_vm.$options.DRAG_OPTIONS,false),[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},_vm._l((_vm.bannerArray),function(product,index){return _c('div',{key:'bannerCard_' + index,class:['list-item']},[_c('div',{staticClass:"card-item"},[(product.type == 'banner')?_c('div',{staticClass:"drag"}):_vm._e(),_c('div',{class:[
                      'img',
                      { banner: product.type == 'banner' },
                      {
                        dummy: product.type == 'banner' && product.url == '',
                      } ]},[(product.url)?_c('img',{attrs:{"src":product.url,"alt":product.url},on:{"error":_vm.replaceByDefault}}):_vm._e()]),(product.type == 'banner')?_c('div',{staticClass:"title-container banner"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("asPanel.productCardBanner", { var1: product.index + 1, }))+" ")])]):_c('div',{staticClass:"title-container"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"value"})]),_c('div',{staticClass:"price"},[_c('div',{staticClass:"value"})])]),_c('div',{staticClass:"position"},[_c('div',{staticClass:"value"},[_vm._v(_vm._s(index + 1))])])])])}),0)],1)],1)],1)]):_c('div',{staticClass:"results"},[(_vm.searchWord == '' && _vm.VMSelectedProducts.length == 0)?_c('div',{staticClass:"empty"},[_vm._v(" "+_vm._s(_vm.$t("asPanel.noProductSelected"))+" ")]):(_vm.searchWord !== '' && _vm.products.length == 0)?_c('div',{staticClass:"empty"},[_vm._v(" "+_vm._s(_vm.$t("asPanel.noProductsFound"))+" \""+_vm._s(_vm.searchWord)+"\" ")]):(_vm.searchWord == '' && _vm.VMSelectedProducts.length > 0)?_c('div',{staticClass:"results-container"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t("asPanel.belowAreShown"))+" ")]),(_vm.isPinType)?_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t("asPanel.thisProductsCanBeOrdered"))+" ")]):_vm._e(),(_vm.isPinType)?_c('perfect-scrollbar',{staticClass:"results-list"},[_c('draggable',_vm._b({model:{value:(_vm.VMSelectedProducts),callback:function ($$v) {_vm.VMSelectedProducts=$$v},expression:"VMSelectedProducts"}},'draggable',_vm.$options.DRAG_OPTIONS,false),[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},_vm._l((_vm.VMSelectedProducts),function(product){return _c('div',{key:'selectedProduct_' + product.id,class:[
                  'list-item',
                  'selected',
                  { 'is-not-alive': !product.isAlive } ]},[_c('div',{staticClass:"card-item"},[_c('div',{staticClass:"drag"}),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":product.image,"alt":product.title}})]),_c('div',{staticClass:"title-container"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(product.title))]),_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.$options.setNumberFormat(product.price, 2, ",", "."))+" € ")])]),_c('div',{staticClass:"selected"}),_c('div',{staticClass:"icon delete",on:{"click":function($event){$event.stopPropagation();return _vm.onProductClick(product)}}})]),(!product.isAlive)?_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.$t("asPanel.asFind"))+" ")]):_vm._e()])}),0)],1)],1):_c('perfect-scrollbar',{staticClass:"results-list"},_vm._l((_vm.VMSelectedProducts),function(product){return _c('div',{key:'selectedProduct_' + product.id,class:[
              'list-item',
              'selected',
              { 'is-not-alive': !product.isAlive } ]},[_c('div',{staticClass:"card-item"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":product.image,"alt":product.title}})]),_c('div',{staticClass:"title-container"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(product.title))]),_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.$options.setNumberFormat(product.price, 2, ",", "."))+" € ")])]),_c('div',{staticClass:"selected"}),_c('div',{staticClass:"icon delete",on:{"click":function($event){return _vm.onProductClick(product)}}})]),(!product.isAlive)?_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.$t("asPanel.asFind"))+" ")]):_vm._e()])}),0)],1):_c('div',{staticClass:"results-container searching"},[_c('div',{staticClass:"top-list"},[_c('div',{staticClass:"cta"},[_c('div',{class:['filters', { opened: _vm.showFilters }],on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[_c('span',{staticClass:"icon"}),_vm._v(" "+_vm._s(_vm.$t("asPanel.showFilters"))+" ")]),_c('div',{staticClass:"sort"},[_c('b-select',{attrs:{"options":_vm.sortOptions},on:{"change":_vm.onSortChange},model:{value:(_vm.selectedSort),callback:function ($$v) {_vm.selectedSort=$$v},expression:"selectedSort"}})],1)]),(_vm.productsCount > 0)?_c('div',{staticClass:"products"},[_vm._v(" "+_vm._s(_vm.productsCount)+" "+_vm._s(_vm.$t("asPanel.products"))+" ")]):_vm._e()]),_c('perfect-scrollbar',{ref:"ps",staticClass:"results-list",on:{"ps-y-reach-end":_vm.onReachEnd}},_vm._l((_vm.products),function(product){return _c('div',{key:'product_' + product.id,class:[
              'list-item',
              { selected: _vm.selectedProductsId.includes(product.id) } ],on:{"click":function($event){return _vm.onProductClick(product)}}},[_c('div',{staticClass:"card-item"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":product.image,"alt":product.title}})]),_c('div',{staticClass:"title-container"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(product.title))]),_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.$options.setNumberFormat(product.price, 2, ",", "."))+" € ")])]),_c('div',{staticClass:"selected"})])])}),0)],1),(_vm.searchWord !== '')?_c('div',{staticClass:"bottom"},[(_vm.VMSelectedProducts.length == 0)?_c('div',{staticClass:"btn inactive"},[_vm._v(" "+_vm._s(_vm.VMSelectedProducts.length)+" "+_vm._s(_vm.$t("asPanel.selectedProducts"))+" ")]):_c('div',{staticClass:"btn",on:{"click":_vm.deleteWord}},[_vm._v(" "+_vm._s(_vm.$t("asPanel.showProducts", { var1: _vm.VMSelectedProducts.length }))+" ")])]):_vm._e()])]),_c('div',{class:['filters-container', { show: _vm.showFilters && _vm.searchWord !== '' }]},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"icon",on:{"click":function($event){_vm.showFilters = false}}}),_vm._v(" "+_vm._s(_vm.$t("asPanel.filterBy"))+" ")]),_c('perfect-scrollbar',{staticClass:"container"},_vm._l((Object.keys(_vm.filters)),function(filterName,index){return _c('div',{key:filterName,class:['filter', { expanded: _vm.openFilters[index] }]},[_c('div',{staticClass:"header",on:{"click":function () { return _vm.collapseAction(index); }}},[_c('div',{staticClass:"icon"}),_vm._v(" "+_vm._s(filterName)+" "),(filterName == 'sellingPrice' && _vm.isPriceFilterActive)?_c('span',{on:{"click":function($event){$event.stopPropagation();return (function () {
                _vm.activeFilters.sellingPrice = { min: 0, max: 0 }
                _vm.onCategoryChange()
              })($event)}}},[_vm._v(" "+_vm._s(_vm.$t("asPanel.clear"))+" ")]):_vm._e()]),(filterName == 'sellingPrice')?_c('b-collapse',{model:{value:(_vm.openFilters[index]),callback:function ($$v) {_vm.$set(_vm.openFilters, index, $$v)},expression:"openFilters[index]"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"from"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("asPanel.from")))]),_c('b-input',{attrs:{"value":_vm.$options.setNumberFormat(_vm.priceFilterValues[0], 2, ',', '.'),"disabled":""}})],1),_c('div',{staticClass:"to"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("asPanel.to")))]),_c('b-input',{attrs:{"value":_vm.$options.setNumberFormat(_vm.priceFilterValues[1], 2, ',', '.'),"disabled":""}})],1)]),_c('vue-slider',{attrs:{"width":200,"interval":(_vm.filters.sellingPrice.max - _vm.filters.sellingPrice.min) / 100,"lazy":true,"min":_vm.filters.sellingPrice.min,"max":_vm.filters.sellingPrice.max},on:{"change":_vm.onPriceChange},model:{value:(_vm.priceFilterValues),callback:function ($$v) {_vm.priceFilterValues=$$v},expression:"priceFilterValues"}})],1):_c('b-collapse',{model:{value:(_vm.openFilters[index]),callback:function ($$v) {_vm.$set(_vm.openFilters, index, $$v)},expression:"openFilters[index]"}},[_c('perfect-scrollbar',{staticClass:"values-container"},[_c('b-form-checkbox-group',{on:{"change":_vm.onCategoryChange},model:{value:(_vm.activeFilters[filterName]),callback:function ($$v) {_vm.$set(_vm.activeFilters, filterName, $$v)},expression:"activeFilters[filterName]"}},_vm._l((_vm.filters[filterName]),function(filter){return _c('b-form-checkbox',{key:'filter_' + filter,attrs:{"value":filter}},[_vm._v(" "+_vm._s(filter.split("/").slice(-1)[0])+" ")])}),1)],1)],1)],1)}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }