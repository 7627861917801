<template>
  <div class="setup">
    <ConfigModal/>
    <DeleteShopModal/>
    <ImportCodeModal/>
    <div class="overheader"> 
      <div class="title"> {{$t('setup.setup')}} </div>
    </div>
    <Header />
    <router-view/>
  </div>
</template>

<script>
import ConfigModal from '@/components/ConfigModal/ConfigModal.vue'
import DeleteShopModal from '@/components/DeleteShopModal/DeleteShopModal.vue'
import ImportCodeModal from '@/components/ImportCodeModal/ImportCodeModal.vue'
import Header from '@/components/Header/Header'
import Utils from '@/utils/Utils'
import axios from '@/utils/Axios'
import { mapActions, mapGetters } from 'vuex'

export default { 
  name: 'Setup',
  components: { Header, ConfigModal, DeleteShopModal, ImportCodeModal},
  computed:{
    ...mapGetters(['getCurrentConfiguration','getBillingInfo']),
  },
  methods:{
    ...mapActions(['setOpenedWebsitesList','setDeleteShopModalVisible','setWebsiteAttributes']),
    deleteShop(){
      this.setDeleteShopModalVisible(true);
    },
    copyApi(){
      var copyText = document.getElementById("api-key");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      this.$bvToast.toast(this.$t('setup.copied'), {
        title: '',
        autoHideDelay: 2000,
        solid:true,
        variant:'success'
      })
    },
    getAttributes(){
      axios.get(Utils.SHOPS+'/'+this.getCurrentConfiguration.id+Utils.ATTR)
      .then((response)=>{ 
        this.setWebsiteAttributes(response.data); 
      })
    }
  },
  mounted(){
    this.getAttributes();
    this.$root.$on('not-busy',this.getAttributes);
  }
}
</script>

<style src="./Setup.less" lang="less" scoped></style>