<template>
  <div class="signup">
    <b-modal id="info" hide-footer hide-header size="lg" v-model="modalInfo" @hide="checkedTerms=checkedTerms1 && checkedTerms2">
      <div class="title"> {{$t('home.signup.modalInfoTitleTerms')}} </div>
      <div class="close" @click="modalInfo=false"></div>
      <perfect-scrollbar class="infoContent">
        <TermsConditions/>
        <b-form-checkbox v-model="checkedTerms1" class="terms"> {{$t('home.signup.iAgreeTerms')}} {{$t('home.signup.terms')}} </b-form-checkbox>
        <b-form-checkbox v-model="checkedTerms2" :disabled="!checkedTerms1" class="terms2"> 
          Ai sensi e per gli effetti di cui agli artt. 1341 e seguenti C.C. il Cliente dichiara di accettare espressamente le seguenti clausole contrattuali: art. 3 esonero responsabilità su tempistiche di attivazione; art. 4 esonero di responsabilità in caso di malfunzionamento, art. 6 attivazione automatica del piano “extra”, art. 9 Durata, art. 13 Manleva e limitazione di responsabilità in caso di disservizio, art. 14 Modifiche, art. 15 Clausola risolutiva espressa, art. 16 Foro Competente.
        </b-form-checkbox>
      </perfect-scrollbar>
      <div class="button-container">
        <b-button class="button" @click="()=>{modalInfo=false;checkedTerms=true;}" :disabled="!checkedTerms1 || !checkedTerms2"> {{$t('home.signup.modalInfoConfirm')}} </b-button>
      </div>
    </b-modal>
    <div class="label"> {{$t('home.signup.free')}} </div>
    <b-form-input class="usr" placeholder="example@email.com" :state="emailState?EMAIL_REGEX.test(mail):null" v-model="mail" @change="()=>emailState=true"/>
    <div :class="['eye',{show:pwdVisibility}]" @click="pwdVisibility=!pwdVisibility"></div>
    <b-form-input class="pwd" :type="pwdVisibility?'password':'text'" :placeholder="$t('home.signup.password')" v-model="password" @change="()=>pwdState=true"/>
    <div class="error" v-if="(pwdState || checkErrors) && !PWD_REGEX.test(password)"> {{$t('home.signup.passwordContain')}} </div>
    <div :class="['eye',{show:repeatPasswordVisibility}]" @click="repeatPasswordVisibility=!repeatPasswordVisibility"></div>
    <b-form-input class="pwd" :type="repeatPasswordVisibility?'password':'text'" :placeholder="$t('home.signup.password')" v-model="repeatPassword" @change="()=>pwdRepeatState=true"/>
    <div class="error" v-if="(pwdRepeatState || checkErrors) && repeatPassword!==password"> {{$t('home.signup.passwordSame')}} </div>
    <div class="checkbox-container" @click="modalInfo=true">
      <b-form-checkbox disabled v-model="checkedTerms"> </b-form-checkbox>
      {{$t('home.signup.iAgreeTerms')}} <a> {{$t('home.signup.terms')}} </a>
    </div>
    <div class="error" v-if="!checkedTerms && checkErrors"> {{$t('home.signup.checkTerms')}} </div>
    <!-- <div class="checkbox-container"> 
      <b-form-checkbox v-model="checkedPrivacy"> </b-form-checkbox>
      {{$t('home.signup.iAgreePrivacy')}} <a @click="()=>{modalType='Privacy';modalInfo=true;}"> {{$t('home.signup.privacy')}} </a>
    </div> -->
    <!-- <div class="error" v-if="!checkedPrivacy && checkErrors"> {{$t('home.signup.checkPrivacy')}} </div> -->
    <vue-recaptcha ref="recaptcha" class="recaptcha" @expired="onCaptchaExpired" @verify="onVerify" sitekey="6LeWbjQeAAAAANjAqehyt3UMSOXMTMI-5oevq4ZI">
    </vue-recaptcha>
    <b-button class="cta" @click="signupAction"> {{$t('home.signup.registerNow')}} </b-button>
    <div class="register"> {{ $t("home.signup.click") }} <a :href="$i18n.locale=='it'?'https://accelasearch.com/privacy-policy/':'https://accelasearch.com/en/privacy-policy/'" target="_blank"> {{ $t("home.signup.privacy") }} </a> </div>
    <div class="register"> {{$t('home.signup.gotAlready')}} <router-link to="/"> {{$t('home.signup.login')}} </router-link> </div>
  </div>
</template>

<script>
import Utils from '@/utils/Utils';
import axios from '@/utils/Axios';
import TermsConditions from '@/components/TermsConditions/TermsConditions';
import {mapGetters} from 'vuex';
import VueRecaptcha from 'vue-recaptcha';

export default { 
  name: 'Signup',
  data(){
    return {
      EMAIL_REGEX:Utils.EMAIL_REGEX,
      PWD_REGEX:Utils.PWD_REGEX,
      mail:"",
      password:"",
      repeatPassword:"",
      emailState:false,
      pwdState:false,
      pwdRepeatState:false,
      checkedTerms:false,
      checkedTerms1:false,
      checkedTerms2:false,
      // checkedPrivacy:false,
      pwdVisibility:true,
      repeatPasswordVisibility:true,
      modalInfo:false,
      modalType:"",
      showTerms:false,
      checkErrors:false,
      isRobot:Utils.isLocalhost() || Utils.isDev()?false:true
    }
  },
  computed:{
    disabled(){
      return !Utils.EMAIL_REGEX.test(this.mail) || !Utils.PWD_REGEX.test(this.password) || !this.checkedTerms/*  || !this.checkedPrivacy */ || this.isRobot || this.repeatPassword!=this.password
    },
    ...mapGetters(['getUrlSignup'])
  },
  methods:{ 
    signupAction(){
      if(this.disabled) this.checkErrors=true
      else if(!this.isRobot){
        var signupData={email:this.mail,password:this.password,language_iso:this.$i18n.locale};
        axios.post(Utils.CUSTOMERS,signupData)
        .then((response)=>{
          if(response.data.status=="OK")
            this.$router.push({name:"activation-mail"})
          else
          this.$bvToast.toast(this.$t('home.signup.something'), {
            title: '',
            solid:true,
            variant:'danger'
          })
        })
      } else {
        this.$bvToast.toast('You are a robot', {
          title: '',
          autoHideDelay: 2000,
          solid:true,
          variant:'danger'
        })
      }
    },
    onVerify(response) {
      if (response) this.isRobot = false;
    },
    onCaptchaExpired(response){
      console.log(response);
      this.isRobot=true;
    }
  },
  components: {
    'vue-recaptcha': VueRecaptcha,TermsConditions
  }
}
</script>

<style src="./Signup.less" lang="less" scoped></style>