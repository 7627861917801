<template>
  <div :class="['config-item', type]">
    <div
      class="available"
      v-if="
        type == 'webhook' &&
          !$options.isAdvancedOrMore(Object.values(getSelectedPlan)[0].key)
      "
    >
      <span v-html="$t('configItem.available')"> </span>
      <span class="icon"> </span>
    </div>
    <div class="title">
      {{ title }}
      <span class="info" @click="openModal('info-' + setupKey)"> ? </span>
    </div>
    <div class="desc">
      {{ desc }}
      <div class="help" v-if="type == 'webhook'">
        {{ $t("configItem.needHelp") }}
      </div>
      <div class="help" v-if="type == 'webhook'">
        <a
          class="doc"
          href="https://phase-orchid-049.notion.site/Advanced-Customization-a5db646cc28043a1bf72edac959ac0a3"
          target="_blank"
        >
          {{ $t("configItem.viewDocumentation") }}
        </a>
      </div>
    </div>
    <b-form-input
      v-if="type == 'input'"
      :value="
        isConfig
          ? getCurrentConfiguration.setup.config[setupKey]
          : getCurrentConfiguration.setup[setupKey]
      "
      @change="onChange"
    />
    <b-form-checkbox
      v-else-if="type == 'boolean'"
      :checked="
        isConfig
          ? getCurrentConfiguration.setup.config[setupKey]
          : getCurrentConfiguration.setup[setupKey]
      "
      @change="onChange"
    >
      {{
        setupKey == "dynamicFacets"
          ? $t("configItem.otherFields")
          : $t("configItem.outOfStock")
      }}
    </b-form-checkbox>
    <b-form-checkbox
      v-else-if="type == 'seo-settings'"
      :checked="
        getCurrentConfiguration.setup.config[setupKey]
          ? getCurrentConfiguration.setup.config[setupKey].isActive
          : false
      "
      @change="onChangeSeo"
    >
      {{ $t("configItem.activateUrlFriendly") }}
    </b-form-checkbox>
    <div v-else-if="type == 'single-list'" class="footer-container">
      <div class="footer-item">
        <div class="list">
          <div
            class="list-item"
            @click="removeElement(singleListItem, 'main')"
            v-if="
              setupKey == 'productImage'
                ? getProductImageField(singleListItem, 'main')
                : singleListItem
            "
          >
            {{
              setupKey == "productImage"
                ? getProductImageField(singleListItem, "main")
                : singleListItem
            }}
            <span> </span>
          </div>
        </div>
        <button @click="openModal(type + '-' + title)" class="btn">
          {{ $t("configItem.selectField") }}
        </button>
      </div>
      <div class="footer-item" v-if="setupKey == 'productImage'">
        <div class="list">
          <div
            class="list-item"
            @click="removeElement(singleListItem, 'hover')"
            v-if="getProductImageField(singleListItem, 'hover')"
          >
            {{ getProductImageField(singleListItem, "hover") }} <span> </span>
          </div>
        </div>
        <button @click="openModal(type + '-' + title + '-hover')" class="btn">
          {{ $t("configItem.selectField") }}
        </button>
      </div>
    </div>
    <div v-else-if="type == 'list'" class="footer-container">
      <div class="list">
        <div
          class="list-item"
          v-for="item in valuesToShow"
          :key="'item_' + item + '_' + setupKey"
          @click="removeElement(item)"
        >
          {{ item }} <span> </span>
        </div>
      </div>
      <button @click="openModal(type + '-' + title)" class="btn">
        + {{ $t("configItem.addFields") }}
      </button>
    </div>
    <div v-else-if="type == 'dynamic-list'" class="footer-container">
      <div class="label">
        {{ $t("configItem.otherFields") }}
        <toggle-button
          class="toggle-button"
          color="#398ff4"
          :sync="true"
          :width="50"
          :value="
            getCurrentConfiguration.setup.config[setupKey]
              ? getCurrentConfiguration.setup.config[setupKey].isActive
              : false
          "
          @change="activationToggle"
        />
      </div>
      <!-- <div :class="['list',{disabled:!(getCurrentConfiguration.setup.config[setupKey]?getCurrentConfiguration.setup.config[setupKey].isActive:false)}]"> </div> -->
      <button
        @click="openModal(type + '-' + title)"
        :class="[
          'btn',
          {
            disabled: !(getCurrentConfiguration.setup.config[setupKey]
              ? getCurrentConfiguration.setup.config[setupKey].isActive
              : false),
          },
        ]"
      >
        Manage Fields
      </button>
    </div>
    <div v-else-if="type == 'import'" class="footer-container">
      <button
        class="btn"
        @click="
          () => setImportCodeModalVisible({ visible: true, setupKey: 'import' })
        "
      >
        {{ $t("configItem.seeHow") }}
      </button>
    </div>
    <div v-else-if="type == 'input-selector'">
      <div class="label">
        {{ isId ? "Id" : "Name" }}
        <toggle-button
          class="toggle-button"
          color="#398ff4"
          :sync="true"
          :width="50"
          v-model="isId"
        />
      </div>
      <div class="label-selector">
        <div class="label">{{ $t("configItem.desktop") }}</div>
        <b-form-input
          :value="getCurrentConfiguration.setup.config[setupKey].desktop"
          @change="
            (value) => {
              onChangeSelector(value, 'desktop');
            }
          "
        />
      </div>
      <div class="label-selector">
        <div class="label">{{ $t("configItem.mobile") }}</div>
        <b-form-input
          :value="getCurrentConfiguration.setup.config[setupKey].mobile"
          @change="
            (value) => {
              onChangeSelector(value, 'mobile');
            }
          "
        />
      </div>
    </div>
    <div v-else-if="type == 'feed-url'" class="feed">
      <b-input
        type="text"
        class="value"
        :value="getCurrentConfiguration.cms_data.feedUrl"
        @change="
          (evt) => {
            onChangeFeed(evt);
            checkUrl(evt);
          }
        "
      />
      <div class="status">
        <b-spinner class="sync" label="Spinning" v-if="feedLoading"></b-spinner>
        <div class="ko" v-if="incorrectFeedUrl">
          {{ $t("configItem.wrongFeed") }}
        </div>
        <div class="ok" v-if="!feedLoading && !incorrectFeedUrl">
          {{ $t("configItem.correctFeed") }}
        </div>
      </div>
    </div>
    <div v-else-if="type == 'lang'">
      <b-form-select
        :disabled="
          !getCurrentConfiguration.editable_widgets.includes('language_iso')
        "
        :value="getCurrentConfiguration.language_iso"
        @change="onChangeLang"
        :options="languageOptions"
      ></b-form-select>
    </div>
    <div
      v-else-if="type == 'webhook'"
      :class="{
        disabled: !$options.isAdvancedOrMore(
          Object.values(getSelectedPlan)[0].key
        ),
      }"
    >
      <div class="label">
        {{ $t("configItem.webhook") }}
        <toggle-button
          class="toggle-button"
          color="#398ff4"
          :sync="true"
          :width="50"
          :value="
            getCurrentConfiguration.setup.config[setupKey]
              ? getCurrentConfiguration.setup.config[setupKey].isActive
              : false
          "
          @change="activationToggle"
        />
      </div>
      <div
        class="content-container"
        v-if="getCurrentConfiguration.setup.config[setupKey].isActive"
      >
        <div class="label"></div>
        <b-form-input
          :value="
            getCurrentConfiguration.setup.config[setupKey]
              ? getCurrentConfiguration.setup.config[setupKey].url
              : ''
          "
          @change="
            (value) => {
              onChangeSelector(value, 'url');
            }
          "
        />
        <div class="label"></div>
        <div class="footer-item">
          <div class="list">
            <div
              class="list-item"
              @click="removeElement(singleListItem)"
              v-if="singleListItem"
            >
              {{ singleListItem }} <span> </span>
            </div>
          </div>
          <button @click="openModal(type + '-' + title)" class="btn">
            {{ $t("configItem.selectField") }}
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="type == 'boosting'">
      <div class="content-container">
        <div class="footer-item">
          <div class="list">
            <div
              class="list-item"
              @click="removeElement(singleListItem)"
              v-if="singleListItem"
            >
              {{ singleListItem }} <span> </span>
            </div>
          </div>
          <button @click="openModal(type + '-' + title)" class="btn">
            {{ $t("configItem.selectField") }}
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="type == 'groupPrice'">
      <div class="label">{{ $t("configItem.defaultListPrice") }}</div>
      <b-input
        type="text"
        class="value"
        :value="
          getCurrentConfiguration.setup.config[setupKey]
            ? getCurrentConfiguration.setup.config[setupKey].priceGroupId
            : ''
        "
        @change="
          (value) => {
            onChangeSelector(value, 'priceGroupId');
          }
        "
      />
      <div class="label">{{ $t("configItem.defaultCurrency") }}</div>
      <b-select
        type="text"
        class="value"
        :value="
          getCurrentConfiguration.setup.config[setupKey]
            ? getCurrentConfiguration.setup.config[setupKey].currencyCode
            : ''
        "
        :options="currenciesOptions"
        @change="
          (value) => {
            onChangeSelector(value, 'currencyCode');
          }
        "
      >
      </b-select>
    </div>
    <div v-if="type == 'checkbox'">
      <b-form-checkbox
        :checked="
          getCurrentConfiguration.setup.config[setupKey]
            ? getCurrentConfiguration.setup.config[setupKey].isActive
            : false
        "
        @change="(value) => onChangeSelector(value, 'isActive')"
      >
        {{ $t("configItem.ga4Events") }}
      </b-form-checkbox>
      <b-form-select
        :disabled="
          getCurrentConfiguration.setup.config[setupKey]
            ? !getCurrentConfiguration.setup.config[setupKey].isActive
            : true
        "
        :value="
          getCurrentConfiguration.setup.config[setupKey]
            ? getCurrentConfiguration.setup.config[setupKey].type
            : 1
        "
        @change="(value) => onChangeSelector(value, 'type')"
        :options="ga4Options"
      ></b-form-select>
      <div
        v-if="
          getCurrentConfiguration.setup.config[setupKey] &&
            getCurrentConfiguration.setup.config[setupKey].type == '1'
        "
        class="footer-container"
      >
        <button
          class="btn"
          @click="
            () => setImportCodeModalVisible({ visible: true, setupKey: 'ga4' })
          "
          :disabled="
            getCurrentConfiguration.setup.config[setupKey].isActive
              ? !getCurrentConfiguration.setup.config[setupKey].isActive
              : true
          "
        >
          {{ $t("configItem.seeHow") }}
        </button>
      </div>
    </div>
    <div v-if="type == 'seo-settings'">
      <div class="label">{{ $t("configItem.queryString") }}</div>
      <div class="url-params">
        <b-input
          type="text"
          class="value"
          :value="
            getCurrentConfiguration.setup.config[setupKey]
              ? getCurrentConfiguration.setup.config[setupKey].searchWord
              : 'search'
          "
          @change="
            (value) => {
              onChangeSelector(value, 'searchWord');
            }
          "
        />
        <div class="example">
          <div class="label-small">Example</div>
          <div class="label-small">
            store.com/#/<b>{{
              getCurrentConfiguration.setup.config[setupKey]
                ? getCurrentConfiguration.setup.config[setupKey].searchWord
                : "search"
            }}</b
            >/?query=jumper
          </div>
        </div>
      </div>
      <div class="label">{{ $t("configItem.uploadSeoCsv") }}</div>
      <div class="edit-label" @click="onImportClick()">
        {{ $t("configItem.importSeoValues") }}
      </div>
      <div class="divider">{{ $t("configItem.seoOmptimized") }}</div>
      <div class="label">{{ $t("configItem.seoOmptimizedDesc") }}</div>
      <div class="mandatory">
        <div class="icon"></div>
        {{ $t("configItem.mandatoryAttributes") }}
      </div>
      <button
        @click="openModal(type + '-' + title)"
        class="btn left"
        :disabled="
          !getCurrentConfiguration.setup.config[setupKey] ||
            !getCurrentConfiguration.setup.config[setupKey].isActive
        "
      >
        + {{ $t("configItem.manageAttributes") }}
      </button>
      <b-modal
        id="values-modal"
        hide-footer
        hide-header
        size="lg"
        v-model="valuesModal"
      >
        <div class="title">{{ $t("configItem.importSeoValues") }}</div>
        <div class="close" @click="() => (valuesModal = false)"></div>
        <div class="desc">{{ $t("configItem.importSeoDesc") }}</div>
        <div
          class="button download"
          @click="() => $options.downloadJSON2CSV(seoValues, 'seo-values')"
        >
          {{ $t("layoutDesign.general.downloadSample") }}
          <span class="icon"></span>
        </div>
        <input
          ref="upload"
          type="file"
          accept=".csv, text/csv"
          @input="pickFile"
        />
        <div
          class="upload-container"
          @click="() => $refs.upload.click()"
          v-if="
            getCurrentConfiguration.setup.config.seoSettings &&
              !getCurrentConfiguration.setup.config.seoSettings.filename
          "
        >
          <div class="icon"></div>
          <div
            class="desc"
            v-html="$t('layoutDesign.general.uploadCSVData')"
          ></div>
          <div class="sub-desc">{{ $t("layoutDesign.general.CSVOnly") }}</div>
        </div>
        <template v-else>
          <div class="file-container">
            <div class="uploaded">
              <div class="label">
                {{
                  getCurrentConfiguration.setup.config.seoSettings
                    ? getCurrentConfiguration.setup.config.seoSettings.filename
                    : ""
                }}
              </div>
              <div class="remove" @click="removeCSV"></div>
              <div
                class="download"
                @click="
                  () =>
                    $options.downloadJSON2CSV(
                      getCurrentConfiguration.setup.config.seoSettings
                        ? getCurrentConfiguration.setup.config.seoSettings
                            .csvData
                        : {},
                      'seo-values'
                    )
                "
              ></div>
            </div>
            <div class="label">
              {{ $t("layoutDesign.general.filePreview") }}
            </div>
            <perfect-scrollbar class="preview">
              <textarea
                readonly="readonly"
                disabled="disabled"
                rows="20"
                :value="
                  getCurrentConfiguration.setup.config.seoSettings
                    ? $options.convertJsonTranslationToCSV(
                        getCurrentConfiguration.setup.config.seoSettings.csvData
                      )
                    : ''
                "
              />
            </perfect-scrollbar>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Utils from "@/utils/Utils";
import axios from "@/utils/Axios";
import lodash from "lodash";
import { currencies } from "currencies.json";

export default {
  name: "ConfigItem",
  isAdvancedOrMore: Utils.isAdvancedOrMore,
  props: ["title", "desc", "type", "setupKey", "modalTitle", "isConfig"],
  downloadJSON2CSV: Utils.downloadJSON2CSV,
  convertCSVToJson: Utils.convertCSVToJson,
  convertJsonTranslationToCSV: Utils.convertJsonTranslationToCSV,
  data() {
    return {
      booleanValue: false,
      valuesModal: false,
      incorrectFeedUrl: false,
      feedLoading: false,
      seoValues: [],
      ga4Options: Utils.ga4Options,
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentConfiguration",
      "getWebsiteAttributes",
      "getHistoryChanges",
      "getBaseUrl",
      "getLanguages",
      "getSelectedPlan",
    ]),
    valuesToShow() {
      var arrayToReturn = [];
      if (
        this.setupKey == "query" ||
        this.setupKey == "results" ||
        this.setupKey == "webhook"
      )
        Object.keys(
          this.getCurrentConfiguration.setup[this.setupKey]
        ).forEach((item) =>
          arrayToReturn.push(
            this.getCurrentConfiguration.setup[this.setupKey][item].label
          )
        );
      else if (this.setupKey == "dynamicFacets")
        arrayToReturn =
          this.getCurrentConfiguration.setup.config[this.setupKey] &&
          this.getCurrentConfiguration.setup.config[this.setupKey].selectedList
            ? Object.keys(
                this.getCurrentConfiguration.setup.config[this.setupKey]
                  .selectedList
              )
            : [];
      else
        arrayToReturn = this.isConfig
          ? this.getCurrentConfiguration.setup.config[this.setupKey]
          : this.getCurrentConfiguration.setup[this.setupKey];
      return arrayToReturn;
    },
    singleListItem() {
      if (this.setupKey == "webhook")
        return this.getCurrentConfiguration.setup.config[this.setupKey]
          ? this.getCurrentConfiguration.setup.config[this.setupKey].key
          : "";
      if (this.setupKey == "boosting")
        return this.getCurrentConfiguration.setup[this.setupKey]
          ? this.getCurrentConfiguration.setup[this.setupKey]
          : "";
      var value = this.isConfig
        ? this.getCurrentConfiguration.setup.config[this.setupKey]
        : this.getCurrentConfiguration.setup[this.setupKey];
      if (Array.isArray(value)) return value[0];
      if (this.setupKey == "productImage") return value;
      return value ? Object.keys(value)[0] : "";
    },
    isId: {
      set(value) {
        var historyChanges = lodash.cloneDeep(this.getHistoryChanges);
        historyChanges.push(lodash.cloneDeep(this.getCurrentConfiguration));
        this.setHistoryChanges(historyChanges);
        var objectToReturn = { ...this.getCurrentConfiguration.setup };
        objectToReturn.config[this.setupKey].isId = value;
        this.setCurrentConfigurationByField({
          field: "setup",
          value: objectToReturn,
        });
      },
      get() {
        return this.getCurrentConfiguration.setup.config[this.setupKey].isId;
      },
    },
    languageOptions() {
      var arrayToReturn = lodash.cloneDeep(this.getLanguages);
      arrayToReturn.splice(0, 0, {
        value: null,
        text: "-- Select --",
        disabled: true,
      });
      return arrayToReturn;
    },
    currenciesOptions() {
      return currencies.map((currency) => ({
        text: currency.name + " - " + currency.symbolNative,
        value: currency.code,
      }));
    },
  },
  methods: {
    ...mapActions([
      "setConfigurationModal",
      "setCurrentConfigurationByField",
      "setCurrentConfiguration",
      "setHistoryChanges",
      "setImportCodeModalVisible",
    ]),
    openModal(type) {
      this.setConfigurationModal({ field: "type", value: type });
      this.setConfigurationModal({ field: "title", value: this.modalTitle });
      this.setConfigurationModal({ field: "setupKey", value: this.setupKey });
      this.setConfigurationModal({ field: "isConfig", value: this.isConfig });
      this.setConfigurationModal({ field: "desc", value: this.desc });
      if (this.setupKey == "results") {
        this.setConfigurationModal({
          field: "list",
          value: [
            this.$t("configItem.automaticDiscount"),
            ...this.getWebsiteAttributes,
          ],
        });
      } else {
        this.setConfigurationModal({
          field: "list",
          value: type.startsWith("info-")
            ? Utils.CONFIG_MODAL_INFO[type]
            : this.getWebsiteAttributes,
        });
      }
      if (this.setupKey == "webhook")
        this.setConfigurationModal({
          field: "selectedList",
          value: this.getCurrentConfiguration.setup.config[this.setupKey]
            ? {
                key: this.getCurrentConfiguration.setup.config[this.setupKey]
                  .key,
              }
            : { key: "" },
        });
      else if (this.setupKey == "boosting")
        this.setConfigurationModal({
          field: "selectedList",
          value: this.getCurrentConfiguration.setup[this.setupKey]
            ? { key: this.getCurrentConfiguration.setup[this.setupKey] }
            : { key: "" },
        });
      else if (this.setupKey == "dynamicFacets")
        this.setConfigurationModal({
          field: "selectedList",
          value:
            this.getCurrentConfiguration.setup.config[this.setupKey] &&
            this.getCurrentConfiguration.setup.config[this.setupKey]
              .selectedList
              ? this.getCurrentConfiguration.setup.config[this.setupKey]
                  .selectedList
              : {},
        });
      else if (this.setupKey == "seoSettings") {
        const facets = this.getCurrentConfiguration.layout.facets.map(
          (facet) => {
            if (facet.active) {
              return facet.name;
            }
          }
        );
        this.getCurrentConfiguration.setup.config.seoSettings.list = [
          ...(this.getCurrentConfiguration.setup.config.seoSettings.list ?? []),
          ...facets,
        ].reduce(
          (unique, item) =>
            unique.includes(item) ? unique : [...unique, item],
          []
        );
        this.setConfigurationModal({
          field: "list",
          value: this.getCurrentConfiguration.setup.config.seoSettings.list,
        });

        this.setConfigurationModal({
          field: "filteredList",
          value:
            this.getCurrentConfiguration.setup.config[this.setupKey] &&
            this.getCurrentConfiguration.setup.config[this.setupKey].list
              ? this.getCurrentConfiguration.setup.config[this.setupKey].list
              : {},
        });
        this.setConfigurationModal({
          field: "selectedList",
          value:
            this.getCurrentConfiguration.setup.config[this.setupKey] &&
            this.getCurrentConfiguration.setup.config[this.setupKey]
              .selectedList
              ? this.getCurrentConfiguration.setup.config[this.setupKey]
                  .selectedList
              : {},
        });
      } else
        this.setConfigurationModal({
          field: "selectedList",
          value: this.getCurrentConfiguration.setup[this.setupKey]
            ? this.getCurrentConfiguration.setup[this.setupKey]
            : {},
        });
      this.setConfigurationModal({ field: "filter", value: "" });
      this.$bvModal.show("config-modal");
    },
    removeElement(item, productImageType) {
      var historyChanges = lodash.cloneDeep(this.getHistoryChanges);
      historyChanges.push(lodash.cloneDeep(this.getCurrentConfiguration));
      this.setHistoryChanges(historyChanges);
      var objectToReturn = lodash.cloneDeep(this.getCurrentConfiguration.setup);
      if (this.setupKey == "productImage")
        delete objectToReturn[this.setupKey][productImageType];
      else if (this.setupKey == "webhook")
        delete objectToReturn.config[this.setupKey].key;
      else if (this.setupKey == "boosting")
        delete objectToReturn[this.setupKey];
      else if (this.setupKey == "dynamicFacets")
        delete objectToReturn.config[this.setupKey].selectedList[item];
      else delete objectToReturn[this.setupKey][item];
      this.setCurrentConfigurationByField({
        field: "setup",
        value: objectToReturn,
      });
    },
    onChange(value) {
      var historyChanges = lodash.cloneDeep(this.getHistoryChanges);
      historyChanges.push(lodash.cloneDeep(this.getCurrentConfiguration));
      this.setHistoryChanges(historyChanges);
      var objectToReturn = { ...this.getCurrentConfiguration.setup };
      if (this.isConfig) objectToReturn.config[this.setupKey] = value;
      else objectToReturn[this.setupKey] = value;
      this.setCurrentConfigurationByField({
        field: "setup",
        value: objectToReturn,
      });
    },
    onChangeFeed(value) {
      var historyChanges = lodash.cloneDeep(this.getHistoryChanges);
      historyChanges.push(lodash.cloneDeep(this.getCurrentConfiguration));
      this.setHistoryChanges(historyChanges);
      var objectToReturn = { ...this.getCurrentConfiguration.setup };
      objectToReturn.feedUrl = value;
      this.setCurrentConfigurationByField({
        field: "setup",
        value: objectToReturn,
      });
      objectToReturn = { ...this.getCurrentConfiguration.cms_data };
      objectToReturn.feedUrl = value;
      this.setCurrentConfigurationByField({
        field: "cms_data",
        value: objectToReturn,
      });
    },
    async onChangeLang(value) {
      var historyChanges = lodash.cloneDeep(this.getHistoryChanges);
      historyChanges.push(lodash.cloneDeep(this.getCurrentConfiguration));
      this.setHistoryChanges(historyChanges);
      var objectToReturn = { ...this.getCurrentConfiguration.setup };
      objectToReturn.language_iso = value;
      await this.setCurrentConfigurationByField({
        field: "language_iso",
        value: value,
      });
      await this.setCurrentConfigurationByField({
        field: "setup",
        value: objectToReturn,
      });
    },
    onChangeSelector(value, type) {
      var historyChanges = lodash.cloneDeep(this.getHistoryChanges);
      historyChanges.push(lodash.cloneDeep(this.getCurrentConfiguration));
      this.setHistoryChanges(historyChanges);
      var objectToReturn = lodash.cloneDeep(this.getCurrentConfiguration.setup);
      if (this.isConfig && !objectToReturn.config[this.setupKey])
        objectToReturn.config[this.setupKey] = {};
      else if (!this.isConfig && !objectToReturn[this.setupKey])
        objectToReturn[this.setupKey] = {};
      if (this.isConfig) objectToReturn.config[this.setupKey][type] = value;
      else objectToReturn[this.setupKey][type] = value;
      this.setCurrentConfigurationByField({
        field: "setup",
        value: objectToReturn,
      });
    },
    activationToggle(evt) {
      this.onChangeSelector(evt.value, "isActive");
    },
    onChangeSeo(value) {
      this.onChangeSelector(value, "isActive");
    },
    getProductImageField(item, type) {
      return item && item[type] ? Object.keys(item[type])[0] : "";
    },
    async onImportClick() {
      this.valuesModal = true;
      await this.getSeoValues();
    },
    async checkUrl(value) {
      this.feedLoading = true;
      this.incorrectFeedUrl = false;
      await axios
        .get(
          this.getBaseUrl +
            Utils.INTEGRATIONS +
            Utils.GS +
            Utils.CHECK_FEED +
            "?url=" +
            encodeURIComponent(value)
        )
        .then((response) => (this.incorrectFeedUrl = !response.data.isReadable))
        .catch(() => (this.incorrectFeedUrl = true))
        .finally(() => (this.feedLoading = false));
    },
    pickFile(evt) {
      var file = evt.target.files[0];
      this.filename = file.name;
      var fr = new FileReader();
      fr.addEventListener("load", () => {
        var setup = lodash.cloneDeep(this["getCurrentConfiguration"].setup);
        setup.config.seoSettings.filename = this.filename;
        const data = Utils.convertCSVTranslationToJson(fr.result);
        const parsedData = {};

        data.forEach((filter) => {
          if (!parsedData[filter.filter]) {
            parsedData[filter.filter] = {};
          }
          parsedData[filter.filter][filter.value] = filter.translation;
        });

        setup.config.seoSettings.csvData = data;
        this.setCurrentConfigurationByField({ field: "setup", value: setup });
        this.$root.$emit("save-changes");
        axios.post(
          Utils.SHOPS + "/" + this.getCurrentConfiguration.id + Utils.SEO,
          { seo: parsedData }
        );
        evt.target.value = "";
      });
      fr.readAsText(file);
    },
    removeCSV() {
      axios.delete(
        Utils.SHOPS + "/" + this.getCurrentConfiguration.id + Utils.SEO
      );
      var setup = lodash.cloneDeep(this["getCurrentConfiguration"].setup);
      setup.config.seoSettings.filename = "";
      setup.config.seoSettings.csvData = {};
      this.setCurrentConfigurationByField({ field: "setup", value: setup });
      this.$root.$emit("save-changes");
      this.$refs.upload.value = "";
    },
    async getSeoValues() {
      let queryString = "";
      let indexPos = 0;
      Object.values(
        this.getCurrentConfiguration.setup.config.seoSettings.list
      ).map((value) => {
        if (
          this.getCurrentConfiguration.setup.config.seoSettings.selectedList[
            value
          ]
        ) {
          if (indexPos === 0) {
            queryString += `?attributes[]=${value}`;
            indexPos++;
          } else queryString += `&attributes[]=${value}`;
        }
        return value;
      });
      const values = await axios.get(
        Utils.SHOPS +
          "/" +
          this.getCurrentConfiguration.id +
          Utils.SEOVALUES +
          queryString
      );
      const valuesToReturn = [];
      values.data.values.forEach((key) =>
        Object.entries(key).forEach(([k, value]) => {
          value.forEach((v) =>
            valuesToReturn.push({ filter: k, value: v, translation: "" })
          );
        })
      );
      this.seoValues = valuesToReturn;
    },
  },
  mounted() {
    this.setConfigurationModal({
      field: "list",
      value: this.getWebsiteAttributes,
    });
    if (
      this.isConfig &&
      this.getCurrentConfiguration.setup.config[this.setupKey] &&
      this.getCurrentConfiguration.setup.config[this.setupKey].length == 0
    ) {
      var objectToReturn = { ...this.getCurrentConfiguration.setup };
      objectToReturn.config[this.setupKey] = {};
      this.setCurrentConfigurationByField({
        field: "setup",
        value: objectToReturn,
      });
    }
    if (this.type == "feed-url") {
      this.checkUrl(this.getCurrentConfiguration.cms_data.feedUrl);
      this.$root.$on("discard-changes", () =>
        this.checkUrl(this.getCurrentConfiguration.cms_data.feedUrl)
      );
    }
  },
};
</script>

<style src="./ConfigItem.less" lang="less" scoped></style>
