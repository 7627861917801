<template>
  <div id="notification-detail-modal" @click="closeModal">
    <div :class="['modal-container',fromCodeToParam(getSelectedNotification.code, 'status')]"  @click.stop="">
      <div class="title">
        {{
          $t(
            "notificationsModal." + fromCodeToParam(getSelectedNotification.code, "title")
          )
        }}
      </div>
      <div class="close" @click="closeModal"></div>
      <div
        class="desc"
        v-html="
          $t(
            'notificationsModal.' + getMessage(getSelectedNotification),
            getPayload(getSelectedNotification)
          )
        "
      ></div>
      <div
        class="redirect"
        v-if="fromCodeToParam(getSelectedNotification.code, 'redirect') != ''"
        @click.stop="() => redirect(getSelectedNotification)"
      >
        {{ $t("notificationsModal.viewIssue") }}
      </div>
      <textarea class="detail" readonly="readonly" disabled="disabled" :value="JSON.stringify(getSelectedNotification.detail)" ></textarea>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Utils from "../../utils/Utils";
import axiosInstance from "../../utils/Axios";
import lodash from "lodash";

export default {
  name: "NotificationDetailModal",
  computed: {
    ...mapGetters([
      "getNotificationInterval",
      "getSelectedNotification",
      "getNotificationDetailModalVisible",
      "getWebsites",
      "getSelectedCampaign",
      "getHistoryChanges",
      "getDisplayedWebsites"
    ]),
    endNotification() {
      return this.getNotifications.length == this.page * Utils.NOTIFICATION_PAGE_SIZE;
    },
  },
  methods: {
    ...mapActions([
      "setSelectedNotification",
      "setNotificationDetailModalVisible",
      "setNotificationInterval",
      "setNotificationModalVisible",
      "setNotifications",
      "setShowSaveConfigModal",
      "setPendingWebsite",
      "setOpenedWebsitesList",
      "setCurrentConfiguration",
      "setWebsites"
    ]),
    async openNotificationModal() {
      await axiosInstance
        .get(
          Utils.NOTIFICATIONS +
            "?" +
            Utils.FROM +
            "=0&" +
            Utils.SIZE +
            "=" +
            Utils.NOTIFICATION_PAGE_SIZE
        )
        .then((response) => {
          this.setNotifications(response.data);
        });
      clearInterval(this.getNotificationInterval);
      this.setNotificationInterval(-1);
      this.setNotificationModalVisible(true);
    },
    fromCodeToParam(code, param) {
      return Object.keys(Utils.NOTIFICATIONS_CODES).includes(code)?Utils.NOTIFICATIONS_CODES[code][param]:Utils.NOTIFICATIONS_CODES['generic-error'][param];
    },
    getMessage(notification) {
      return Object.keys(Utils.NOTIFICATIONS_CODES).includes(notification.code)?Utils.NOTIFICATIONS_CODES[notification.code].message:Utils.NOTIFICATIONS_CODES['generic-error'].message;
    },
    getPayload(notification) {
      var payload = Object.keys(Utils.NOTIFICATIONS_CODES).includes(notification.code)?Utils.NOTIFICATIONS_CODES[notification.code].payload:Utils.NOTIFICATIONS_CODES['generic-error'].payload;
      var objectToReturn = {};
      payload.forEach((key) => {
        if (key == "shopId") {
          var websitesArray = this.getWebsites.filter(
            (website) => website.id == notification.payload.shopId
          );
          objectToReturn.shopName = Utils.beautifyWebsite(
            websitesArray.length > 0 ? websitesArray[0].url : ""
          );
        }
        // message=message.replace('{'+key+'}',notification.payload[key])
        else objectToReturn.key = notification.payload[key];
      });
      return objectToReturn;
    },
    async closeModal() {
      this.setNotificationDetailModalVisible(false);
      this.setSelectedNotification(null);
      this.openNotificationModal();
    },
    async redirect(notification) {
      var redirectName = Utils.NOTIFICATIONS_CODES[notification.code].redirect;
      var payload = Utils.NOTIFICATIONS_CODES[notification.code].payload;
      var withShopId = payload.some((key) => key == "shopId");
      if (withShopId)
        await this.websiteClick(
          this.getWebsites.filter(
            (website) => website.id == notification.payload.shopId
          )[0]
        );
      this.$router.push({ name: redirectName });
      this.setNotificationDetailModalVisible(false);
      this.setSelectedNotification(null);
    },
    async websiteClick(website) {
      if (this.getSelectedCampaign) this.$root.$emit("before-change-website", website);
      else if (this.getHistoryChanges.length > 0) {
        this.setShowSaveConfigModal(true);
        this.setPendingWebsite(website);
      } else {
        this.setOpenedWebsitesList(false);
        await this.setCurrentConfiguration(
          lodash.clone(
            Utils.getConfigurationFromWebsite(this.getDisplayedWebsites, website)
          )
        );
        await axiosInstance.get(Utils.SHOPS).then((response) => {
          this.setWebsites(response.data);
        });
        if (Utils.checkConfiguring(website))
          this.$router.push({ name: "wizard-settings" });
        else {
          this.$root.$emit("changed-website");
        }
      }
    },
  },
};
</script>

<style src="./NotificationDetailModal.less" lang="less" scoped></style>
