<template>
  <div class="cms-login">
    <div class="label" v-if="!error">{{ $t("home.login.csmLogin") }}</div>
    <div class="label" v-else>{{ error }}</div>
  </div>
</template>

<script>
import Utils from "@/utils/Utils";
import axios from "@/utils/Axios";

export default {
  name: "CMSLogin",
  data() {
    return {
      token: "",
      id: 0,
      error: "",
    };
  },
  methods: {
    async loginAction() {
      var baseUrl = axios.defaults.baseURL.replace("/API", "/admin");
      axios
        .post(baseUrl + Utils.USERS + "/" + this.id + Utils.SESSION, {
          token: this.token,
        })
        .then(async (response) => {
          if (response.data.status !== Utils.ERROR) {
            window.localStorage.setItem("token", response.data.jwt);
            window.localStorage.setItem("apikey", response.data.api_key);
            this.$router.push({ name: "analytics" });
          } else this.error = this.$t("home.signup.something");
        })
        .catch(() => (this.error = this.$t("home.signup.something")));
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.token = urlParams.get("token");
    this.id = urlParams.get("id");
    if (this.token && this.id) {
      this.loginAction();
    } else {
      this.error = this.$t("home.signup.something");
    }
  },
};
</script>

<style src="./CMSLogin.less" lang="less" scoped></style>
