<template>
  <div class="terms">
      TERMS
  </div>
</template>

<script>
export default { name: 'Terms' }
</script>

<style src="./Terms.less" lang="less" scoped></style>