<template>
  <b-modal
    id="import-code-modal"
    :visible="getImportCodeModalVisible.visible"
    hide-footer
    hide-header
    size="lg"
    @hide="setImportCodeModalVisible({ visible: false, setupKey: '' })"
  >
    <div
      class="close"
      @click="setImportCodeModalVisible({ visible: false, setupKey: '' })"
    ></div>
    <div class="title">{{ $t("importCodeModal.layerCustomImport") }}</div>
    <div class="desc" v-html="$t('importCodeModal.copy')"></div>
    <div class="code-snippet">
      <div class="copy-icon" @click="copyToClipboard"></div>
      <textarea
        id="code"
        rows="2"
        readonly="readonly"
        disabled="disabled"
        v-model="model"
      ></textarea>
    </div>
    <div class="button-container">
      <b-button @click="closeModal">
        {{ $t("importCodeModal.close") }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "@/utils/Axios";

export default {
  name: "ImportCodeModal",
  props: ["setupKey"],
  computed: {
    ...mapGetters(["getImportCodeModalVisible", "getCurrentConfiguration"]),
    model: {
      get() {
        if (this.getImportCodeModalVisible.setupKey === "ga4") {
          return this.codeSnippetGa4;
        } else {
          return this.codeSnippet;
        }
      },
    },
    codeSnippet() {
      var scriptString =
        ' <script async defer src="BASE_URL/shops/SHOP_ID/loader"></' +
        "script>";
      return scriptString
        .replace("SHOP_ID", this.getCurrentConfiguration.key)
        .replace("BASE_URL", axios.defaults.baseURL);
    },
    codeSnippetGa4() {
      var scriptString =
        '<!-- Google tag (gtag.js) --> <script async src="https://www.googletagmanager.com/gtag/js?id=TAG_ID"></' +
        'script> <script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag("js", new Date()); gtag("config", "TAG_ID"); </' +
        "script>";
      return scriptString
        .replace("SHOP_ID", this.getCurrentConfiguration.key)
        .replace("BASE_URL", axios.defaults.baseURL);
    },
  },
  methods: {
    ...mapActions(["setImportCodeModalVisible"]),
    closeModal() {
      this.setImportCodeModalVisible({ visible: false, setupKey: "" });
    },
    copyToClipboard() {
      var copyText = document.getElementById("code");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      this.$bvToast.toast(this.$t("importCodeModal.copied"), {
        title: "",
        autoHideDelay: 2000,
        solid: true,
        variant: "success",
      });
    },
  },
};
</script>

<style src="./ImportCodeModal.less" lang="less" scoped></style>
