<template>
  <div class="support">
      SUPPORT
  </div>
</template>

<script>
export default { 
  name: 'Support',
  component:{ }
}
</script>

<style src="./Support.less" lang="less" scoped></style>