<template>
  <div class="billing">
    <div class="overheader"> 
      <div class="title"> {{$t('billing.billingPayments')}} </div>
    </div>
    <Header />
    <router-view/>
  </div>
</template>

<script>
import Header from '@/components/Header/Header'
import { mapGetters } from 'vuex'

export default { 
  name: 'Billing',
  components:{ Header },
  computed:{
    ...mapGetters(['getConfiguration'])
  }
}
</script>

<style src="./Billing.less" lang="less" scoped></style>