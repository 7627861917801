<template>
  <div class="reset-password">
    <div class="label"> {{$t('home.resetPassword.newPassword')}} </div>
    <div class="desc"> {{$t('home.resetPassword.type')}} </div>

    <div :class="['eye',{show:pwdVisibility}]" @click="pwdVisibility=!pwdVisibility"></div>
    <b-form-input class="pwd" :type="pwdVisibility?'password':'text'" :placeholder="$t('home.resetPassword.newPwd')" v-model="password" @change="()=>pwdState=true"/>
    <div class="error" v-if="pwdState && !PWD_REGEX.test(password)"> {{$t('home.signup.passwordContain')}} </div>
    
    <div :class="['eye',{show:repeatPasswordVisibility}]" @click="repeatPasswordVisibility=!repeatPasswordVisibility"></div>
    <b-form-input class="pwd" :type="repeatPasswordVisibility?'password':'text'" :placeholder="$t('home.resetPassword.confirmNewPwd')" v-model="repeatPassword" @change="()=>pwdRepeatState=true"/>
    <div class="error" v-if="pwdRepeatState && repeatPassword!==password"> {{$t('home.signup.passwordSame')}} </div>
    
    <b-button class="cta" @click="changePassword" :disabled="!PWD_REGEX.test(this.password) || repeatPassword!==password"> {{$t('home.forgotPassword.submit')}} </b-button>
  </div>
</template>

<script>
import Utils from '@/utils/Utils';
import axios from '@/utils/Axios';

export default { 
  name: 'ResetPassword' ,
  data(){
    return {
      PWD_REGEX:Utils.PWD_REGEX,
      pwdVisibility:true,
      repeatPasswordVisibility:true,
      pwdRepeatState:false,
      pwdState:false,
      code:'',
      password:'',
      repeatPassword:'',
      activated:'running'
    }
  },
  methods:{
    changePassword(){
      axios.post(Utils.USERS+Utils.PWD+Utils.RECOVERY+"/"+this.code,{password:this.password})
      .then((response)=>{
        if(response.data.status!==Utils.ERROR)
          this.$router.push({'name':'login'})
        else
          this.$bvToast.toast(this.$t('home.signup.something'), {
            title: '',
            autoHideDelay: 2000,
            solid:true,
            variant:'danger'
          })
      })
    }
  },
  computed:{
    activatedLabel(){
      switch(this.activated){
        case 'running': return this.$t('home.activation.pleaseWait');
        case 'ok': return this.$t('home.activation.accountActivated');
        case 'ko': return this.$t('home.activation.errorOccurred');
        default: return '';
      }
    }
  },
  mounted(){
    const urlParams = new URLSearchParams(window.location.search);
    this.code = urlParams.get('code');
  }
}
</script>

<style src="./ResetPassword.less" lang="less" scoped></style>