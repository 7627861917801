var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['config-item', _vm.type]},[(
      _vm.type == 'webhook' &&
        !_vm.$options.isAdvancedOrMore(Object.values(_vm.getSelectedPlan)[0].key)
    )?_c('div',{staticClass:"available"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('configItem.available'))}}),_c('span',{staticClass:"icon"})]):_vm._e(),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('span',{staticClass:"info",on:{"click":function($event){return _vm.openModal('info-' + _vm.setupKey)}}},[_vm._v(" ? ")])]),_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.desc)+" "),(_vm.type == 'webhook')?_c('div',{staticClass:"help"},[_vm._v(" "+_vm._s(_vm.$t("configItem.needHelp"))+" ")]):_vm._e(),(_vm.type == 'webhook')?_c('div',{staticClass:"help"},[_c('a',{staticClass:"doc",attrs:{"href":"https://phase-orchid-049.notion.site/Advanced-Customization-a5db646cc28043a1bf72edac959ac0a3","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("configItem.viewDocumentation"))+" ")])]):_vm._e()]),(_vm.type == 'input')?_c('b-form-input',{attrs:{"value":_vm.isConfig
        ? _vm.getCurrentConfiguration.setup.config[_vm.setupKey]
        : _vm.getCurrentConfiguration.setup[_vm.setupKey]},on:{"change":_vm.onChange}}):(_vm.type == 'boolean')?_c('b-form-checkbox',{attrs:{"checked":_vm.isConfig
        ? _vm.getCurrentConfiguration.setup.config[_vm.setupKey]
        : _vm.getCurrentConfiguration.setup[_vm.setupKey]},on:{"change":_vm.onChange}},[_vm._v(" "+_vm._s(_vm.setupKey == "dynamicFacets" ? _vm.$t("configItem.otherFields") : _vm.$t("configItem.outOfStock"))+" ")]):(_vm.type == 'seo-settings')?_c('b-form-checkbox',{attrs:{"checked":_vm.getCurrentConfiguration.setup.config[_vm.setupKey]
        ? _vm.getCurrentConfiguration.setup.config[_vm.setupKey].isActive
        : false},on:{"change":_vm.onChangeSeo}},[_vm._v(" "+_vm._s(_vm.$t("configItem.activateUrlFriendly"))+" ")]):(_vm.type == 'single-list')?_c('div',{staticClass:"footer-container"},[_c('div',{staticClass:"footer-item"},[_c('div',{staticClass:"list"},[(
            _vm.setupKey == 'productImage'
              ? _vm.getProductImageField(_vm.singleListItem, 'main')
              : _vm.singleListItem
          )?_c('div',{staticClass:"list-item",on:{"click":function($event){return _vm.removeElement(_vm.singleListItem, 'main')}}},[_vm._v(" "+_vm._s(_vm.setupKey == "productImage" ? _vm.getProductImageField(_vm.singleListItem, "main") : _vm.singleListItem)+" "),_c('span')]):_vm._e()]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.openModal(_vm.type + '-' + _vm.title)}}},[_vm._v(" "+_vm._s(_vm.$t("configItem.selectField"))+" ")])]),(_vm.setupKey == 'productImage')?_c('div',{staticClass:"footer-item"},[_c('div',{staticClass:"list"},[(_vm.getProductImageField(_vm.singleListItem, 'hover'))?_c('div',{staticClass:"list-item",on:{"click":function($event){return _vm.removeElement(_vm.singleListItem, 'hover')}}},[_vm._v(" "+_vm._s(_vm.getProductImageField(_vm.singleListItem, "hover"))+" "),_c('span')]):_vm._e()]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.openModal(_vm.type + '-' + _vm.title + '-hover')}}},[_vm._v(" "+_vm._s(_vm.$t("configItem.selectField"))+" ")])]):_vm._e()]):(_vm.type == 'list')?_c('div',{staticClass:"footer-container"},[_c('div',{staticClass:"list"},_vm._l((_vm.valuesToShow),function(item){return _c('div',{key:'item_' + item + '_' + _vm.setupKey,staticClass:"list-item",on:{"click":function($event){return _vm.removeElement(item)}}},[_vm._v(" "+_vm._s(item)+" "),_c('span')])}),0),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.openModal(_vm.type + '-' + _vm.title)}}},[_vm._v(" + "+_vm._s(_vm.$t("configItem.addFields"))+" ")])]):(_vm.type == 'dynamic-list')?_c('div',{staticClass:"footer-container"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t("configItem.otherFields"))+" "),_c('toggle-button',{staticClass:"toggle-button",attrs:{"color":"#398ff4","sync":true,"width":50,"value":_vm.getCurrentConfiguration.setup.config[_vm.setupKey]
            ? _vm.getCurrentConfiguration.setup.config[_vm.setupKey].isActive
            : false},on:{"change":_vm.activationToggle}})],1),_c('button',{class:[
        'btn',
        {
          disabled: !(_vm.getCurrentConfiguration.setup.config[_vm.setupKey]
            ? _vm.getCurrentConfiguration.setup.config[_vm.setupKey].isActive
            : false),
        } ],on:{"click":function($event){return _vm.openModal(_vm.type + '-' + _vm.title)}}},[_vm._v(" Manage Fields ")])]):(_vm.type == 'import')?_c('div',{staticClass:"footer-container"},[_c('button',{staticClass:"btn",on:{"click":function () { return _vm.setImportCodeModalVisible({ visible: true, setupKey: 'import' }); }}},[_vm._v(" "+_vm._s(_vm.$t("configItem.seeHow"))+" ")])]):(_vm.type == 'input-selector')?_c('div',[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.isId ? "Id" : "Name")+" "),_c('toggle-button',{staticClass:"toggle-button",attrs:{"color":"#398ff4","sync":true,"width":50},model:{value:(_vm.isId),callback:function ($$v) {_vm.isId=$$v},expression:"isId"}})],1),_c('div',{staticClass:"label-selector"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("configItem.desktop")))]),_c('b-form-input',{attrs:{"value":_vm.getCurrentConfiguration.setup.config[_vm.setupKey].desktop},on:{"change":function (value) {
            _vm.onChangeSelector(value, 'desktop');
          }}})],1),_c('div',{staticClass:"label-selector"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("configItem.mobile")))]),_c('b-form-input',{attrs:{"value":_vm.getCurrentConfiguration.setup.config[_vm.setupKey].mobile},on:{"change":function (value) {
            _vm.onChangeSelector(value, 'mobile');
          }}})],1)]):(_vm.type == 'feed-url')?_c('div',{staticClass:"feed"},[_c('b-input',{staticClass:"value",attrs:{"type":"text","value":_vm.getCurrentConfiguration.cms_data.feedUrl},on:{"change":function (evt) {
          _vm.onChangeFeed(evt);
          _vm.checkUrl(evt);
        }}}),_c('div',{staticClass:"status"},[(_vm.feedLoading)?_c('b-spinner',{staticClass:"sync",attrs:{"label":"Spinning"}}):_vm._e(),(_vm.incorrectFeedUrl)?_c('div',{staticClass:"ko"},[_vm._v(" "+_vm._s(_vm.$t("configItem.wrongFeed"))+" ")]):_vm._e(),(!_vm.feedLoading && !_vm.incorrectFeedUrl)?_c('div',{staticClass:"ok"},[_vm._v(" "+_vm._s(_vm.$t("configItem.correctFeed"))+" ")]):_vm._e()],1)],1):(_vm.type == 'lang')?_c('div',[_c('b-form-select',{attrs:{"disabled":!_vm.getCurrentConfiguration.editable_widgets.includes('language_iso'),"value":_vm.getCurrentConfiguration.language_iso,"options":_vm.languageOptions},on:{"change":_vm.onChangeLang}})],1):(_vm.type == 'webhook')?_c('div',{class:{
      disabled: !_vm.$options.isAdvancedOrMore(
        Object.values(_vm.getSelectedPlan)[0].key
      ),
    }},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t("configItem.webhook"))+" "),_c('toggle-button',{staticClass:"toggle-button",attrs:{"color":"#398ff4","sync":true,"width":50,"value":_vm.getCurrentConfiguration.setup.config[_vm.setupKey]
            ? _vm.getCurrentConfiguration.setup.config[_vm.setupKey].isActive
            : false},on:{"change":_vm.activationToggle}})],1),(_vm.getCurrentConfiguration.setup.config[_vm.setupKey].isActive)?_c('div',{staticClass:"content-container"},[_c('div',{staticClass:"label"}),_c('b-form-input',{attrs:{"value":_vm.getCurrentConfiguration.setup.config[_vm.setupKey]
            ? _vm.getCurrentConfiguration.setup.config[_vm.setupKey].url
            : ''},on:{"change":function (value) {
            _vm.onChangeSelector(value, 'url');
          }}}),_c('div',{staticClass:"label"}),_c('div',{staticClass:"footer-item"},[_c('div',{staticClass:"list"},[(_vm.singleListItem)?_c('div',{staticClass:"list-item",on:{"click":function($event){return _vm.removeElement(_vm.singleListItem)}}},[_vm._v(" "+_vm._s(_vm.singleListItem)+" "),_c('span')]):_vm._e()]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.openModal(_vm.type + '-' + _vm.title)}}},[_vm._v(" "+_vm._s(_vm.$t("configItem.selectField"))+" ")])])],1):_vm._e()]):(_vm.type == 'boosting')?_c('div',[_c('div',{staticClass:"content-container"},[_c('div',{staticClass:"footer-item"},[_c('div',{staticClass:"list"},[(_vm.singleListItem)?_c('div',{staticClass:"list-item",on:{"click":function($event){return _vm.removeElement(_vm.singleListItem)}}},[_vm._v(" "+_vm._s(_vm.singleListItem)+" "),_c('span')]):_vm._e()]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.openModal(_vm.type + '-' + _vm.title)}}},[_vm._v(" "+_vm._s(_vm.$t("configItem.selectField"))+" ")])])])]):(_vm.type == 'groupPrice')?_c('div',[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("configItem.defaultListPrice")))]),_c('b-input',{staticClass:"value",attrs:{"type":"text","value":_vm.getCurrentConfiguration.setup.config[_vm.setupKey]
          ? _vm.getCurrentConfiguration.setup.config[_vm.setupKey].priceGroupId
          : ''},on:{"change":function (value) {
          _vm.onChangeSelector(value, 'priceGroupId');
        }}}),_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("configItem.defaultCurrency")))]),_c('b-select',{staticClass:"value",attrs:{"type":"text","value":_vm.getCurrentConfiguration.setup.config[_vm.setupKey]
          ? _vm.getCurrentConfiguration.setup.config[_vm.setupKey].currencyCode
          : '',"options":_vm.currenciesOptions},on:{"change":function (value) {
          _vm.onChangeSelector(value, 'currencyCode');
        }}})],1):_vm._e(),(_vm.type == 'checkbox')?_c('div',[_c('b-form-checkbox',{attrs:{"checked":_vm.getCurrentConfiguration.setup.config[_vm.setupKey]
          ? _vm.getCurrentConfiguration.setup.config[_vm.setupKey].isActive
          : false},on:{"change":function (value) { return _vm.onChangeSelector(value, 'isActive'); }}},[_vm._v(" "+_vm._s(_vm.$t("configItem.ga4Events"))+" ")]),_c('b-form-select',{attrs:{"disabled":_vm.getCurrentConfiguration.setup.config[_vm.setupKey]
          ? !_vm.getCurrentConfiguration.setup.config[_vm.setupKey].isActive
          : true,"value":_vm.getCurrentConfiguration.setup.config[_vm.setupKey]
          ? _vm.getCurrentConfiguration.setup.config[_vm.setupKey].type
          : 1,"options":_vm.ga4Options},on:{"change":function (value) { return _vm.onChangeSelector(value, 'type'); }}}),(
        _vm.getCurrentConfiguration.setup.config[_vm.setupKey] &&
          _vm.getCurrentConfiguration.setup.config[_vm.setupKey].type == '1'
      )?_c('div',{staticClass:"footer-container"},[_c('button',{staticClass:"btn",attrs:{"disabled":_vm.getCurrentConfiguration.setup.config[_vm.setupKey].isActive
            ? !_vm.getCurrentConfiguration.setup.config[_vm.setupKey].isActive
            : true},on:{"click":function () { return _vm.setImportCodeModalVisible({ visible: true, setupKey: 'ga4' }); }}},[_vm._v(" "+_vm._s(_vm.$t("configItem.seeHow"))+" ")])]):_vm._e()],1):_vm._e(),(_vm.type == 'seo-settings')?_c('div',[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("configItem.queryString")))]),_c('div',{staticClass:"url-params"},[_c('b-input',{staticClass:"value",attrs:{"type":"text","value":_vm.getCurrentConfiguration.setup.config[_vm.setupKey]
            ? _vm.getCurrentConfiguration.setup.config[_vm.setupKey].searchWord
            : 'search'},on:{"change":function (value) {
            _vm.onChangeSelector(value, 'searchWord');
          }}}),_c('div',{staticClass:"example"},[_c('div',{staticClass:"label-small"},[_vm._v("Example")]),_c('div',{staticClass:"label-small"},[_vm._v(" store.com/#/"),_c('b',[_vm._v(_vm._s(_vm.getCurrentConfiguration.setup.config[_vm.setupKey] ? _vm.getCurrentConfiguration.setup.config[_vm.setupKey].searchWord : "search"))]),_vm._v("/?query=jumper ")])])],1),_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("configItem.uploadSeoCsv")))]),_c('div',{staticClass:"edit-label",on:{"click":function($event){return _vm.onImportClick()}}},[_vm._v(" "+_vm._s(_vm.$t("configItem.importSeoValues"))+" ")]),_c('div',{staticClass:"divider"},[_vm._v(_vm._s(_vm.$t("configItem.seoOmptimized")))]),_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("configItem.seoOmptimizedDesc")))]),_c('div',{staticClass:"mandatory"},[_c('div',{staticClass:"icon"}),_vm._v(" "+_vm._s(_vm.$t("configItem.mandatoryAttributes"))+" ")]),_c('button',{staticClass:"btn left",attrs:{"disabled":!_vm.getCurrentConfiguration.setup.config[_vm.setupKey] ||
          !_vm.getCurrentConfiguration.setup.config[_vm.setupKey].isActive},on:{"click":function($event){return _vm.openModal(_vm.type + '-' + _vm.title)}}},[_vm._v(" + "+_vm._s(_vm.$t("configItem.manageAttributes"))+" ")]),_c('b-modal',{attrs:{"id":"values-modal","hide-footer":"","hide-header":"","size":"lg"},model:{value:(_vm.valuesModal),callback:function ($$v) {_vm.valuesModal=$$v},expression:"valuesModal"}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("configItem.importSeoValues")))]),_c('div',{staticClass:"close",on:{"click":function () { return (_vm.valuesModal = false); }}}),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.$t("configItem.importSeoDesc")))]),_c('div',{staticClass:"button download",on:{"click":function () { return _vm.$options.downloadJSON2CSV(_vm.seoValues, 'seo-values'); }}},[_vm._v(" "+_vm._s(_vm.$t("layoutDesign.general.downloadSample"))+" "),_c('span',{staticClass:"icon"})]),_c('input',{ref:"upload",attrs:{"type":"file","accept":".csv, text/csv"},on:{"input":_vm.pickFile}}),(
          _vm.getCurrentConfiguration.setup.config.seoSettings &&
            !_vm.getCurrentConfiguration.setup.config.seoSettings.filename
        )?_c('div',{staticClass:"upload-container",on:{"click":function () { return _vm.$refs.upload.click(); }}},[_c('div',{staticClass:"icon"}),_c('div',{staticClass:"desc",domProps:{"innerHTML":_vm._s(_vm.$t('layoutDesign.general.uploadCSVData'))}}),_c('div',{staticClass:"sub-desc"},[_vm._v(_vm._s(_vm.$t("layoutDesign.general.CSVOnly")))])]):[_c('div',{staticClass:"file-container"},[_c('div',{staticClass:"uploaded"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.getCurrentConfiguration.setup.config.seoSettings ? _vm.getCurrentConfiguration.setup.config.seoSettings.filename : "")+" ")]),_c('div',{staticClass:"remove",on:{"click":_vm.removeCSV}}),_c('div',{staticClass:"download",on:{"click":function () { return _vm.$options.downloadJSON2CSV(
                    _vm.getCurrentConfiguration.setup.config.seoSettings
                      ? _vm.getCurrentConfiguration.setup.config.seoSettings
                          .csvData
                      : {},
                    'seo-values'
                  ); }}})]),_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t("layoutDesign.general.filePreview"))+" ")]),_c('perfect-scrollbar',{staticClass:"preview"},[_c('textarea',{attrs:{"readonly":"readonly","disabled":"disabled","rows":"20"},domProps:{"value":_vm.getCurrentConfiguration.setup.config.seoSettings
                  ? _vm.$options.convertJsonTranslationToCSV(
                      _vm.getCurrentConfiguration.setup.config.seoSettings.csvData
                    )
                  : ''}})])],1)]],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }