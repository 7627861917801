<template>
  <div
    :class="[
      'save-changes',
      { 'with-changes': getHistoryChanges.length > 0 },
      { beforeUnload: beforeUnload },
      { locked: !selectedPlanValue.isActive },
    ]"
  >
    <div class="relative-save">
      <div
        :class="[
          'version',
          { 'old-version': savedVersion !== currentVersion.versionName },
        ]"
        @click="showVersions"
        v-click-outside="hideVersions"
      >
        <div class="version-icon-main">?</div>
        {{ $t("menu.version") }}
        {{ currentVersion.versionName.replace("v", "") }}
        <div class="arrow-down"></div>
        <div id="version-toast" v-if="visible">
          <b-card-group
            v-for="(version, index) in versions"
            :key="'version_' + index"
          >
            <b-card no-body>
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle="'accordion-' + index"
                  @click="setCollapsedIndex(index)"
                >
                  <div class="version-icon">?</div>
                  <div class="btn-text">
                    {{ $t("menu.version")
                    }}{{ version.versionName.replace("v", "") }}
                  </div>
                  <div
                    :class="
                      collapsedIndex === index
                        ? 'arrow-up-btn'
                        : 'arrow-down-btn'
                    "
                  ></div>
                </b-button>
              </b-card-header>
              <b-collapse
                :id="'accordion-' + index"
                accordion="my-accordion"
                role="tabpanel"
                :visible="version.versionName === currentVersion.versionName"
              >
                <b-card-body @click="onChangeVersion(version)">
                  <b-card-text>{{
                    $t("menu.version") +
                      version.versionName.replace("v", "") +
                      $t("saveChanges.currentVersion2").replace("version", "") +
                      $t("saveChanges.versionInfo")
                  }}</b-card-text>
                  <b-card-text>{{
                    version.description[$i18n.locale]
                  }}</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-card-group>
        </div>
      </div>

      <div
        class="activation-toggle"
        v-if="getHistoryChanges.length == 0"
        @click="toBillingInfo"
      >
        <div class="label" v-if="!selectedPlanValue.isActive">
          {{ $t("saveChanges.locked") }}
        </div>
        <div class="label" v-else>
          {{
            getCurrentConfiguration.is_active
              ? $t("saveChanges.published")
              : $t("saveChanges.notPublished")
          }}
        </div>
        <div id="version-tooltip">
          <toggle-button
            class="toggle-button"
            color="#398ff4"
            :sync="true"
            :width="40"
            :value="getCurrentConfiguration.is_active"
            @change="activationToggle"
          />
        </div>
        <b-tooltip
          target="version-tooltip"
          placement="top"
          class="custom-tootlip"
        >
          {{
            $t("saveChanges.currentVersion") +
              currentVersion.versionName.replace("v", "") +
              $t("saveChanges.currentVersion2")
          }}
        </b-tooltip>
      </div>
      <div class="changes-container" v-if="getHistoryChanges.length > 0">
        <div class="info"></div>
        <div class="buttons">
          <div class="discard" @click="discardChanges"><a class="icon" /></div>
          <div
            class="update"
            id="next-version-tooltip"
            :class="{
              'is-active': getCurrentConfiguration.is_active,
              disabled: disabled,
            }"
            @click="handleSaveChanges"
          >
            {{ $t("saveChanges.saveChanges") }}
          </div>
          <b-tooltip
            target="next-version-tooltip"
            placement="top"
            class="custom-tootlip"
          >
            {{
              $t("saveChanges.nextVersion").replace(
                "[version]",
                currentVersion.versionName.replace("v", "")
              )
            }}
          </b-tooltip>
          <b-modal
            id="change-version-modal"
            hide-footer
            hide-header
            size="lg"
            v-model="versionModal"
          >
            <div class="close" @click="() => (versionModal = false)"></div>
            <div class="title"></div>
            <div class="desc">
              {{
                $t("saveChanges.confirmChanges").replace(
                  "[version]",
                  currentVersion.versionName.replace("v", "")
                )
              }}
            </div>
            <div class="btn-confirm">
              <button class="button" @click="saveChanges">
                {{ $t("layoutDesign.desktop.confirm") }}
              </button>
            </div>
          </b-modal>
        </div>
      </div>
      <div class="preview-container">
        <div class="preview" @click="toPreview">
          {{ $t("saveChanges.seePreview") }} <a class="icon" />
        </div>
        <div class="tutorial"><a class="icon" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import axios from "@/utils/Axios"
import Utils from "@/utils/Utils"
import lodash from "lodash"
import Vue from "vue"
Vue.directive("click-outside", {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener("click", el.clickOutsideEvent)
  },
  unbind: function(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent)
  },
})
export default {
  name: "SaveChanges",
  data() {
    return {
      versions: [],
      currentVersion: null,
      visible: false,
      collapsedIndex: 0,
      savedVersion: "",
      versionModal: false,
    }
  },
  watch: {
    $route(to, from) {
      if (from.fullPath !== to.fullPath) {
        if (this.savedVersion !== this.currentVersion.versionName) {
          this.getVersions()
        }
      }
    },
  },
  methods: {
    ...mapActions([
      "setWebsites",
      "setCurrentConfigurationByField",
      "setCurrentConfiguration",
      "setHistoryChanges",
      "setPreviewConfig",
      "getCurrentConfiguration",
    ]),
    async activationToggle(evt) {
      if (this.selectedPlanValue.isActive) {
        var restUrl =
          Utils.SHOPS + "/" + this.getCurrentConfiguration.id + Utils.ACTIVATION
        if (evt.value)
          await axios
            .post(restUrl)
            .then(() =>
              this.setCurrentConfigurationByField({
                field: "is_active",
                value: evt.value,
              })
            )
        else
          await axios
            .delete(restUrl)
            .then(() =>
              this.setCurrentConfigurationByField({
                field: "is_active",
                value: evt.value,
              })
            )
        axios
          .get(Utils.SHOPS)
          .then((response) => this.setWebsites(response.data))
      }
    },
    showVersions() {
      this.visible = true
    },
    hideVersions() {
      this.visible = false
    },
    setCollapsedIndex(index) {
      if (this.collapsedIndex === index) {
        this.collapsedIndex = -1
      } else {
        this.collapsedIndex = index
      }
    },
    async getVersions() {
      await axios
        .get(Utils.VERSIONS)
        .then((response) => (this.versions = response.data))
      const currentVersionName = this.getCurrentConfiguration.setup.config
        .layerVersion
      this.versions?.forEach((version, index) => {
        if (currentVersionName && version.versionName === currentVersionName) {
          this.currentVersion = version
          this.collapsedIndex = index
        }
      })
      this.savedVersion = currentVersionName
    },
    onChangeVersion(value) {
      var historyChanges = lodash.cloneDeep(this.getHistoryChanges)
      historyChanges.push(lodash.cloneDeep(this.getCurrentConfiguration))
      this.setHistoryChanges(historyChanges)
      var objectToReturn = { ...this.getCurrentConfiguration.setup }
      objectToReturn.config.layerVersion = value?.versionName
      this.setCurrentConfigurationByField({
        field: "setup",
        value: objectToReturn,
      })
      this.hideVersions()
      this.currentVersion = value
    },
    toBillingInfo() {
      if (!this.selectedPlanValue.isActive)
        this.$router.push({ name: "billing-information" })
    },
    discardChanges() {
      var historyChanges = lodash.cloneDeep(this.getHistoryChanges)
      this.setCurrentConfiguration(
        lodash.clone(historyChanges[historyChanges.length - 1])
      )
      historyChanges.pop()
      this.setHistoryChanges(historyChanges)
      this.$root.$emit("discard-changes")
      const currentVersionName = this.getCurrentConfiguration.setup.config
        .layerVersion
      this.versions?.forEach((version, index) => {
        if (currentVersionName && version.versionName === currentVersionName) {
          this.currentVersion = version
          this.collapsedIndex = index
        }
      })
    },
    handleSaveChanges() {
      if (this.savedVersion !== this.currentVersion.versionName) {
        this.versionModal = true
      } else {
        this.saveChanges()
      }
    },
    async saveChanges() {
      var objectToSend = {}
      var objectKey = ""
      if (this.$route.matched[0].name == "setup") objectKey = "setup"
      else if (this.$route.matched[0].name == "fineTuning")
        objectKey = "fineTuning"
      else if (this.$route.matched[0].name == "layoutDesign")
        objectKey = "layout"
      objectToSend[objectKey] = lodash.cloneDeep(
        this.getCurrentConfiguration[objectKey]
      )
      objectToSend["setup"] = lodash.cloneDeep(
        this.getCurrentConfiguration["setup"]
      )
      if (objectKey == "setup") {
        if (objectToSend.setup.language_iso) {
          await axios.put(
            Utils.SHOPS + "/" + this.getCurrentConfiguration.id + Utils.LANG,
            { iso6391: objectToSend.setup.language_iso }
          )
          this.$bvToast.toast("Custom import code changed!", {
            title: "",
            autoHideDelay: 2000,
            solid: true,
            variant: "warning",
          })
          delete objectToSend.setup.language_iso
        }
        if (objectToSend.setup.feedUrl) {
          var subObjectToSend = lodash.cloneDeep(
            this.getCurrentConfiguration.cms_data
          )
          subObjectToSend.feedUrl = objectToSend.setup.feedUrl
          await axios.put(
            Utils.SHOPS + "/" + this.getCurrentConfiguration.id + Utils.DATA,
            subObjectToSend
          )
          delete objectToSend.setup.feedUrl
        }
      }
      await axios
        .put(
          Utils.SHOPS + "/" + this.getCurrentConfiguration.id + Utils.CONF,
          objectToSend
        )
        .then((response) => {
          this.setWebsites(response.data)
          // this.setCurrentConfiguration(lodash.clone(Utils.getConfigurationFromWebsite(response.data,this.getCurrentConfiguration)));
        })
      this.setHistoryChanges([])
      setTimeout(() => this.$root.$emit("check-indexing"), 1000)
      this.savedVersion = this.currentVersion.versionName
    },
    toPreview() {
      var windowReference = window.open()
      Utils.fromConfigToConfig(this["getCurrentConfiguration"]).then(
        (config) => {
          localStorage.setItem("config", JSON.stringify(config))
          let routeData = this.$router.resolve({ name: "preview" })
          windowReference.location = routeData.href
        }
      )
    },
    beforeWindowUnload(e) {
      e.preventDefault()
      return (e.returnValue = "")
    },
  },
  computed: {
    ...mapGetters([
      "getDisplayedWebsites",
      "getCurrentConfiguration",
      "getHistoryChanges",
      "getSelectedPlan",
    ]),
    disabled() {
      return this.getHistoryChanges.length == 0
        ? false
        : Utils.checkChanges(
            this.getDisplayedWebsites,
            this.getCurrentConfiguration
          )
    },
    beforeUnload() {
      if (this.getHistoryChanges.length > 0)
        window.addEventListener("beforeunload", this.beforeWindowUnload)
      else window.removeEventListener("beforeunload", this.beforeWindowUnload)
      return this.getHistoryChanges.length > 0
    },
    selectedPlanValue() {
      return Object.values(this.getSelectedPlan)[0]
        ? Object.values(this.getSelectedPlan)[0]
        : {}
    },
  },
  mounted() {
    this.$root.$on("save-changes", this.saveChanges)
    this.getVersions()
    console.log("lv: ", this.getCurrentConfiguration)
  },
}
</script>

<style src="./SaveChanges.less" lang="less" scoped></style>
