import { render, staticRenderFns } from "./Campaigns.vue?vue&type=template&id=582e884a&scoped=true&"
import script from "./Campaigns.vue?vue&type=script&lang=js&"
export * from "./Campaigns.vue?vue&type=script&lang=js&"
import style0 from "./Campaigns.less?vue&type=style&index=0&id=582e884a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "582e884a",
  null
  
)

export default component.exports