<template>
  <div class="home">
      <div class="as-card">
        <div class="logo">
          <div></div>
        </div>
        <div class="form">
          <router-view/>
        </div>
      </div>
      <div class="rightbg">
        <b-form-select v-model="$i18n.locale" :options="languageOptions" @change="()=>setChosenLanguage(setChosenLanguage)"></b-form-select>
        <div class="content-container">
          <div class="title"> {{$t('home.experience')}} </div>
          <div class="sub-title"> {{$t('home.enhance')}} </div>
          <div class="logos">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import lodash from 'lodash';
import { mapGetters,mapActions } from 'vuex'

export default { 
  name: 'Home', 
  computed:{
    ...mapGetters(['getBOLanguages']),
    languageOptions(){
      var arrayToReturn=lodash.cloneDeep(this.getBOLanguages);
      arrayToReturn.splice(0,0,{value: null, text: '-- Select --', disabled:true})
      return arrayToReturn;
    }
  },
  methods:{
    ...mapActions(['setChosenLanguage'])
  }
}
</script>

<style src="./Home.less" lang="less" scoped></style>