<template>
  <div class="layout-design">
    <div class="overheader"> 
      <div class="title"> {{$t('layoutDesign.layoutDesign')}} </div>
    </div>
    <Header />
    <router-view/>
  </div>
</template>

<script>
import Header from '@/components/Header/Header'
import { mapActions, mapGetters } from 'vuex'
import lodash from 'lodash';
import axios from '@/utils/Axios';
import Utils from '@/utils/Utils'

export default { 
  name: 'LayoutDesign',
  components:{ Header },
  computed:{
    ...mapGetters(['getCurrentConfiguration'])
  },
  methods:{
    ...mapActions(['setCurrentConfigurationByField','setWebsiteAttributes']),
    initializeFacets(initialFacets){
      var arrayToReturn=[];
      var facets=Object.keys(this['getCurrentConfiguration'].setup.facets);
      initialFacets.forEach(initialFacet=>{
        if((initialFacet.name=='sellingPrice' || initialFacet.name=='categories') && !facets.includes(initialFacet.name)) facets.push(initialFacet.name)
      })
      facets.forEach(facet=>{
        if(initialFacets.filter(item=>item.name==facet).length==0)
          arrayToReturn.push({
            name:facet,
            label:facet,
            filterSorting:"results",
            type:"checkbox-MC",
            withSearch:false,
            active:false
          })
        else arrayToReturn.push(initialFacets.filter(item=>item.name==facet)[0])
      })
      return arrayToReturn;
    }
  },
  mounted(){
    axios.get(Utils.SHOPS+'/'+this.getCurrentConfiguration.id+Utils.ATTR)
    .then((response)=>{ 
      this.setWebsiteAttributes(response.data); 
    })
    var initialObject=lodash.cloneDeep(this['getCurrentConfiguration'].layout);
    if(!this['getCurrentConfiguration'].layout){
      initialObject.language={}
      initialObject.sort={}
      initialObject.icebreaker={}
      initialObject.desktop={}
      initialObject.mobile={}
      initialObject.facets=this.initializeFacets(initialObject.facets);
      this.setCurrentConfigurationByField({field:'layout',value:initialObject})
    } else {
      if(!this['getCurrentConfiguration'].layout.language || this['getCurrentConfiguration'].layout.language.length==0)
        initialObject.language={};
      if(!this['getCurrentConfiguration'].layout.facets)
        initialObject.facets=[];
      if(!this['getCurrentConfiguration'].layout.sort || this['getCurrentConfiguration'].layout.sort.length==0){
        initialObject.sort={};
        initialObject.sort.values=[
          {"label":'Relevance',"key":"score","value":"DESC",'isDefault':true,"active":true,desc:this.$t('layoutDesign.relevanceDesc')},
          {"label":"Price - Ascending","key":"selling_price","value":"ASC",'isDefault':true,"active":true,desc:this.$t('layoutDesign.priceAscendingDesc')},
          {"label":"Price - Descending","key":"selling_price","value":"DESC",'isDefault':true,"active":true,desc:this.$t('layoutDesign.priceDescendingDesc')}
        ]
      }if(!this['getCurrentConfiguration'].layout.icebreaker || this['getCurrentConfiguration'].layout.icebreaker.length==0)
        initialObject.icebreaker={};
      if(!this['getCurrentConfiguration'].layout.desktop || this['getCurrentConfiguration'].layout.desktop.length==0)
        initialObject.desktop={};
      if(!this['getCurrentConfiguration'].layout.mobile || this['getCurrentConfiguration'].layout.mobile.length==0)
        initialObject.mobile={};
      initialObject.facets=this.initializeFacets(initialObject.facets);
      this.setCurrentConfigurationByField({field:'layout',value:initialObject});
    }
  }
}
</script>

<style src="./LayoutDesign.less" lang="less" scoped></style>