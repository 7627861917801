<template>
  <div class="notfound">
      NOTFOUND
  </div>
</template>

<script>

export default { name: 'Notfound' }
</script>

<style src="./Notfound.less" lang="less" scoped></style>