<template>
  <div class="account-settings">
    <div class="title"> {{$t('accountSettings.accountSettings')}} </div>
    <div class="info-container">
      <div class="basic-info">
        <div class="title"> {{$t('accountSettings.userInformation')}} </div>
        <div class="info-row">
          <div class="input-element">
            <div class="label"> {{$t('accountSettings.firstName')}} </div>
            <b-input :placeholder="$t('accountSettings.firstName')" v-model="userInfo.firstName"></b-input>
          </div>
          <div class="input-element">
            <div class="label"> {{$t('accountSettings.lastName')}} </div>
            <b-input :placeholder="$t('accountSettings.lastName')" v-model="userInfo.lastName"></b-input>
          </div>
        </div>
        <div class="info-row">
          <div class="input-element">
            <div class="label"> *{{$t('accountSettings.email')}} </div>
            <b-input :placeholder="$t('accountSettings.email')" v-model="userInfo.email"></b-input>
            <div class="error" v-if="!$options.EMAIL_REGEX.test(userInfo.email)"> {{$t('accountSettings.incorrectEmail')}} </div>
          </div>
          <div class="input-element">
            <div class="label"> {{$t('accountSettings.phone')}} </div>
            <b-input :placeholder="$t('accountSettings.phone')" v-model="userInfo.phone"></b-input>
          </div>
        </div>
        <!-- <div class="info-row">
          <div class="input-element">
            <div class="label"> {{$t('accountSettings.company')}} </div>
            <b-input placeholder="Company" v-model="userInfo.companyName" @change="userInfo={...userInfo}"></b-input>
          </div>
        </div> -->
        <div class="info-row">
          <div class="input-element">
            <div class="label"> {{$t('accountSettings.resellerCode')}} </div>
            <b-input :placeholder="$t('accountSettings.resellerCode')" v-model="userInfo.resellerCode" @change="userInfo={...userInfo}"></b-input>
          </div>
        </div>
        <div class="footer">
          <b-button :class="[{disabled:disabledUpdate}]" @click="updateUserInfo">
            <b-spinner v-if="updatingInfo"/> 
            <span v-else> {{$t('accountSettings.update')}} </span>
          </b-button>
        </div>

        <div class="title"> {{$t('accountSettings.systemLanguage')}} </div>
        <div class="info-row">
          <b-form-select v-model="selectedLang" :options="languageOptions"></b-form-select>
        </div>
        <div class="footer">
          <b-button :class="[{disabled:getUserInfo.languageIso===selectedLang}]" @click="updateLang">
            <b-spinner v-if="updatingLanguage"/> 
            <span v-else> {{$t('accountSettings.update')}} </span>
          </b-button>
        </div>
      </div>
      <div class="change-pass">
        <div class="title"> {{$t('accountSettings.changePassword')}} </div>
        <div class="info-row">
          <div class="input-element">
            <div class="label"> {{$t('accountSettings.currentPassword')}}</div>
            <div :class="['eye',{show:pwdVisibility}]" @click="pwdVisibility=!pwdVisibility"></div>
            <!-- fake fields are a workaround for chrome autofill getting the wrong fields -->
            <input style="opacity: 0; position: absolute; z-index: -1;" type="text" name="fakeusernameremembered" />
            <b-input :type="pwdVisibility?'password':'text'" v-model="pwd"></b-input>
            <div class="error" v-if="!$options.PWD_REGEX.test(pwd) && pwd.length>4"> {{$t('accountSettings.passwordMustContain')}} </div>
          </div>
        </div>
        <div class="info-row">
          <div class="input-element">
            <div class="label"> {{$t('accountSettings.newPassword')}} </div>
            <div :class="['eye',{show:newPwdVisibility}]" @click="newPwdVisibility=!newPwdVisibility"></div>
            <b-input :type="newPwdVisibility?'password':'text'" v-model="newPwd"></b-input>
            <div class="error" v-if="!$options.PWD_REGEX.test(newPwd) && newPwd.length>4"> {{$t('accountSettings.passwordMustContain')}} </div>
            <div class="error" v-else-if="pwd==newPwd && newPwd.length>4"> {{$t('accountSettings.passwordMustBeDifferent')}} </div>
          </div>
        </div>
        <div class="info-row">
          <div class="input-element">
            <div class="label"> {{$t('accountSettings.repeatNewPassword')}} </div>
            <div :class="['eye',{show:repeatNewPwdVisibility}]" @click="repeatNewPwdVisibility=!repeatNewPwdVisibility"></div>
            <b-input :type="repeatNewPwdVisibility?'password':'text'" v-model="repeatNewPwd"></b-input>
            <div class="error" v-if="newPwd!=repeatNewPwd && repeatNewPwd.length>4"> {{$t('accountSettings.passwordIsDifferent')}} </div>
          </div>
        </div>
        <div class="footer">
          <b-button :class="[{disabled:disabledUpdatePwd}]" @click="updatePassword"> 
            <b-spinner v-if="updatingPassword"/> 
            <span v-else> {{$t('accountSettings.update')}} </span>
          </b-button>
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Utils from '@/utils/Utils'
import axios from '@/utils/Axios';
import lodash from 'lodash'

export default { 
  name: 'AccountSettings',
  EMAIL_REGEX:Utils.EMAIL_REGEX,
  PWD_REGEX:Utils.PWD_REGEX,
  data(){
    return {
      pwd:"",
      newPwd:"",
      repeatNewPwd:"",
      userInfo:{},
      updatingInfo:false,
      pwdVisibility:true,
      newPwdVisibility:true,
      repeatNewPwdVisibility:true,
      updatingPassword:false,
      updatingLanguage:false,
      selectedLang:""
    }
  },
  computed:{
    ...mapGetters(['getUserInfo','getBOLanguages','getBillingInfo']),
    disabledUpdate(){
      return !Utils.EMAIL_REGEX.test(this.userInfo.email) || this.updatingInfo || ( 
        this.userInfo.email==this.getUserInfo.email && 
        this.userInfo.firstName==this.getUserInfo.firstName &&
        this.userInfo.lastName==this.getUserInfo.lastName &&
        this.userInfo.phone==this.getUserInfo.phone &&
        this.userInfo.resellerCode==this.getBillingInfo.resellerCode /* &&
        this.userInfo.companyName==this.getBillingInfo.companyName */
      )
    },
    userInfoModified(){
      return this.userInfo.firstName!==this.getUserInfo.firstName ||
      this.userInfo.lastName!==this.getUserInfo.lastName ||
      this.userInfo.phone!==this.getUserInfo.phone ||
      this.userInfo.email!==this.getUserInfo.email
    },
    disabledUpdatePwd(){
      return !Utils.PWD_REGEX.test(this.pwd) || !Utils.PWD_REGEX.test(this.newPwd) || this.newPwd!==this.repeatNewPwd || this.newPwd==this.pwd || this.updatingPassword
    },
    languageOptions(){
      var arrayToReturn=lodash.cloneDeep(this.getBOLanguages);
      arrayToReturn.splice(0,0,{value: null, text: '-- Select --', disabled:true})
      return arrayToReturn;
    }
  },
  methods:{
    ...mapActions(['setUserInfo','setBillingInfo']),
    async updateUserInfo(){
      var objectToSend=lodash.cloneDeep(this.userInfo);
      if(this.userInfoModified){
        this.updatingInfo=true;
        delete objectToSend.companyName;
        delete objectToSend.resellerCode;
        delete objectToSend.lang;
        await axios.put(Utils.USERS+Utils.ME,objectToSend)
        .then(response=>{
          if(response.data.status=="OK"){
            this.$bvToast.toast(this.$t('accountSettings.userInfoUpdated'), {
              title: '',
              autoHideDelay: 2000,
              solid:true,
              variant:'success'
            })
            this.setUserInfo(lodash.cloneDeep(this.userInfo));
          } else 
          this.$bvToast.toast(this.$t('accountSettings.somethingWentWrong'), {
            title: '',
            autoHideDelay: 2000,
            solid:true,
            variant:'danger'
          })
        })
        .catch(()=>
          this.$bvToast.toast(this.$t('accountSettings.somethingWentWrong'), {
            title: '',
            autoHideDelay: 2000,
            solid:true,
            variant:'danger'
          })
        )
        .finally(()=>this.updatingInfo=false)
      }
      if(this.getBillingInfo.companyName!==this.userInfo.companyName || this.getBillingInfo.resellerCode!==this.userInfo.resellerCode){
        this.updatingInfo=true;
        objectToSend={};
        // objectToSend.companyName=lodash.cloneDeep(this.userInfo.companyName)
        objectToSend.resellerCode=lodash.cloneDeep(this.userInfo.resellerCode)
        axios.put(Utils.CUSTOMERS+Utils.ME,objectToSend)
        .then(response=>{
          if(response.data.status=="OK"){
            if(!this.userInfoModified) this.$bvToast.toast(this.$t('accountSettings.userInfoUpdated'), {
              title: '',
              autoHideDelay: 2000,
              solid:true,
              variant:'success'
            })
            var billingInfo=lodash.cloneDeep(this.getBillingInfo);
            // billingInfo.companyName=this.userInfo.companyName;
            billingInfo.resellerCode=this.userInfo.resellerCode;
            this.setBillingInfo({...billingInfo});
          }
        })
        .finally(()=>this.updatingInfo=false)
      }
    },
    updatePassword(){
      var objectToSend={};
      objectToSend.password=this.newPwd;
      objectToSend.previousPassword=this.pwd;
      this.updatingPassword=true;
      axios.put(Utils.USERS+Utils.ME,objectToSend)
      .then(response=>{
        if(response.data.status=="ERROR")
          this.$bvToast.toast(this.$t('accountSettings.somethingWentWrong'), {
            title: '',
            autoHideDelay: 2000,
            solid:true,
            variant:'danger'
          })
        else
          this.$bvToast.toast(this.$t('accountSettings.passwordChanged'), {
            title: '',
            autoHideDelay: 2000,
            solid:true,
            variant:'success'
          })
      })
      .finally(()=>this.updatingPassword=false)
    },
    updateLang(){
      // come l'updatePassword ma valorizzando il campo lang, passarlo come iso6391
      var objectToSend={};
      objectToSend.languageIso=this.selectedLang;
      this.updatingLanguage=true;
      axios.put(Utils.USERS+Utils.ME,objectToSend)
      .then(response=>{
        if(response.data.status=="ERROR")
          this.$bvToast.toast(this.$t('accountSettings.somethingWentWrong'), {
            title: '',
            autoHideDelay: 2000,
            solid:true,
            variant:'danger'
          })
        else{
          this.$i18n.locale=this.selectedLang;
          var userInfo=lodash.cloneDeep(this.getUserInfo);
          userInfo.languageIso=this.selectedLang;
          this.setUserInfo({...userInfo});
          this.$bvToast.toast(this.$t('accountSettings.languageChanged'), {
            title: '',
            autoHideDelay: 2000,
            solid:true,
            variant:'success'
          });
        }
      })
      .finally(()=>this.updatingLanguage=false)
    }
  },
  mounted(){
    this.userInfo=lodash.cloneDeep(this.getUserInfo);
    this.selectedLang=this.userInfo.languageIso;
    // this.userInfo.companyName=this.getBillingInfo.companyName;
    this.userInfo.resellerCode=this.getBillingInfo.resellerCode;
  }
}
</script>

<style src="./AccountSettings.less" lang="less" scoped></style>