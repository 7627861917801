<template>
  <b-modal id="save-config-modal" :visible="getShowSaveConfigModal" hide-footer hide-header size="lg" @hide="closeModal">
    <div class="close" @click="closeModal"></div>
    <div class="title"> {{$t('changesModal.changesDetected')}} </div> 
    <div class="desc"> {{$t('changesModal.unsavedChanges')}} </div>
    <div class="footer">
      <div class="button discard" @click="closeModal"> {{$t('changesModal.cancel')}} </div>
      <div class="button save" @click="exitWithoutSave"> {{$t('changesModal.exitWithoutSaving')}} </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Utils from '@/utils/Utils.js';
import lodash from 'lodash';

export default {
  name: 'ChangesModal',
  computed:{
    ...mapGetters(['getShowSaveConfigModal','getDisplayedWebsites','getCurrentConfiguration','getPendingRouting','getPendingWebsite'])
  },
  methods:{
    ...mapActions(['setShowSaveConfigModal','setCurrentConfiguration','setHistoryChanges','setPendingWebsite','setPendingRouting']),
    closeModal(){ 
      this.setShowSaveConfigModal(false); 
    },
    discardChanges(){
      this.setCurrentConfiguration(lodash.clone(Utils.getConfigurationFromWebsite(this.getDisplayedWebsites,this.getCurrentConfiguration)));
      this.setShowSaveConfigModal(false);
      this.setHistoryChanges([]);
    },
    exitWithoutSave(){
      this.discardChanges();
      if(this.getPendingRouting!=''){
        this.$router.push({name:this.getPendingRouting});
        this.setPendingRouting('');
      }if(this.getPendingWebsite){
        this.$root.$emit('change-website',this.getPendingWebsite);
        this.setPendingWebsite(null);
      }
    }
  }
}
</script>

<style src="./ChangesModal.less" lang="less" scoped></style>