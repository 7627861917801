<template>
  <div>
    <div class="wizard-shop" v-if="navigation=='website'">
      <div class="title"> {{$t('wizard.shop.addAPI')}} </div>
      <div class="row">
        <div class="col-6">
          <div class="desc"> {{$t('wizard.shop.ensureAPI')}} </div>
          <div class="copy-button"  @click="copyText">  </div>
          <b-form-input id="copy-text" :class="['apikey','disabled']" :value="apikey"/>
          <div class="refresh-container">
            <div :class="['refresh',{refreshing:refreshing}]" @click="refreshClick"> <div class="icon"> </div> </div>
            <b-form-select v-model="selected" :options="optionsToShow"></b-form-select>
          </div>
        </div>
        <!-- <div class="global-settings">
          <div class="sub-title"> Global Settings </div>
          <div class="sub-column2"> 
            Import out of stock products
            <toggle-button color="#398ff4" v-model="globalSettings"/>
          </div>
        </div> -->
      </div>
      <div class="desc" v-if="selected!=null" v-html="$t('wizard.shop.wait')"> </div>
      <div class="footer">
        <button class="btn back" @click="backClick"> {{$t('wizard.shop.back')}} </button>
        <button class="btn next"  @click="startSyncClick" :disabled="selected==null"> {{$t('wizard.shop.nextStep')}} </button>
      </div>
    </div>
    <div class="wizard-sync" v-else-if="navigation=='sync'">
      <div class="title"> {{$t('wizard.shop.ready')}} </div>
      <div class="website"> {{getCurrentConfiguration.url}} </div>
      <div class="desc"> {{$t('wizard.shop.decide')}} </div>
      <button class="start-sync btn" @click="startSyncClick"> {{$t('wizard.shop.startSync')}} </button>
      <button class="back-sync btn" @click="backSyncClick"> {{$t('wizard.shop.back')}} </button>
    </div>
    <div class="wizard-syncing" v-else-if="navigation=='syncing'">
      <div class="title"> {{$t('wizard.shop.syncing')}} </div>
      <div class="desc"> {{$t('wizard.shop.syncingProducts')}} </div>
      <div class="website"> {{getCurrentConfiguration.url}} </div>
      <b-spinner type="grow" label="Spinning"></b-spinner>
      <div class="disclaimer-logo"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from '@/utils/Axios';
import Utils from '@/utils/Utils';

export default { 
  name: 'WizardShop',
  NO_WEBSITES:{ value: null, text: 'No websites', disabled:true },
  data(){
    return {
      globalSettings: false,
      selected: null,
      navigation:'website',
      progressValue:0,
      status:'no',
      refreshing:false,
      time:10,
      apikey:""
    }
  },
  computed:{
    ...mapGetters(['getCurrentConfiguration','getConfiguration','getToBeConfiguredWebsites','getConfiguringWebsites']),
    optionsToShow(){
      var optionsToReturn=[this.$options.NO_WEBSITES];
      this.getToBeConfiguredWebsites.forEach(item=> optionsToReturn.push({value:item.id,text:item.url}) );
      return optionsToReturn;
    }
  },
  methods:{
    ...mapActions(['setCurrentConfigurationByField','setWebsiteAttributes','setWebsites']),
    copyText(){
      var copyText = document.getElementById("copy-text");
      copyText.select();
      copyText.setSelectionRange(0, 99999)
      try {
        document.execCommand('copy');
        copyText.blur();
        this.$bvToast.toast( this.$t('wizard.shop.copied') , {
          title: '',
          autoHideDelay: 2000,
          solid:true,
          variant:'success'
        })
      } catch (err) {
        copyText.blur();
        this.$bvToast.toast( this.$t('wizard.shop.try'), {
          title: '',
          autoHideDelay: 2000,
          solid:true,
          variant:'danger'
        })
      }
    },
    backClick(){
      this.$router.push({name:'wizard-cms'})
    },
    backSyncClick(){
      this.navigation='website';
    },
    startSyncClick(){
      this.navigation='syncing';
      this.setCurrentConfigurationByField({field:'id',value:this.selected});
      this.setCurrentConfigurationByField({field:'url',value:this.optionsToShow.filter(item=>item.value==this.selected)[0].text});
      this.checkTimeout();
    },
    checkTimeout(){
      axios.get(Utils.SHOPS+'/'+this.selected+Utils.ATTR)
      .then((response)=>{
        if(response.data.length>0) {
          this.setWebsiteAttributes(response.data);
          this.$router.push({name:'wizard-settings'});
        }
        else setTimeout(()=>{this.checkTimeout()},5000)
      })
    },
    refreshClick(){
      this.refreshing=true;
      axios.get(Utils.SHOPS)
      .then((response)=>{ this.setWebsites(response.data); })
      .finally(()=>this.refreshing=false)
    }
  },mounted(){
    this.apikey=window.localStorage.getItem('apikey');
    axios.get(Utils.SHOPS)
    .then((response)=>{ this.setWebsites(response.data); })
  }
}
</script>

<style src="./WizardShop.less" lang="less" scoped></style>