<template>
  <div class="fine-tuning">
    <FineTuningModal/>
    <div class="title"> {{$t('fineTuning.fineTuning')}} </div>
    <perfect-scrollbar>
      <div class="sub-title"> {{$t('fineTuning.weight')}} </div>
      <div class="desc"> {{$t('fineTuning.changeWeights')}} </div>
      <WeightSliders/>
      <div class="sub-desc"> {{$t('fineTuning.cantFind')}} <br> {{$t('fineTuning.goTo')}} <span @click="$router.push({name:'setup'})"> {{$t('fineTuning.setup')}} </span> {{$t('fineTuning.toAdd')}}</div>
      <div class="sub-title"> {{$t('fineTuning.filterIPs')}} </div>      
      <div class="desc"> {{$t('fineTuning.addIPs')}} </div>
      <div class="counter desc" v-html="$t('fineTuning.excludedIPs',{var1:getCurrentConfiguration.fineTuning.ips.length})"> </div>
      <div class="button" @click="()=>openModal('ips')"> {{$t('fineTuning.manageIPs')}} </div>
      <div class="sub-title"> {{$t('fineTuning.stopWords')}} </div>
      <div class="desc"> {{$t('fineTuning.addStopWords')}} </div>
      <div class="counter desc" v-html="$t('fineTuning.excludeStopWords',{var1:getCurrentConfiguration.fineTuning.stopwords.length})"> </div>
      <div class="button" @click="()=>openModal('stopwords')"> {{$t('fineTuning.manageStopWords')}} </div>
      <div class="sub-title"> {{$t('fineTuning.synonyms')}} </div>
      <div class="desc"> {{$t('fineTuning.addSynonyms')}} </div>
      <div class="counter desc" v-html="$t('fineTuning.addedSynonyms',{var1:getCurrentConfiguration.fineTuning.synonyms.length})"> </div>
      <div class="button" @click="()=>openModal('synonyms')"> +{{$t('fineTuning.addWord')}} </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import WeightSliders from '@/components/WeightSliders/WeightSliders'
import FineTuningModal from '@/components/FineTuningModal/FineTuningModal'
import { mapActions, mapGetters } from 'vuex';
import lodash from 'lodash';

export default { 
  name: 'FineTuning',
  components: { WeightSliders,FineTuningModal },
  computed:{
    ...mapGetters(['getCurrentConfiguration'])
  },
  methods:{
    ...mapActions(['setFineTuningModal']),
    openModal(key){
      var info={}
      switch(key){
        case 'ips': {
          info.title=this.$t('fineTuning.excludeIPs');
          info.desc=this.$t('fineTuning.addingIP');
          info.placeholder=this.$t('fineTuning.addIP');
          break
        }
        case 'stopwords': {
          info.title=this.$t('fineTuning.stopWords');
          info.desc=this.$t('fineTuning.stopWordDesc');
          info.placeholder=this.$t('fineTuning.addStopWord');
          break
        }
        case 'synonyms': {
          info.title=this.$t('fineTuning.synonyms');
          info.desc=this.$t('fineTuning.synonymDesc');
          info.placeholder=this.$t('fineTuning.addSynonym');
          break
        }
        default: break;
      }
      info.type=key;
      info.list=lodash.cloneDeep(this.getCurrentConfiguration.fineTuning[key]);
      this.setFineTuningModal(info);
      this.$bvModal.show('fine-tuning-modal');
    },
  }
}
</script>

<style src="./FineTuning.less" lang="less" scoped></style>