<template>
  <div :class="['recap-square','type_'+type]">
    <div class="counter-title" id="spinner-sync">
      <div class="label"> {{$t('recapSquare.currentSkus')}} <b-spinner class="busy" v-if="isSynchronizing"></b-spinner> </div>
      <b-tooltip target="spinner-sync" triggers="hover" v-if="isSynchronizing"> {{$t('recapSquare.syncing')}} </b-tooltip>
      <div class="value-tier"> {{$options.setNumberFormat(skus,0,",",".")}} </div>
      <!-- <div class="value-tier"> {{$options.setNumberFormat(getTotalCounter,0,",",".")}} </div> -->
    </div>
    <!-- <b-progress :max="getConfiguration.tier.limit" :value="getTotalCounter">
        <b-progress-bar :style="{'background-color':$options.COLORS_ARRAY[index]}" :id="'skus_progress_'+type+'_'+website.url" v-for="(website,index) in getCompletedWebsites" :key="'progress_'+website.url" :value="website.skus"></b-progress-bar>
        <b-progress-bar :style="{'background-color':$options.COLORS_ARRAY[index]}" :id="'skus_progress_'+type+'_'+website.url" v-for="(website,index) in getActiveWebsites" :key="'progress_'+website.url" :value="website.skus"></b-progress-bar>
        <b-tooltip v-for="website in getCompletedWebsites" :key="'skus_progress_tooltip_'+website.url" :target="'skus_progress_'+type+'_'+website.url" triggers="hover">
          {{website.url}}<br>{{website.skus}} SKUs
        </b-tooltip>
    </b-progress> -->
    <!-- <div class="counter-values">
      <div class="label"> {{getTotalCounter}} </div>
      <div class="value-tier"> of {{getConfiguration.tier.limitLabel}} SKUs </div>
    </div> -->
    <div class="divider"/>
    <div class="monthly">
      <div class="label"> {{$t('recapSquare.monthlyQueries')}} </div>
      <b-progress :max="threshold" :value="getTotalMonthly" v-if="threshold">
      <!-- <b-progress :max="threshold" :value="getTotalMonthly" :variant="getTotalMonthly>threshold?'danger':''"> -->
      </b-progress>
      <div class="counter-values">
        <div class="label"> {{$options.setNumberFormat(getTotalMonthly,0,",",".")}} </div>
        <div class="value-tier" v-if="actualPlan!=='trial' && threshold"> {{$t('recapSquare.ofQueries',{var1:$options.setNumberFormat(threshold,0,",",".")})}} </div>
        <div class="value-tier" v-else-if="threshold"> 30-Day Trial </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from '@/utils/Utils';
import { mapActions, mapGetters } from 'vuex';
import axios from '@/utils/Axios'

export default {
  name: 'RecapSquare',
  beautifyWebsite:Utils.beautifyWebsite,
  setNumberFormat:Utils.setNumberFormat,
  COLORS_ARRAY:Utils.COLORS_ARRAY,
  props:['type'],
  data(){
    return {
      isSynchronizing:false,
      skus:0,
      checkingSkus:false,
    }
  },
  methods:{
    ...mapActions(['setConfiguration']),
    async checkSync(){
      await axios.get(Utils.SHOPS+Utils.STATUS)
      .then(response=>{
        if(response.data.some(shop=>shop.isSynchronizing)){
          this.isSynchronizing=true;
          if(!this.checkingSkus) this.getSkus();
          setTimeout(this.checkSync,5000);
        }else{
          this.getSkus();
          this.isSynchronizing=false;
        }
      })
    },
    async getSkus(){
      this.checkingSkus=true
      await axios.get(Utils.CUSTOMERS+Utils.ME+Utils.COUNT).then(
        response=>{
          this.skus=0
          this.skus=Object.values(response.data).reduce((acc,current)=>acc+parseInt(current),this.skus);
          if(this.isSynchronizing)
            setTimeout(this.getSkus,300000);
            // setTimeout(this.getSkus,10000);
          else this.checkingSkus=false
        })
    }
  },
  computed:{
    ...mapGetters(['getTotalCounter','getTotalMonthly','getConfiguration','getCompletedWebsites','getActiveWebsites','getSelectedPlan','getCurrentConfiguration']),
    selectedPlanKey(){ return Object.keys(this.getSelectedPlan)[0] },
    selectedPlanValue(){ return Object.values(this.getSelectedPlan)[0]?.title },
    actualPlan(){
      return this.selectedPlanKey=='trial' || new Date().getTime()<this.selectedPlanValue?.subscriptionTimestamp*1000?'trial':this.selectedPlanKey
    },
    threshold(){ return this.actualPlan=='trial'?1000:(this.selectedPlanValue?.requestsLimit==0?this.selectedPlanValue?.queries.threshold:this.selectedPlanValue?.requestsLimit) }
  },
  mounted(){
    this.$root.$on('check-sync',this.checkSync);
    axios.get(Utils.CUSTOMERS+Utils.ME+Utils.TIER)
    .then(response=>{
      this.setConfiguration({tier:{...response.data}})
    })
    this.checkSync();
  }
}
</script>

<style src="./RecapSquare.less" lang="less" scoped></style>