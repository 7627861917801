<template>
  <div class="dashboard">
    <div class="title"> {{ $t("dashboard.dashboard") }} </div>
    <perfect-scrollbar @ps-y-reach-end="onReachEnd">
      <div class="info-row">
        <div class="item-container search">
          <div class="icon"></div>
          <div class="data">
            <div class="value"> {{info.totalSearches}} </div>
            <div class="label">  {{ $t("dashboard.totalSearches") }} </div>
          </div>
        </div>
        <div class="item-container click">
          <div class="icon"></div>
          <div class="data">
            <div class="value"> {{info.totalProductClicks}} </div>
            <div class="label"> {{ $t("dashboard.totalProductClicks") }} </div>
          </div>
        </div>
        <div class="item-container timeframe">
          <div class="label"> {{ $t("dashboard.timeframe") }} </div>
          <Datepicker v-model="date" opens="left" :ranges="ranges" :locale-data="locale" @update="onDateUpdate"/>
        </div>
      </div>
      <div class="list-row" v-if="!detail">
        <div class="list-container trending">
          <div class="title"> {{ $t("dashboard.trendingSearches") }} </div>
          <div class="sub-title"> {{ $t("dashboard.mostSearchedQueries") }} </div>
          <div class="item-list" v-for="(query,index) in tenArray(info.mostSearchedQueries)" :key="query.query+'_'+index">
            <div class="num"> {{index+1}} </div>
            <div class="value"> {{query.query?query.query:"N/A"}} </div>
            <div class="count"> {{query.count}} </div>
            <div class="icon"></div>
          </div>
          <div class="show-all"> 
            <span  v-if="info.mostSearchedQueries.length>=10" @click="()=>showAll(info.mostSearchedQueries,$options.MSQ)"> {{ $t("dashboard.showAll") }} </span>
          </div>
        </div>
        <div class="list-container click">
          <div class="title"> {{ $t("dashboard.mostClickedProducts") }} </div>
          <div class="sub-title"> {{ $t("dashboard.trendingItems") }} </div>
          <div class="item-list" v-for="(query,index) in tenArray(info.mostClickedProducts)" :key="query.title+'_'+index">
            <div class="num"> {{index+1}} </div>
            <div class="value"> {{query.title?query.title:"N/A"}} </div>
            <div class="count"> {{query.count}} </div>
            <div class="icon"></div>
          </div>
          <div class="show-all"> 
            <span v-if="info.mostClickedProducts.length>=10" @click="()=>showAll(info.mostClickedProducts,$options.MCP)"> {{ $t("dashboard.showAll") }} </span>
          </div>
        </div>
        <div class="list-container zero">
          <div class="title"> {{ $t("dashboard.searchesNoResults") }} </div>
          <div class="sub-title"> {{ $t("dashboard.MostSearchesNoResults") }}</div>
          <div class="item-list" v-for="(query,index) in tenArray(info.noResultsQueries)" :key="query.query+'_'+index">
            <div class="num"> {{index+1}} </div>
            <div class="value"> {{query.query?query.query:"N/A"}} </div>
            <div class="count"> {{query.count}} </div>
            <div class="icon"></div>
          </div>
          <div class="show-all"> 
            <span v-if="info.noResultsQueries.length>=10" @click="()=>showAll(info.noResultsQueries,$options.NRQ)"> {{ $t("dashboard.showAll") }}  </span>
          </div>
        </div>
      </div>
      <div class="detail" v-else>
        <div class="back" @click="backClick">
          <div class="icon"></div>
          <div class="label"> {{ $t("dashboard.goBack") }} </div>
        </div>
        <div :class="['list-container',detailInfo.class]">
          <div class="title"> {{detailInfo.title}} </div>
          <div class="sub-title"> {{detailInfo.subTitle}} </div>
          <div class="item-list" v-for="(query,index) in detailArray" :key="detailType==$options.MCP?query.title+'_'+index:query.query+'_'+index">
            <div class="num"> {{index+1}} </div>
            <div class="value" v-if="detailType==$options.MCP"> {{query.title?query.title:"N/A"}} </div>
            <div class="value" v-else> {{query.query?query.query:"N/A"}} </div>
            <div class="count"> {{query.count}} </div>
            <div class="icon"></div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import Utils from '@/utils/Utils'
import axios from '@/utils/Axios'
import lodash from 'lodash'
import { mapGetters } from 'vuex'

export default { 
  name: 'Dashboard',
  NRQ:Utils.NRQ,
  MCP:Utils.MCP,
  MSQ:Utils.MSQ,
  STARTING_DATE:{
    startDate:new Date(),
    endDate:new Date()
  },
  data(){
    return {
      detail:false,
      detailType:"",
      page:0,
      isLoading:false,
      stopPaging:false,
      detailArray:[],
      locale:{
        applyLabel: this.$t('dashboard.setTimeRange'),
        cancelLabel: this.$t('dashboard.cancel'),
      },
      info:{
        totalSearches:0,
        totalProductClicks:0,
        noResultsQueries:[],
        mostSearchedQueries:[],
        mostClickedProducts:[]
      },
      modelDate:this.$options.STARTING_DATE,
      interval:null
    }
  },
  computed:{
    ...mapGetters(['getCurrentConfiguration']),
    ranges(){
      var rangesToReturn={};
      var today = new Date(new Date().setHours(0,0,0,0));
      var midnight = new Date();
      midnight.setDate(today.getDate()-1);
      midnight.setHours(23,59,59,0);
      var yesterday= new Date();
      yesterday.setDate(today.getDate()-1);
      yesterday.setHours(0,0,0,0);
      // rangesToReturn['Today']=[today,midnight];
      rangesToReturn[this.$t('dashboard.yesterday')]=[yesterday,midnight];
      var lastWeek = new Date();
      lastWeek.setDate(today.getDate()-7);
      lastWeek.setHours(0,0,0,0);
      rangesToReturn[this.$t('dashboard.pastWeek')]=[lastWeek,midnight];
      var lastMonth = new Date();
      lastMonth.setMonth(today.getMonth()-1);
      lastMonth.setHours(0,0,0,0);
      rangesToReturn[this.$t('dashboard.pastMonth')]=[lastMonth,midnight];
      var last3Month = new Date();
      last3Month.setMonth(today.getMonth()-3);
      last3Month.setHours(0,0,0,0);
      rangesToReturn[this.$t('dashboard.past3Month')]=[last3Month,midnight];
      var last6Month = new Date();
      last6Month.setMonth(today.getMonth()-6);
      last6Month.setHours(0,0,0,0);
      rangesToReturn[this.$t('dashboard.past6Month')]=[last6Month,midnight];
      var lastYear = new Date();
      lastYear.setFullYear(lastYear.getFullYear()-1);
      lastYear.setHours(0,0,0,0);
      rangesToReturn[this.$t('dashboard.pastYear')]=[lastYear,midnight];
      return rangesToReturn;
    },
    detailInfo(){
      if(this.detailType==Utils.NRQ)
        return {
          class:"zero",
          title:this.$t('dashboard.searchesNoResults'),
          subTitle:this.$t('dashboard.MostSearchesNoResults')
        }
      if(this.detailType==Utils.MCP)
        return {
          class:"click",
          title:this.$t('dashboard.mostClickedProducts'),
          subTitle:this.$t('dashboard.trendingItems')
        }
      return {
        class:"trending",
        title:this.$t('dashboard.trendingSearches'),
        subTitle:this.$t('dashboard.mostSearchedQueries')
      }
    },
    date:{
      set(value){this.modelDate=value;},
      get(){
        if(lodash.isEqual(this.modelDate,this.$options.STARTING_DATE)){
          var yesterday= new Date();
          yesterday.setDate(new Date().getDate()-1);
          yesterday.setHours(0,0,0,0);
          var midnight = new Date();
          midnight.setDate(yesterday.getDate());
          midnight.setHours(23,59,59,0);
          return {
            startDate:yesterday,
            endDate:midnight
          }
        } else return this.modelDate
      }
    }
  },
  methods:{
    getInfo(){
      axios.get(Utils.SHOPS+'/'+this.getCurrentConfiguration.id+Utils.EVENTS+Utils.OVERVIEW+'?'+Utils.getFormattedTimestampFromDate(this.date))
      .then(response=>this.info=response.data);
    },
    tenArray(array){
      var arrayToReturn=[]
      for (var i=1;i<=10;i++) {
        arrayToReturn.push({query: "", count: 0});
      }
      array.filter((item,index)=>index<10).forEach((item,index)=>{
        arrayToReturn[index]=item
      })
      return arrayToReturn;
    },
    showAll(array,detailType){
      this.detail=true;
      this.detailType=detailType;
      this.detailArray=lodash.cloneDeep(array);
      axios.get(Utils.SHOPS+'/'+this.getCurrentConfiguration.id+Utils.EVENTS+detailType+'?'+Utils.getFormattedTimestampFromDate(this.date))
      .then(response=>this.detailArray=lodash.cloneDeep(response.data))
    },
    onReachEnd(){
      if(!this.detail || this.isLoading || this.stopPaging) return null
      this.isLoading=true;
      this.page++;
      axios.get(Utils.SHOPS+'/'+this.getCurrentConfiguration.id+Utils.EVENTS+this.detailType+'?'+Utils.getFormattedTimestampFromDate(this.date)+'&'+Utils.PAGE+'='+this.page)
      .then(response=>{
        this.detailArray=[...this.detailArray,...response.data];
        if(response.data.length==0) this.stopPaging=true;
        this.isLoading=false;
      })
    },
    backClick(){
      this.detail=false;
      this.page=0;
      this.stopPaging=false
    },
    onChangedWebsite(){
      this.backClick();
      this.getInfo();
    },
    onDateUpdate(){
      if(this.detail) {
        this.page=0;
        this.stopPaging=false
        axios.get(Utils.SHOPS+'/'+this.getCurrentConfiguration.id+Utils.EVENTS+this.detailType+'?'+Utils.getFormattedTimestampFromDate(this.date))
        .then(response=>this.detailArray=lodash.cloneDeep(response.data))
      } else this.getInfo();
    }
  },
  mounted(){
    this.getInfo();
    this.$root.$on('changed-website',this.onChangedWebsite);
  },
}
</script>

<style src="./Dashboard.less" lang="less" scoped></style>