<template>
  <perfect-scrollbar
    class="advanced-settings"
    ref="advanced-settings"
    @ps-scroll-y="onScroll"
  >
    <div class="config-container">
      <div class="config-row">
        <ConfigItem
          :title="$t('setup.languageTitle')"
          :desc="$t('setup.languageDesc')"
          type="lang"
          :modalTitle="$t('setup.languageTitle')"
          setupKey="lang"
          :isConfig="false"
        />
        <ConfigItem
          :title="$t('setup.boostingTitle')"
          :desc="$t('setup.boostingDesc')"
          type="single-list"
          :modalTitle="$t('setup.boostingTitle')"
          setupKey="boosting"
          :isConfig="false"
        />
      </div>
      <!-- <div class="config-row">
        <ConfigItem
          :title="$t('setup.categoryTitle')"
          :desc="$t('setup.categoryDesc')"
          type="input"
          :modalTitle="$t('setup.categoryTitle')"
          setupKey="separator"
          :isConfig="false"
        />
        <ConfigItem
          :title="$t('setup.stockTitle')"
          :desc="$t('setup.stockDesc')"
          type="boolean"
          :modalTitle="$t('setup.stockTitle')"
          setupKey="oosProducts"
          :isConfig="false"
        />
      </div> -->
      <div class="config-row">
        <ConfigItem
          :title="$t('setup.groupPriceTitle')"
          :desc="$t('setup.groupPriceDesc')"
          type="groupPrice"
          :modalTitle="$t('setup.groupPriceTitle')"
          setupKey="groupPrice"
          :isConfig="true"
        />
        <ConfigItem
          :title="$t('setup.priceTitle')"
          :desc="$t('setup.priceDesc')"
          type="webhook"
          :modalTitle="$t('setup.priceTitle')"
          setupKey="webhook"
          :isConfig="true"
        />
      </div>
      <div class="config-row">
        <ConfigItem
          :title="$t('setup.seoSettingsTitle')"
          :desc="$t('setup.seoSettingsDesc')"
          type="seo-settings"
          :modalTitle="$t('setup.seoSettingsTitle')"
          setupKey="seoSettings"
          :isConfig="true"
        />
        <div class="img-column sorting" ref="seoSettings-row">
          <div :class="['img', scrollCheck($refs['seoSettings-row'])]"></div>
        </div>
      </div>
      <div class="config-row">
        <ConfigItem
          :title="$t('setup.ga4Title')"
          :desc="$t('setup.ga4Desc')"
          type="checkbox"
          :modalTitle="$t('setup.ga4Title')"
          setupKey="ga4"
          :isConfig="true"
        />
      </div>
    </div>
    <div class="footer">
      <b-button class="btn delete" @click="deleteShop">
        {{ $t("setup.deleteShop") }}
      </b-button>
    </div>
  </perfect-scrollbar>
</template>

<script>
import ConfigItem from "@/components/ConfigItem/ConfigItem.vue";
import Utils from "@/utils/Utils";
import axios from "@/utils/Axios";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AdvancedSettings",
  components: { ConfigItem },
  computed: {
    ...mapGetters(["getCurrentConfiguration", "getBillingInfo"]),
  },
  data() {
    return { scrollTop: 0 };
  },
  methods: {
    ...mapActions([
      "setOpenedWebsitesList",
      "setDeleteShopModalVisible",
      "setWebsiteAttributes",
    ]),
    deleteShop() {
      this.setDeleteShopModalVisible(true);
    },
    copyApi() {
      var copyText = document.getElementById("api-key");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      this.$bvToast.toast(this.$t("setup.copied"), {
        title: "",
        autoHideDelay: 2000,
        solid: true,
        variant: "success",
      });
    },
    onScroll() {
      this.scrollTop = this.$refs["advanced-settings"]?.$el.scrollTop;
    },
    scrollCheck(img) {
      if (this.scrollTop < img?.offsetTop - 10 || !img?.offsetTop) return "";
      if (
        this.scrollTop >
        img?.offsetTop +
          img?.offsetHeight -
          img?.childNodes[0].offsetHeight -
          10
      )
        return "scrolled";
      return "fixed";
    },
    getAttributes() {
      axios
        .get(Utils.SHOPS + "/" + this.getCurrentConfiguration.id + Utils.ATTR)
        .then((response) => {
          this.setWebsiteAttributes(response.data);
        });
    },
  },
  mounted() {
    this.getAttributes();
    this.$root.$on("not-busy", this.getAttributes);
  },
};
</script>

<style src="./AdvancedSettings.less" lang="less" scoped></style>
