import axios from 'axios';
import Utils from '@/utils/Utils';

var axiosInstance=axios.create({
    headers:{'Content-Type':'application/json'},
    baseURL:window.location.origin
})

axiosInstance.interceptors.request.use(
    request => {
        if(localStorage.getItem("apikey")) 
            request.headers.common["X-Accelasearch-Apikey"]=localStorage.getItem("apikey");
        if (localStorage.getItem("token"))
            request.headers.common["X-Accelasearch-Jwt"] = localStorage.getItem("token");
        return request;
    }
);

axiosInstance.interceptors.response.use(
    response => {
        if(Utils.ERROR===response.data.status && Utils.EXPIRED_TOKEN===response.data.message) {
            localStorage.removeItem("token");
            localStorage.removeItem("apikey");
        }
        return response;
    }
);

export default axiosInstance;