<template>
  <div class="activation-mail">
      <div class="label"> {{$t('home.activationMail.checkYourEmail')}} </div>
      <div class="register" > {{$t('home.activationMail.sendEmail')}} </div>
  </div>
</template>

<script>

export default { 
  name: 'ActivationMail' 
}
</script>

<style src="./ActivationMail.less" lang="less" scoped></style>