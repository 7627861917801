<template>
  <div class="visual-machine">
    <div class="overheader"> 
      <div class="title"> {{$t('visualMachine.visualMachine')}} </div>
    </div>
    <Header />
    <router-view/>
  </div>
</template>

<script>
import Header from '@/components/Header/Header'

export default { 
  name: 'VisualMachine',
  components:{ Header }
}
</script>

<style src="./VisualMachine.less" lang="less" scoped></style>