<template>
  <perfect-scrollbar class="billing-overview">
    <div class="queries-container">
      <div class="labels">
        <div class="title"> {{$t('billing.overview.totalSearchQueries')}} </div>
        <div class="label"> {{$t('billing.overview.basedAllShops')}} </div>
      </div>
      <b-progress :max="threshold" :value="getTotalMonthly" v-if="threshold"></b-progress>
      <!-- <b-progress :max="threshold" :value="getTotalMonthly" :variant="getTotalMonthly>threshold?'danger':''"></b-progress> -->
      <div class="values">
        <div class="value"> {{$options.setNumberFormat(getTotalMonthly,0,",",".")}} </div>
        <div class="tier" v-if="actualPlan!=='trial' && threshold"> {{$options.setNumberFormat(threshold,0,",",".")}} </div>
        <div class="tier" v-else-if="threshold"> 
          <div class="trial">
            <div class="icon"></div>
            <div class="label"> 30-Day Trial </div>
          </div> 
        </div>
      </div>
    </div>
    <div class="websites-detail">
      <div class="row">
        <div class="col"> {{$t('billing.overview.currentShops')}} </div>
        <div class="col"> {{$t('billing.overview.queries')}} </div>
        <div class="col"> {{$t('billing.overview.skus')}} </div>
      </div>
      <div class="row" v-for="website in getCompletedWebsites" :key="'website_detail_'+website.url">
        <div class="col"> {{$options.beautifyWebsite(website.url)}} </div>
        <div class="col"> {{$options.setNumberFormat(website.requests,0,",",".")}} </div>
        <div class="col"> {{$options.setNumberFormat(website.skus,0,",",".")}} </div>
      </div>
    </div>
    <div :class="['warning-container',boxClass]" v-if="boxClass!='none'">
      <div class="info-box" v-if="boxClass=='info'">
        <div class="icon"></div>
        <div class="label"> {{$t('billing.overview.updateBillingInfo')}} </div>
      </div>
      <!-- <div class="info-box" v-if="boxClass=='warn'">
        <div class="icon"></div>
        <div class="label"> {{$t('billing.overview.reaching')}} </div>
      </div> -->
      <div class="info-box" v-else-if="boxClass=='err'">
        <div class="icon"></div>
        <div class="label"> {{selectedPlanValue.isRegular?$t('billing.overview.updateBillingInfoDanger'):$t('billing.overview.updatePaymentInfoDanger')}} </div>
      </div>
      <!-- <div class="info-box" v-else-if="boxClass=='exceed'">
        <div class="icon"></div>
        <div class="label"> {{$t('billing.overview.reached')}} </div>
      </div> -->
    </div>
    <div class="consumption">
      <div class="header">
        <div class="past-year" @click="()=>{selectedYear--;getInfoByYear();}"> 	&#60;  {{ selectedYear-1 }} </div>
        <div class="title"> {{$t('billing.overview.consumptionSummary')}} {{selectedYear}} </div>
        <div class="invoices">
          {{$t('billing.overview.invoices')}}
          <toggle-button class="toggle-button" color="#398ff4" :sync="true" :width="50" :value="invoicesToggle" @change="toggleInvoices"/>
        </div>
        <div class="consumptions">
          {{$t('billing.overview.consumptions')}}
          <toggle-button class="toggle-button" color="#398ff4" :sync="true" :width="50" :value="consumptionsToggle" @change="toggleConsumptions"/>
        </div>
        <div class="next-year"  @click="()=>{selectedYear++;getInfoByYear();}" v-if="this.selectedYear!==new Date().getFullYear()"> {{ selectedYear+1 }} &#62; </div>
      </div>
      <b-table class="table" :items="items" :fields="fields" :busy="tableIsLoading">
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(detail)="data">
          <div class="detail">
            <div class="collapse-header" @click="data.toggleDetails">
              {{data.detailsShowing?$t('billing.overview.hideDetails'):$t('billing.overview.showDetails')}}
            </div>
          </div>
        </template>
        <template #cell(typeLabel)="data">
          <div :class="['type',data.item.type]">
            {{$t('billing.overview.'+data.item.typeLabel)}}
          </div>
        </template>
        <template #cell(cost)="data">
          <div v-if="data.item.cost"> {{$options.setNumberFormat(data.item.cost,2,",",".")}} € </div>
        </template>
        <template #row-details="data">
          <div class="detail-container" v-if="data.item.type=='invoice'">
            <div class="header">
              <div class="right"> {{$t('billing.overview.downloadInvoice')}} </div>
            </div>
            <div class="info">
              <div class="left"> {{$t('billing.overview.'+data.item.typeLabel)}} </div>
              <div class="right" @click="()=>pdfClick(data.item.detail.dowloadUrl)"> {{$t('billing.overview.pdf')}} </div>
            </div>
          </div>
          <div class="detail-container" v-else>
            <b-table :fields="subTableFields" :items="data.item.detail">
              <template #cell(service)="subdata">
                {{$t('billing.overview.'+subdata.item.service,{var1:subdata.item.requests})}}
              </template>
              <template #cell(shopUrl)="subdata">
                {{$options.beautifyWebsite(subdata.item.shopUrl)}}
              </template>
            </b-table>
          </div>
        </template>
      </b-table>
    </div>
  </perfect-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex';
import Utils from '@/utils/Utils';
import axios from '@/utils/Axios';
import moment from 'moment';
import lodash from 'lodash';

export default { 
  name: 'BillingOverview',
  beautifyWebsite:Utils.beautifyWebsite,
  setNumberFormat:Utils.setNumberFormat,
  data(){
    return {
      invoicesToggle:true,
      consumptionsToggle:true,
      cards:[],
      tableIsLoading:false,
      fetchingInfos:false,
      consumption:[],
      fields: [
        { key: 'date', label: this.$t('billing.overview.date')},
        { key: 'detail', label: this.$t('billing.overview.serviceDetail') },
        { key: 'typeLabel', label: this.$t('billing.overview.type') },
        { key: 'cost', label: this.$t('billing.overview.totalCost') }
      ],
      collapseList:[],
      subTableFields:[
        { key: 'shopUrl', label: this.$t('billing.overview.shop')},
        { key: 'service', label: this.$t('billing.overview.service') }
      ],
      selectedYear:0
    }
  },
  computed:{
    ...mapGetters(['getTotalCounter','getTotalMonthly','getConfiguration','getCompletedWebsites','getSelectedPlan','getBillingInfo']),
    checkInfo(){
      return Utils.checkBillingInfo(this.getBillingInfo) || (this.selectedPlanValue.isAutomatic && this.cards.length==0) || !this.selectedPlanValue.isRegular
    },
    boxClass(){
      if(this.fetchingInfos || !this.checkInfo) return "none"
      if(this.actualPlan=='trial') return "info"
      else return "err"
      // if(this.getTotalMonthly<(this.getConfiguration.tier.limitMonth*0.75)) return "none"
      // if(this.getTotalMonthly<(this.getConfiguration.tier.limitMonth*0.9)) return "warn"
    },
    items(){
      var momentFormat="L";
      var arrayToReturn= this.consumption.map(item=>{
        var detailItem=item.type=='invoice'?{
          transactionId:item.transactionId,
          dowloadUrl:item.url,
          detailLabel:item.label
        }:item.requests;
        return {
          date:moment(item.timestamp*1000).format(momentFormat),
          type:item.type,
          typeLabel:item.label,
          cost:item.amount?item.amount:"",
          detail:detailItem
        }
      });
      arrayToReturn=arrayToReturn.filter(item=>{
        if(!this.invoicesToggle && item.type=="invoice") return false
        if(!this.consumptionsToggle && item.type=="consumption") return false
        return true
      })
      return arrayToReturn;
    },
    selectedPlanValue(){ return Object.values(this.getSelectedPlan)[0] },
    selectedPlanKey(){ return Object.keys(this.getSelectedPlan)[0] },
    actualPlan(){
      return this.selectedPlanKey=='trial' || new Date().getTime()<this.selectedPlanValue.subscriptionTimestamp*1000?'trial':this.selectedPlanKey
    },
    threshold(){ return this.actualPlan=='trial'?1000:(this.selectedPlanValue.requestsLimit==0?this.selectedPlanValue.queries.threshold:this.selectedPlanValue.requestsLimit) }
  },
  methods:{
    setCollapseItem(value,index){
      var objectToReturn=lodash.cloneDeep(this.collapseList);
      objectToReturn[index]=value;
      this.collapseList=objectToReturn;
    },
    toggleInvoices(){
      this.collapseList=this.collapseList.map(()=>false);
      this.invoicesToggle=!this.invoicesToggle;
      if(!this.invoicesToggle) this.consumptionsToggle=true;
    },
    toggleConsumptions(){
      this.collapseList=this.collapseList.map(()=>false);
      this.consumptionsToggle=!this.consumptionsToggle;
      if(!this.consumptionsToggle) this.invoicesToggle=true;
    },
    async getInfoByYear(){
      this.tableIsLoading=true;
      await axios.get(Utils.CUSTOMERS+Utils.ME+Utils.PAYMENT+Utils.HISTORY+"?"+Utils.FROM+"="+new Date(this.selectedYear, 0, 1).getTime()/1000+"&"+Utils.TO+"="+new Date(this.selectedYear+1, 0, 1).getTime()/1000)
      .then(response=> this.consumption=response.data )
      this.tableIsLoading=false;
    },
    async pdfClick(url){
      await axios.get(url)
      .then(response=>{
        const linkSource = `data:application/pdf;base64,${response.data}`;
        const downloadLink = document.createElement("a");
        const fileName = "invoice.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
    }
  },
  async mounted(){
    this.fetchingInfos=true;
    this.collapseList=[];
    this.items.forEach(()=>this.collapseList.push(false));
    this.selectedYear=new Date().getFullYear();
    await axios.get(Utils.CUSTOMERS+Utils.ME+Utils.PAYMENT+Utils.METHODS)
    .then(response=>this.cards=response.data);
    await this.getInfoByYear();
    this.fetchingInfos=false;
  }
}
</script>

<style src="./BillingOverview.less" lang="less" scoped></style>