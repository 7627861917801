<template>
  <b-modal id="config-modal" hide-footer hide-header size="lg">
    <div class="close" @click="() => $bvModal.hide('config-modal')"></div>
    <div class="title" v-if="getConfigurationModal.setupKey == 'seoSettings'">
      {{ $t("configItem.seoOrdering") }}
    </div>
    <div class="title" v-else>{{ getConfigurationModal.title }}</div>
    <div class="desc" v-if="getConfigurationModal.setupKey == 'seoSettings'">
      {{ $t("configItem.seoOmptimizedDesc") }}
    </div>
    <div class="desc" v-else>{{ getConfigurationModal.desc }}</div>
    <div class="desc" v-if="getConfigurationModal.setupKey == 'groupPrice'">
      {{ $t("configModal.groupPriceDesc") }}
    </div>
    <div
      class="selector"
      v-if="getConfigurationModal.setupKey == 'dynamicFacets'"
    >
      <div
        :class="['selector-item', { selected: selector == 'all' }]"
        @click="() => (selector = 'all')"
      >
        Show All
      </div>
      <div
        :class="['selector-item', { selected: selector == 'selected' }]"
        @click="() => (selector = 'selected')"
      >
        Show Selected
      </div>
      <div
        :class="['selector-item', { selected: selector == 'not-selected' }]"
        @click="() => (selector = 'not-selected')"
      >
        Show Unselected
      </div>
    </div>
    <b-input
      :placeholder="
        $t('configModal.searchAmong', { var1: filteredList.length })
      "
      v-if="!isInfo"
      :value="getConfigurationModal.filter"
      @input="setFilter"
      class="input"
    >
    </b-input>
    <perfect-scrollbar class="list info" v-if="isInfo">
      <div
        class="list-item"
        v-for="item in getConfigurationModal.list"
        :key="item.title"
      >
        <div class="icon"></div>
        <div class="info-container">
          <div class="title">{{ item.title }}</div>
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
    </perfect-scrollbar>
    <perfect-scrollbar
      class="list"
      v-else-if="
        getConfigurationModal.setupKey == 'boosting' ||
          getConfigurationModal.setupKey == 'productUrl' ||
          getConfigurationModal.setupKey == 'webhook' ||
          getConfigurationModal.setupKey == 'productImage'
      "
    >
      <div class="list-item" v-for="item in filteredList" :key="item.title">
        <div class="info-container" @click="() => setRadioList(item)">
          <b-checkbox :checked="checked[item]"> {{ item }} </b-checkbox>
        </div>
      </div>
    </perfect-scrollbar>
    <perfect-scrollbar
      class="list"
      v-else-if="getConfigurationModal.setupKey == 'query'"
    >
      <div
        class="list-item"
        v-for="(item, index) in filteredList"
        :key="item.title + '_' + index"
      >
        <div class="info-container" @click="() => setList(item)">
          <b-checkbox :checked="checked[item]"> {{ item }} </b-checkbox>
        </div>
        <div class="weight" v-if="checked[item]">
          <div
            class="select"
            v-for="(searchType, subindex) in searchTypes"
            :key="searchType.key + '_' + subindex"
          >
            <b-select
              :options="searchType.values"
              @change="(value) => dataLevelSet(item, searchType.key, value)"
              :value="getConfigurationModal.selectedList[item][searchType.key]"
            >
            </b-select>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <perfect-scrollbar
      class="list"
      v-else-if="getConfigurationModal.setupKey == 'results'"
    >
      <div
        class="list-item"
        v-for="(item, index) in filteredList"
        :key="item.title + '_' + index"
      >
        <div class="info-container" @click="() => setList(item)">
          <b-checkbox :checked="checked[item]"> {{ item }} </b-checkbox>
        </div>
        <div class="type" v-if="checked[item]">
          <b-select
            v-model="selectedValue(item).value"
            :options="options"
          ></b-select>
        </div>
      </div>
    </perfect-scrollbar>
    <perfect-scrollbar
      class="list"
      v-else-if="getConfigurationModal.setupKey == 'seoSettings'"
    >
      <draggable v-model="activeFacets" :move="onMove">
        <div
          :class="[
            'list-item',
            'drag-sortable',
            { disabled: disabledList[item] },
          ]"
          v-for="(item, index) in activeFacets"
          :key="index"
        >
          <div class="info-container" @click="() => setList(item)">
            <div class="icon-sort"></div>
            <b-checkbox :checked="checked[item]"> {{ item }} </b-checkbox>
          </div>
        </div>
      </draggable>
    </perfect-scrollbar>
    <perfect-scrollbar class="list" v-else>
      <div
        :class="['list-item', { disabled: disabledList[item] }]"
        v-for="item in filteredList"
        :key="item.title"
      >
        <div class="info-container" @click="() => setList(item)">
          <b-checkbox :checked="checked[item]"> {{ item }} </b-checkbox>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="footer">
      <div class="btn-container">
        <div class="info" v-if="getConfigurationModal.setupKey == 'query'">
          <div class="title">{{ $t("configModal.dataLevel") }}</div>
          <div class="desc">{{ $t("configModal.beforeConfirming") }}</div>
        </div>
        <div
          class="info"
          v-else-if="getConfigurationModal.setupKey == 'results'"
        >
          <div class="desc">
            {{ $t("configModal.assigningType") }} <br />
            {{ $t("configModal.allSelected") }}
            <!--<a class='title'> {{$t('configModal.rawData')}} </a>-->
          </div>
        </div>
        <button class="btn" @click="clickBtn" :disabled="disabled">
          {{ isInfo ? $t("configModal.ok") : $t("configModal.confirm") }}
        </button>
      </div>
      <div class="sub-info" v-if="getConfigurationModal.setupKey == 'query'">
        <div
          v-for="(searchType, index) in searchTypes"
          :key="'info_' + searchType.key + '_' + index"
        >
          <div class="desc">{{ searchType.label }}</div>
          <div
            class="legenda-list"
            v-for="(value, subindex) in searchType.values"
            :key="'info_' + value.value + '_' + subindex"
          >
            <div class="legenda-title">{{ value.text }}</div>
            <div class="legenda-desc">{{ value.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "@/utils/Axios";
import Utils from "@/utils/Utils";
import lodash from "lodash";

export default {
  name: "ConfigModal",
  data() {
    return {
      weights: {},
      selected: {},
      searchTypes: [],
      selector: "all",
      options: [{ value: null, text: "Assign type", disabled: true }],
      itemsToReturn: [],
    };
  },
  computed: {
    ...mapGetters([
      "getConfigurationModal",
      "getCurrentConfiguration",
      "getWebsiteAttributes",
      "getHistoryChanges",
    ]),
    isInfo() {
      return this.getConfigurationModal.type.startsWith("info-");
    },
    checked() {
      var itemToReturn = {};
      if (
        this.getConfigurationModal.setupKey == "query" ||
        this.getConfigurationModal.setupKey == "results"
      )
        this.getConfigurationModal.list.forEach(
          (item) =>
            (itemToReturn[item] = Object.keys(
              this.getConfigurationModal.selectedList
            )
              .map((obj) => this.getConfigurationModal.selectedList[obj].label)
              .includes(item))
        );
      else if (
        this.getConfigurationModal.setupKey == "webhook" ||
        this.getConfigurationModal.setupKey == "boosting"
      ) {
        this.getConfigurationModal.list.forEach(
          (item) =>
            (itemToReturn[item] = this.getConfigurationModal.selectedList.key
              ? this.getConfigurationModal.selectedList.key.includes(item)
              : false)
        );
      } else if (this.getConfigurationModal.setupKey == "productImage") {
        if (this.getConfigurationModal.type.endsWith("-hover"))
          this.getConfigurationModal.list.forEach(
            (item) =>
              (itemToReturn[item] = Object.keys(
                this.getConfigurationModal.selectedList.hover
                  ? this.getConfigurationModal.selectedList.hover
                  : {}
              ).includes(item))
          );
        else
          this.getConfigurationModal.list.forEach(
            (item) =>
              (itemToReturn[item] = Object.keys(
                this.getConfigurationModal.selectedList.main
                  ? this.getConfigurationModal.selectedList.main
                  : {}
              ).includes(item))
          );
      } else if (this.getConfigurationModal.setupKey == "dynamicFacets") {
        this.getConfigurationModal.list.forEach(
          (item) =>
            (itemToReturn[item] =
              !Object.keys(this.getConfigurationModal.selectedList).includes(
                item
              ) &&
              !Object.keys(this.getCurrentConfiguration.setup.facets).includes(
                item
              ))
        );
      } else
        this.getConfigurationModal.list.forEach(
          (item) =>
            (itemToReturn[item] = Object.keys(
              this.getConfigurationModal.selectedList
            ).includes(item))
        );
      return itemToReturn;
    },
    disabledList() {
      var itemToReturn = {};
      if (this.getConfigurationModal.setupKey == "dynamicFacets")
        this.getConfigurationModal.list.forEach(
          (item) =>
            (itemToReturn[item] = Object.keys(
              this.getCurrentConfiguration.setup.facets
            ).includes(item))
        );
      return itemToReturn;
    },
    filteredList() {
      var filteredList = this.getConfigurationModal.list;
      if (this.getConfigurationModal.setupKey == "dynamicFacets") {
        if (this.selector == "not-selected")
          filteredList = Object.keys(this.getConfigurationModal.selectedList);
        else if (this.selector == "selected")
          filteredList = this.getConfigurationModal.list.filter(
            (item) =>
              !Object.keys(this.getCurrentConfiguration.setup.facets).includes(
                item
              ) &&
              !Object.keys(this.getConfigurationModal.selectedList).includes(
                item
              )
          );
      }
      if (this.getConfigurationModal.setupKey == "seoSettings") {
        filteredList = this.getConfigurationModal.filteredList;
      }
      return filteredList.filter((item) =>
        item?.includes(this.getConfigurationModal.filter)
      );
    },
    isQueryOrResults() {
      return (
        this.getConfigurationModal.setupKey == "query" ||
        this.getConfigurationModal.setupKey == "results"
      );
    },
    disabled() {
      if (!this.isQueryOrResults) return false;
      if (this.getConfigurationModal.setupKey == "results")
        return Object.keys(this.getConfigurationModal.selectedList).some(
          (item) => this.getConfigurationModal.selectedList[item].value == null
        );
      return false;
    },
    activeFacets: {
      set(value) {
        this.pushHistoryChanges();
        var setup = lodash.cloneDeep(this["getCurrentConfiguration"].setup);
        this.getConfigurationModal.filteredList = value;
        setup.config.seoSettings.list = value;
        this.setCurrentConfigurationByField({ field: "setup", value: setup });
      },
      get() {
        return this.getCurrentConfiguration.setup.config.seoSettings.list;
      },
    },
  },
  watch: {
    checked: {
      deep: true,
      handler: function(items) {
        this.itemToReturn = items;
      },
    },
  },
  methods: {
    ...mapActions([
      "setConfigurationModal",
      "setCurrentConfigurationByField",
      "setHistoryChanges",
    ]),
    setList(item) {
      var arrayToReturn = Object.values(
        this.getConfigurationModal.selectedList
          ? this.getConfigurationModal.selectedList
          : []
      );
      var itemIndex = -1;
      if (this.isQueryOrResults)
        itemIndex = arrayToReturn.map((obj) => obj.label).indexOf(item);
      else itemIndex = arrayToReturn.indexOf(item);
      if (itemIndex == -1) {
        if (this.getConfigurationModal.setupKey == "query") {
          var objectToAdd = {};
          objectToAdd.label = item;
          this.searchTypes.forEach((searchType) => {
            objectToAdd[searchType.key] = searchType.values[0].value;
          });
          arrayToReturn.push(objectToAdd);
        } else if (this.isQueryOrResults)
          arrayToReturn.push({ label: item, value: null });
        else arrayToReturn.push(item);
      } else {
        if (this.isQueryOrResults) arrayToReturn.splice(itemIndex, 1);
        else arrayToReturn.splice(itemIndex, 1);
      }
      var objectToReturn = {};
      if (this.isQueryOrResults)
        arrayToReturn.forEach((item) => (objectToReturn[item.label] = item));
      else arrayToReturn.forEach((item) => (objectToReturn[item] = item));
      this.setConfigurationModal({
        field: "selectedList",
        value: objectToReturn,
      });
    },
    setRadioList(item) {
      var arrayToReturn = [];
      arrayToReturn.push(item);
      var objectToReturn = {};
      if (this.isQueryOrResults)
        arrayToReturn.forEach((item) => (objectToReturn[item.label] = item));
      else arrayToReturn.forEach((item) => (objectToReturn[item] = item));
      var configurationToAdd = {
        ...this.getCurrentConfiguration.setup[
          this.getConfigurationModal.setupKey
        ],
      };
      if (
        this.getConfigurationModal.setupKey == "webhook" ||
        this.getConfigurationModal.setupKey == "boosting"
      ) {
        configurationToAdd.key = Object.keys(objectToReturn)[0];
        this.setConfigurationModal({
          field: "selectedList",
          value: configurationToAdd,
        });
      } else if (this.getConfigurationModal.type.endsWith("-hover")) {
        configurationToAdd.hover = { ...objectToReturn };
        this.setConfigurationModal({
          field: "selectedList",
          value: configurationToAdd,
        });
      } else if (this.getConfigurationModal.setupKey == "productImage") {
        configurationToAdd.main = { ...objectToReturn };
        this.setConfigurationModal({
          field: "selectedList",
          value: configurationToAdd,
        });
      } else
        this.setConfigurationModal({
          field: "selectedList",
          value: objectToReturn,
        });
    },
    setFilter(value) {
      this.setConfigurationModal({ field: "filter", value: value });
    },
    clickBtn() {
      if (this.isInfo) this.$bvModal.hide("config-modal");
      else {
        this.saveInfo();
        this.$bvModal.hide("config-modal");
      }
    },
    saveInfo() {
      var historyChanges = lodash.cloneDeep(this.getHistoryChanges);
      historyChanges.push(lodash.cloneDeep(this.getCurrentConfiguration));
      this.setHistoryChanges(historyChanges);
      var valueToSave = lodash.cloneDeep(this.getCurrentConfiguration);
      if (this.getConfigurationModal.isConfig) {
        if (this.getConfigurationModal.setupKey == "webhook")
          valueToSave.setup.config[
            this.getConfigurationModal.setupKey
          ].key = lodash.cloneDeep(this.getConfigurationModal.selectedList.key);
        else {
          if (!valueToSave.setup.config[this.getConfigurationModal.setupKey]) {
            valueToSave.setup.config[this.getConfigurationModal.setupKey] = {};
          }
          // if (this.getConfigurationModal.setupKey == "seoSettings") {
          //   const values = {};
          //   this.getCurrentConfiguration.setup.config.seoSettings.list?.forEach(
          //     (attr) => {
          //       if (this.getConfigurationModal.selectedList[attr]) {
          //         values[attr] = attr;
          //       }
          //     }
          //   );
          //   valueToSave.setup.config[
          //     this.getConfigurationModal.setupKey
          //   ].selectedList = values;
          // } else {
          valueToSave.setup.config[
            this.getConfigurationModal.setupKey
          ].selectedList = lodash.cloneDeep(
            this.getConfigurationModal.selectedList
          );
          //}
        }
      } else if (this.getConfigurationModal.setupKey == "boosting")
        valueToSave.setup[
          this.getConfigurationModal.setupKey
        ] = lodash.cloneDeep(this.getConfigurationModal.selectedList.key);
      else
        valueToSave.setup[
          this.getConfigurationModal.setupKey
        ] = lodash.cloneDeep(this.getConfigurationModal.selectedList);
      if (this.getConfigurationModal.setupKey == "dynamicFacets")
        valueToSave.setup.config.seoSettings.list = [
          ...(this.getCurrentConfiguration.setup.config.seoSettings.list ?? []),
          ...Object.entries(this.itemToReturn)
            .filter((item) => !!item[1])
            .map((arr) => arr[0]),
        ];
      this.setCurrentConfigurationByField({
        field: "setup",
        value: valueToSave.setup,
      });
      this.getConfigurationModal.selectedList;
    },
    dataLevelSet(item, key, value) {
      var objectToReturn = lodash.cloneDeep(
        this.getConfigurationModal.selectedList
      );
      objectToReturn[item][key] = value;
      if(key === "type"){
        if(value === "code"){
          objectToReturn[item]["typoTolerance"] = "none";
        } else {
          objectToReturn[item]["typoTolerance"] = "fuzzy2";
        }
      }
      this.setConfigurationModal({
        field: "selectedList",
        value: objectToReturn,
      });
    },
    selectedValue(label) {
      var valueToReturn = {};
      if (Array.isArray(this.getConfigurationModal.selectedList))
        valueToReturn = this.getConfigurationModal.selectedList.filter(
          (obj) => obj.label == label
        )[0];
      else {
        Object.keys(this.getConfigurationModal.selectedList).some((value) => {
          if (value == label)
            valueToReturn = this.getConfigurationModal.selectedList[value];
          return value == label;
        });
      }
      return valueToReturn;
    },
    getTypes() {
      var arrayToReturn = [
        { value: null, text: "Assign type", disabled: true },
      ];
      axios.get(Utils.ATTR + Utils.TYPES).then((response) => {
        response.data.forEach((attribute) => {
          arrayToReturn.push({ value: attribute.id, text: attribute.label });
        });
        this.options = [...arrayToReturn];
      });
      axios.get(Utils.SEARCH + Utils.TYPES).then((response) => {
        this.searchTypes = [];
        response.data.forEach((item) => {
          var values = item.values.map((subitem) => {
            return {
              value: subitem.value,
              text: subitem.label,
              description: subitem.description,
            };
          });
          this.searchTypes.push({
            key: item.key,
            label: item.label,
            values: values,
          });
        })
      });
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || relatedElement.active) && draggedElement.active
      );
    },
    pushHistoryChanges() {
      var historyChanges = lodash.cloneDeep(this.getHistoryChanges);
      historyChanges.push(lodash.cloneDeep(this.getCurrentConfiguration));
      this.setHistoryChanges(historyChanges);
    },
  },
  mounted() {
    this.getTypes();
    this.$root.$on("not-busy", this.getTypes);
    this.selector = "all";
  },
};
</script>

<style src="./ConfigModal.less" lang="less" scoped></style>
