<template>
  <div class="analytics-queries">
    <perfect-scrollbar ref="ps" @ps-y-reach-end="onReachEnd">
      <b-spinner label="Spinning" v-if="isLoading"></b-spinner>
      <div v-if="detailScreen == ''" :class="[{ loading: isLoading }]">
        <div class="title">{{ $t("analytics.queries.highlights") }}</div>
        <div class="list-container">
          <ListTable
            v-for="item of Object.keys(info)
              .filter((item, index) => index < 4)
              .filter((key) => info[key].length > 0)"
            :info="{ key: item, values: info[item] }"
            :key="'list_table_' + item"
            @showAll="showAll"
            @selectItem="toQueryScreen"
            :isDetail="false"
          />
        </div>
        <div class="title">
          {{ $t("analytics.queries.products_highlights") }}
        </div>
        <div class="list-container">
          <ListTable
            v-for="item of Object.keys(info)
              .filter((item, index) => index >= 4)
              .filter((key) => info[key].length > 0)"
            :info="{ key: item, values: info[item] }"
            :key="'list_table_' + item"
            @showAll="showAll"
            @selectItem="toProductScreen"
            :isDetail="false"
          />
        </div>
      </div>
      <div :class="[{ loading: isLoading }]" v-else-if="detailScreen == 'list'">
        <div class="back" @click="backClick">
          <div class="icon"></div>
          <div class="label">{{ $t("analytics.goBack") }}</div>
        </div>
        <div class="list-container">
          <ListTable
            :info="{ key: detailType, values: detailArray }"
            @showAll="showAll"
            @selectItem="toScreen"
            :isDetail="true"
          />
        </div>
      </div>
      <div
        v-else-if="detailScreen == 'query'"
        :class="[{ loading: isLoading }]"
      >
        <div class="back" @click="backClick">
          <div class="icon"></div>
          <div class="label">{{ $t("analytics.goBack") }}</div>
        </div>
        <div class="detail">
          <div class="left">
            <div class="title">
              {{ $t("analytics.queries.query_performance") }}
            </div>
            <div class="desc">{{ $t("analytics.queries.key_metrics") }}</div>
          </div>
          <div class="value">{{ selectedQuery }}</div>
        </div>
        <div class="chart-container">
          <apexchart
            type="bar"
            height="350"
            :options="columnChartOptions"
            :series="chartData.data"
          />
        </div>
        <div class="table-container">
          <div class="header">
            <div
              @click="productType = 'all'"
              :class="[{ selected: productType == 'all' }]"
            >
              {{ $t("analytics.queries.all") }}
            </div>
            <!-- <div @click="productType='query'" :class="[{selected:productType=='query'}]"> {{$t('analytics.queries.query_only')}} </div>
              <div @click="productType='filters'" :class="[{selected:productType=='filters'}]"> {{$t('analytics.queries.query_filters')}} </div> -->
          </div>
          <div class="label" v-if="productType == 'all'">
            {{ $t("analytics.queries.aggregate_all") }}
          </div>
          <div class="label" v-else-if="productType == 'query'">
            {{ $t("analytics.queries.aggregate_query_only") }}
          </div>
          <div class="label" v-else>
            {{ $t("analytics.queries.aggregate_query_filters") }}
          </div>
          <div class="table">
            <b-table
              class="table"
              :items="queryProducts[productType]"
              :fields="fields"
              v-if="queryProducts[productType].length > 0"
            >
              <template #head(position)="data">
                <div class="with-sort">
                  {{ data.label }}
                  <span
                    @click="() => changeSortTable(data.field.key)"
                    :class="['icon', querySortClass(data.field.key)]"
                  ></span>
                </div>
              </template>
              <template #head(ctr)="data">
                <div class="with-sort">
                  {{ data.label }}
                  <span
                    @click="() => changeSortTable(data.field.key)"
                    :class="['icon', querySortClass(data.field.key)]"
                  ></span>
                </div>
              </template>
              <template #head(price)="data">
                <div class="with-sort">
                  {{ data.label }}
                  <span
                    @click="() => changeSortTable(data.field.key)"
                    :class="['icon', querySortClass(data.field.key)]"
                  ></span>
                </div>
              </template>
              <template #head(conversion)="data">
                <div class="with-sort">
                  {{ data.label }}
                  <span
                    @click="() => changeSortTable(data.field.key)"
                    :class="['icon', querySortClass(data.field.key)]"
                  ></span>
                </div>
              </template>
              <template #cell(name)="data">
                <div class="name" @click="() => {}">
                  {{ data.value }}
                </div>
              </template>
              <template #cell(price)="data">
                <div
                  :class="[
                    'price-container',
                    priceClass(data.item.price, data.item.marketPrice),
                  ]"
                >
                  <div class="icon"></div>
                  <div class="price">
                    {{ $options.setNumberFormat(data.value, 2, ",", ".") }} €
                  </div>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div
        v-else-if="detailScreen == 'product'"
        :class="[{ loading: isLoading }]"
      >
        <div class="back" @click="backClick">
          <div class="icon"></div>
          <div class="label">{{ $t("analytics.goBack") }}</div>
        </div>
        <div class="detail">
          <div class="left">
            <div class="title">
              {{ $t("analytics.queries.product_performance") }}
            </div>
            <div class="desc">{{ $t("analytics.queries.analyze") }}</div>
          </div>
          <div class="right">
            <div class="value">{{ selectedProduct }}</div>
            <div class="current-price" v-if="productInfo.avgPrice">
              {{ $t("analytics.queries.current_price") }}
              <span>
                {{
                  $options.setNumberFormat(productInfo.avgPrice, 2, ",", ".")
                }}
                €
              </span>
            </div>
          </div>
        </div>
        <div class="tables-container">
          <div class="table-container">
            <div class="title">
              {{ $t("analytics.queries.product_performance") }}
            </div>
            <div class="desc">{{ $t("analytics.queries.how_often") }}</div>
            <div class="table">
              <b-table
                class="table"
                :items="productInfo.distribution"
                :fields="infoProductsFields"
              />
            </div>
          </div>
          <div class="table-container" v-if="productInfo.marketPrice">
            <div class="title">{{ $t("analytics.queries.market_price") }}</div>
            <div class="desc">{{ $t("analytics.queries.product_valued") }}</div>
            <div class="table">
              <b-table
                class="table"
                :items="productInfo.marketPrice"
                :fields="marketPriceFields"
              >
                <template #head(price)="data">
                  <div class="with-sort">
                    {{ data.label }}
                    <span
                      @click="changeSortProductTable"
                      :class="['icon', productSorting]"
                    ></span>
                  </div>
                </template>
                <template #cell(price)="data">
                  <div
                    :class="[
                      'price-container',
                      priceClass(data.item.price, productInfo.avgPrice),
                    ]"
                  >
                    <div class="icon"></div>
                    <div class="price">
                      {{ $options.setNumberFormat(data.value, 2, ",", ".") }} €
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import axios from "@/utils/Axios";
import axiosLib from "axios";
import lodash from "lodash";
import Utils from "@/utils/Utils";
import { mapGetters } from "vuex";
import ListTable from "../../../components/ListTable/ListTable.vue";

export default {
  name: "AnalyticsQueries",
  setNumberFormat: Utils.setNumberFormat,
  data() {
    return {
      detailScreen: "",
      selectedQuery: "",
      selectedProduct: "",
      detailType: "",
      page: 1,
      isLoading: false,
      stopPaging: false,
      cancelToken: null,
      detailArray: [],
      productType: "all",
      productSorting: "desc",
      querySorting: {
        key: "position",
        order: "asc",
      },
      info: {
        trendingSearches: [{ value: "", count: 0 }],
        convertingSearches: [{ value: "", count: 0 }],
        noResults: [{ value: "", count: 0 }],
        noClicks: [{ value: "", count: 0 }],
        clickedProducts: [{ value: "", count: 0 }],
        convertingProducts: [{ value: "", count: 0 }],
        leastClickedProducts: [{ value: "", count: 0 }],
        leastConvertingProducts: [{ value: "", count: 0 }],
      },
      baseChartOptions: {
        chart: {
          zoom: { enabled: false },
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 400,
          },
          type: "bar",
        },
        grid: {
          row: { opacity: 0.5 },
        },
        colors: ["#825EEA", "#553C9B"],
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              total: {
                enabled: true,
              },
            },
          },
        },
        legend: {
          show: true,
        },
      },
      chartData: {
        columns: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        data: [
          {
            name: this.$t("analytics.queries.search_intents"),
            data: [2000, 4110, 4550, 4200, 5220, 4400, 8990],
          },
          {
            name: this.$t("analytics.queries.search_with_clicks"),
            data: [1000, 1100, 1500, 1100, 1200, 1200, 1900],
          },
        ],
      },
      queryProducts: {
        all: [
          {
            position: 1,
            name: "N/A",
            sku: "",
            ctr: "",
          },
        ],
        query: [
          {
            position: 1,
            name: "",
            sku: "",
            ctr: "",
          },
        ],
        filters: [
          {
            position: 1,
            name: "",
            sku: "",
            ctr: "",
          },
        ],
      },
      marketPriceFields: [
        { key: "shop", label: this.$t("analytics.queries.website") },
        { key: "price", label: this.$t("analytics.queries.price") },
        { key: "comparison", label: this.$t("analytics.queries.comparison") },
      ],
      productInfo: {
        avgPrice: 0,
        distribution: [
          {
            position: 1,
            frequency: "0%",
            ctr: "0%",
            conversion: "0%",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentConfiguration",
      "getAnalyticsSelectedDevice",
      "getAnalyticsSelectedDate",
      "getBaseUrl",
    ]),
    columnChartOptions() {
      return {
        ...this.baseChartOptions,
        xaxis: { categories: this.chartData.columns },
      };
    },
    infoProductsFields() {
      var fieldsToReturn = [
        { key: "position", label: this.$t("analytics.queries.position") },
        { key: "frequency", label: this.$t("analytics.queries.frequency") },
        { key: "ctr", label: this.$t("analytics.queries.ctr") },
        {
          key: "conversion",
          label: this.$t("analytics.queries.conversion_rate"),
        },
      ];
      if (!this.productInfo.distribution[0].conversion)
        fieldsToReturn = fieldsToReturn.filter(
          (item) => item.key != "conversion"
        );
      return fieldsToReturn;
    },
    fields() {
      var fieldsToReturn = [
        { key: "position", label: this.$t("analytics.queries.queries") },
        { key: "name", label: this.$t("analytics.queries.product_name") },
        //{ key: 'sku', label: this.$t('analytics.queries.sku') },
        { key: "ctr", label: this.$t("analytics.queries.ctr") },
        {
          key: "conversion",
          label: this.$t("analytics.queries.conversion_rate"),
        },
        //{ key: 'price', label: this.$t('analytics.queries.avg_price') }
      ];
      if (!this.queryProducts[this.productType][0].conversion)
        fieldsToReturn = fieldsToReturn.filter(
          (item) => item.key != "conversion"
        );
      return fieldsToReturn;
    },
  },
  methods: {
    async getInfo() {
      this.isLoading = true;
      this.cancelToken?.cancel("cancelled");
      this.cancelToken = axiosLib.CancelToken.source();
      var postObject = {
        device: this.getAnalyticsSelectedDevice,
        date: Utils.fromDateToTimestamp(this.getAnalyticsSelectedDate),
      };
      if (this.detailScreen == "")
        await axios
          .post(
            `/analytics/${this.getCurrentConfiguration.id}/query`,
            postObject,
            { cancelToken: this.cancelToken.token }
          )
          .then((response) => (this.info = response.data));
      else if (this.detailScreen == "query") {
        postObject.query = this.selectedQuery;
        this.page = 0;
        postObject.page = this.page;
        postObject.sorting = this.querySorting;
        await axios
          .post(
            `/analytics/${this.getCurrentConfiguration.id}/query-info`,
            postObject,
            { cancelToken: this.cancelToken.token }
          )
          .then((response) => {
            this.chartData.columns = response.data.chartData.columns;
            this.chartData.data[0].data = response.data.chartData.data[0].data;
            this.chartData.data[1].data = response.data.chartData.data[1].data;
            this.queryProducts = response.data.queryProducts;
          });
      } else if (this.detailScreen == "product") {
        postObject.product = this.selectedProduct;
        postObject.sorting = this.productSorting;
        await axios
          .post(
            `/analytics/${this.getCurrentConfiguration.id}/product-info`,
            postObject,
            { cancelToken: this.cancelToken.token }
          )
          .then((response) => (this.productInfo = response.data));
      }
      this.isLoading = false;
    },
    showAll(detailType) {
      this.detailType = detailType;
      this.detailArray = lodash.cloneDeep(this.info[detailType]);
      this.detailScreen = "list";
    },
    async onReachEnd() {
      if (
        (this.detailScreen !== "list" && this.detailScreen !== "query") ||
        this.isLoading ||
        this.stopPaging
      )
        return null;
      this.isLoading = true;
      this.page++;
      var postObject = {
        device: this.getAnalyticsSelectedDevice,
        date: Utils.fromDateToTimestamp(this.getAnalyticsSelectedDate),
        page: this.page,
      };

      if (this.detailScreen == "list") {
        postObject.type = this.detailType;
        await axios
          .post(
            `/analytics/${this.getCurrentConfiguration.id}/query`,
            postObject,
            { cancelToken: this.cancelToken.token }
          )
          .then((response) => {
            this.detailArray = [
              ...this.detailArray,
              ...response.data[this.detailType],
            ];
            if (response.data[this.detailType].length == 0)
              this.stopPaging = true;
          });
      } else if (this.detailScreen == "query") {
        postObject.query = this.selectedQuery;
        postObject.sorting = this.querySorting;
        await axios
          .post(
            `/analytics/${this.getCurrentConfiguration.id}/query-info`,
            postObject,
            { cancelToken: this.cancelToken.token }
          )
          .then((response) => {
            this.queryProducts.all = [
              ...this.queryProducts.all,
              ...response.data.queryProducts.all,
            ];
            this.queryProducts.query = [
              ...this.queryProducts.query,
              ...response.data.queryProducts.query,
            ];
            this.queryProducts.filters = [
              ...this.queryProducts.filters,
              ...response.data.queryProducts.filters,
            ];
            if (response.data.queryProducts[this.productType].length == 0)
              this.stopPaging = true;
          });
      }
      this.isLoading = false;
    },
    toQueryScreen(query) {
      this.$refs.ps.$el.scrollTop = 0;
      this.selectedQuery = query;
      this.detailScreen = "query";
      this.page = 1;
      this.getInfo();
    },
    toProductScreen(product) {
      this.$refs.ps.$el.scrollTop = 0;
      this.selectedProduct = product;
      this.detailScreen = "product";
      this.getInfo();
    },
    toScreen(value) {
      this.$refs.ps.$el.scrollTop = 0;
      if (
        this.detailType == "least_converting_products" ||
        this.detailType == "least_clicked_products" ||
        this.detailType == "clicked_products" ||
        this.detailType == "converting_products"
      )
        this.toProductScreen(value);
      else this.toQueryScreen(value);
    },
    backClick() {
      this.$refs.ps.$el.scrollTop = 0;
      if (this.detailScreen == "product" && this.selectedQuery !== "") {
        this.detailScreen = "query";
      } else if (this.detailScreen !== "list" && this.detailArray.length > 0) {
        this.detailScreen = "list";
        this.selectedQuery = "";
      } else this.toDetail();
      this.selectedProduct = "";
    },
    toDetail() {
      this.detailScreen = "";
      this.selectedQuery = "";
      this.page = 1;
      this.stopPaging = false;
      this.detailArray = [];
      this.selectedProduct = "";
    },
    onChangedWebsite() {
      this.toDetail();
      this.getInfo();
    },
    onUpdateAnalytics() {
      if (this.detailScreen == "list") {
        this.page = 1;
        this.stopPaging = false;
        this.detailArray = [];
        this.onReachEnd();
      } else this.getInfo();
    },
    priceClass(price, marketPrice) {
      if (!marketPrice) return "";
      if (price < marketPrice) return "minus";
      if (price > marketPrice) return "plus";
      return "equal";
    },
    querySortClass(label) {
      if (label != this.querySorting.key) return "";
      return this.querySorting.order;
    },
    changeSortTable(label) {
      if (label != this.querySorting.key)
        this.querySorting = { key: label, order: "asc" };
      else
        this.querySorting.order =
          this.querySorting.order == "asc" ? "desc" : "asc";
      this.getInfo();
    },
    changeSortProductTable() {
      this.productSorting = this.productSorting == "asc" ? "desc" : "asc";
      this.getInfo();
    },
  },
  mounted() {
    this.$root.$on("changed-website", this.onChangedWebsite);
    this.$root.$on("update-analytics", this.onUpdateAnalytics);
    this.getInfo();
  },
  beforeDestroy() {
    this.$root.$off("changed-website", this.onChangedWebsite);
    this.$root.$off("update-analytics", this.onUpdateAnalytics);
  },
  components: { ListTable },
};
</script>

<style src="./AnalyticsQueries.less" lang="less" scoped></style>
