<template>
  <b-modal id="delete-shop-modal" :visible="getDeleteShopModalVisible" hide-footer hide-header size="lg" @hide="setDeleteShopModalVisible(false)"> 
    <div class="close" @click="setDeleteShopModalVisible(false)"></div>
    <div class="title"> {{$t('deleteShopModal.deleteShop')}} </div>   
    <div class="desc"> {{$t('deleteShopModal.enterShopUrl')}} </div>
    <b-input :placeholder="$t('deleteShopModal.typeShopUrl')" v-model="typedShopUrl"></b-input>
    <div class="hint"> {{$t('deleteShopModal.shopUrl')}} <b> {{ shopUrl }} </b> </div>
    <div class="hint disclaimer"> {{$t('deleteShopModal.note')}} </div>
    <div class="button-container">
      <b-button @click="closeModal"> {{$t('deleteShopModal.cancel')}} </b-button>
      <b-button :class="['delete',{disabled:!disabled}]" @click="deleteShop"> {{$t('deleteShopModal.deleteShop')}} </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from '@/utils/Axios'
import Utils from '@/utils/Utils'
import lodash from 'lodash'

export default {
  name: 'DeleteShopModal',
  data(){
    return {
      typedShopUrl:""
    }
  },
  computed:{
    ...mapGetters(['getDeleteShopModalVisible','getCurrentConfiguration','getDisplayedWebsites']),
    shopUrl(){
      return this.getCurrentConfiguration.url?this.getCurrentConfiguration.url.replace("https://","").replace("http://","").replace("www.",""):''
    },
    disabled(){
      return this.typedShopUrl==this.shopUrl;
    }
  },
  methods:{
    ...mapActions(['setDeleteShopModalVisible','setWebsites','setCurrentConfiguration','setOpenedWebsitesList','setHistoryChanges']),
    closeModal(){
      this.setDeleteShopModalVisible(false);
    },
    async deleteShop(){
      await axios.delete(Utils.SHOPS+'/'+this.getCurrentConfiguration.id);
      await axios.get(Utils.SHOPS)
      .then(response=>{ this.setWebsites(response.data); })
      this.setDeleteShopModalVisible(false);
      if(this.getDisplayedWebsites.length>0 && lodash.isEqual(this.getCurrentConfiguration,Utils.newEmptyConfig())){
        this.setOpenedWebsitesList(false);
        this.setCurrentConfiguration(lodash.clone(this.getDisplayedWebsites[0]));
        this.$router.push({name:"dashboard"})
      } else {
        this.setCurrentConfiguration(lodash.clone(Utils.newEmptyConfig()));
        this.$router.push({name:"wizard-start"})
      }
      this.setHistoryChanges([]);
    }
  }
}
</script>

<style src="./DeleteShopModal.less" lang="less" scoped></style>