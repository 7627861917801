<template>
  <div class="analytics-filters">
    <perfect-scrollbar ref="ps" @ps-y-reach-end="onReachEnd">
      <b-spinner label="Spinning" v-if="isLoading"></b-spinner>
      <div :class="[{ loading: isLoading }]">
        <div class="back" @click="goBack" v-if="selectedFacet != ''">
          <div class="icon"></div>
          <div class="label">{{ $t("analytics.goBack") }}</div>
        </div>
        <div class="header" v-if="selectedFacet == ''">
          <div class="title">{{ $t("analytics.filters.facets_filters") }}</div>
          <div class="value-container">
            <div class="top">
              <div class="value">{{ facetsInfo.searches.value }}</div>
              <div
                :class="[
                  'chip-rate',
                  { negative: facetsInfo.searches.rate < 0 },
                ]"
              >
                <span class="icon"></span> {{ facetsInfo.searches.rate }}%
              </div>
            </div>
            <div class="label">
              {{ $t("analytics.filters.total_searches") }}
            </div>
          </div>
          <div class="value-container">
            <div class="top">
              <div class="value">{{ facetsInfo.click.value }}</div>
              <div
                :class="['chip-rate', { negative: facetsInfo.click.rate < 0 }]"
              >
                <span class="icon"></span> {{ facetsInfo.click.rate }}%
              </div>
            </div>
            <div class="label">{{ $t("analytics.filters.total_clicks") }}</div>
          </div>
        </div>
        <div class="detail" v-else>
          <div class="left">
            <div class="title">{{ $t("analytics.filters.facet_detail") }}</div>
            <div class="desc">
              {{ $t("analytics.filters.filters_belonging") }}
            </div>
          </div>
          <div class="value">{{ selectedFacet }}</div>
        </div>
        <div class="tables-container">
          <div class="tables-row">
            <div class="table-item">
              <div class="header">
                <div class="title">
                  {{ $t("analytics.filters.best_filters") }}
                </div>
                <div
                  :class="[
                    'selector',
                    { selected: selector.best == 'searches' },
                  ]"
                  @click="() => setSelector('best', 'searches')"
                >
                  {{ $t("analytics.filters.searches") }}
                </div>
                <!-- <div :class="['selector',{selected:selector.best=='ctr'}]" @click="()=>setSelector('best','ctr')"> {{$t('analytics.filters.ctr')}} </div> -->
              </div>
              <div class="table">
                <b-table
                  :items="
                    selectedFacet == ''
                      ? facetsInfo.best[selector.best]
                      : detailInfo.best[selector.best]
                  "
                  :fields="fields"
                />
              </div>
            </div>
            <div class="table-item">
              <div class="header">
                <div class="title">
                  {{ $t("analytics.filters.worst_filters") }}
                </div>
                <div
                  :class="[
                    'selector',
                    { selected: selector.worst == 'searches' },
                  ]"
                  @click="() => setSelector('worst', 'searches')"
                >
                  {{ $t("analytics.filters.searches") }}
                </div>
                <!-- <div :class="['selector',{selected:selector.worst=='ctr'}]" @click="()=>setSelector('worst','ctr')"> {{$t('analytics.filters.ctr')}} </div> -->
              </div>
              <div class="table">
                <b-table
                  :items="
                    selectedFacet == ''
                      ? facetsInfo.worst[selector.worst]
                      : detailInfo.worst[selector.worst]
                  "
                  :fields="fields"
                />
              </div>
            </div>
          </div>
          <div class="tables-row">
            <template v-if="selectedFacet == ''">
              <div class="table-item">
                <div class="header">
                  <div class="title">{{ $t("analytics.filters.facets") }}</div>
                </div>
                <div class="table">
                  <b-table :items="facetsInfo.facets" :fields="fields">
                    <template #head(searches)="data">
                      <div class="with-sort">
                        {{ data.label }}
                        <span
                          @click="() => changeFacetsSort(data.field.key)"
                          :class="['icon', facetsSortClass(data.field.key)]"
                        ></span>
                      </div>
                    </template>
                    <!-- <template #head(ctr)="data">
                        <div class="with-sort"> {{ data.label }} <span @click="()=>changeFacetsSort(data.field.key)" :class="['icon',facetsSortClass(data.field.key)]"></span> </div>
                      </template> -->
                    <template #cell(label)="data">
                      <div
                        class="name"
                        @click="() => toDetailFacet(data.value)"
                      >
                        {{ data.value }}
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="table-item">
                <div class="header">
                  <div class="title">{{ $t("analytics.filters.sorting") }}</div>
                </div>
                <div class="table">
                  <b-table :items="facetsInfo.sorting" :fields="fields">
                    <template #head(searches)="data">
                      <div class="with-sort">
                        {{ data.label }}
                        <span
                          @click="() => changeSortingSort(data.field.key)"
                          :class="['icon', sortingSortClass(data.field.key)]"
                        ></span>
                      </div>
                    </template>
                    <!-- <template #head(ctr)="data">
                        <div class="with-sort"> {{ data.label }} <span @click="()=>changeSortingSort(data.field.key)" :class="['icon',sortingSortClass(data.field.key)]"></span> </div>
                      </template> -->
                  </b-table>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="table-item single">
                <div class="header">
                  <div class="title">
                    {{ $t("analytics.filters.facet_content") }}
                  </div>
                </div>
                <div class="table">
                  <b-table :items="detailInfo.facets" :fields="fields">
                    <template #head(searches)="data">
                      <div class="with-sort">
                        {{ data.label }}
                        <span
                          @click="() => changeFacetSort(data.field.key)"
                          :class="['icon', facetSortClass(data.field.key)]"
                        ></span>
                      </div>
                    </template>
                    <!-- <template #head(ctr)="data">
                        <div class="with-sort"> {{ data.label }} <span @click="()=>changeFacetSort(data.field.key)" :class="['icon',facetSortClass(data.field.key)]"></span> </div>
                      </template> -->
                  </b-table>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import axios from "@/utils/Axios";
import axiosLib from "axios";
import Utils from "@/utils/Utils";
import { mapGetters } from "vuex";

export default {
  name: "AnalyticsFilters",
  data() {
    return {
      fields: [
        { key: "label", label: this.$t("analytics.filters.name") },
        { key: "searches", label: this.$t("analytics.filters.searches") },
        //{ key: 'ctr', label: this.$t('analytics.filters.ctr')},
      ],
      facetsInfo: {
        searches: {
          value: "0%",
          rate: 0,
        },
        click: {
          value: "0%",
          rate: 0,
        },
        best: {
          searches: [{ label: "N/A", searches: "", ctr: "" }],
          ctr: [{ label: "N/A", searches: "", ctr: "" }],
        },
        worst: {
          searches: [{ label: "N/A", searches: "", ctr: "" }],
          ctr: [{ label: "N/A", searches: "", ctr: "" }],
        },
        facets: [{ label: "N/A", searches: "", ctr: "" }],
        sorting: [
          { isDefault: true, label: "Relevance", searches: "", ctr: "" },
        ],
      },
      selector: {
        best: "searches",
        worst: "searches",
      },
      detailInfo: {
        best: {
          searches: [{ label: "N/A", searches: "", ctr: "" }],
          ctr: [{ label: "N/A", searches: "", ctr: "" }],
        },
        worst: {
          searches: [{ label: "N/A", searches: "", ctr: "" }],
          ctr: [{ label: "N/A", searches: "", ctr: "" }],
        },
        facets: [{ label: "N/A", searches: "", ctr: "" }],
      },
      selectedFacet: "",
      facetsSort: {
        key: "searches",
        order: "desc",
      },
      facetSort: {
        key: "searches",
        order: "desc",
      },
      sortingSort: {
        key: "searches",
        order: "desc",
      },
      isLoading: false,
      page: 1,
      cancelToken: null,
      stopPaging: false,
    };
  },
  computed: {
    ...mapGetters([
      "getAnalyticsSelectedDevice",
      "getAnalyticsSelectedDate",
      "getCurrentConfiguration",
    ]),
  },
  methods: {
    setSelector(key, value) {
      this.selector[key] = value;
      // this.getInfo();
    },
    toDetailFacet(value) {
      this.$refs.ps.$el.scrollTop = 0;
      this.selectedFacet = value;
      this.getInfo();
    },
    facetsSortClass(label) {
      if (label != this.facetsSort.key) return "";
      return this.facetsSort.order;
    },
    changeFacetsSort(label) {
      if (label != this.facetsSort.key)
        this.facetsSort = { key: label, order: "asc" };
      else
        this.facetsSort.order = this.facetsSort.order == "asc" ? "desc" : "asc";
      this.getInfo();
    },
    facetSortClass(label) {
      if (label != this.facetSort.key) return "";
      return this.facetSort.order;
    },
    changeFacetSort(label) {
      if (label != this.facetSort.key)
        this.facetSort = { key: label, order: "asc" };
      else
        this.facetSort.order = this.facetSort.order == "asc" ? "desc" : "asc";
      this.getInfo();
    },
    sortingSortClass(label) {
      if (label != this.sortingSort.key) return "";
      return this.sortingSort.order;
    },
    changeSortingSort(label) {
      if (label != this.sortingSort.key)
        this.sortingSort = { key: label, order: "asc" };
      else
        this.sortingSort.order =
          this.sortingSort.order == "asc" ? "desc" : "asc";
      this.getInfo();
    },
    async getInfo() {
      this.isLoading = true;
      this.cancelToken?.cancel("cancelled");
      this.cancelToken = axiosLib.CancelToken.source();
      this.page = 1;
      var postObject = {
        device: this.getAnalyticsSelectedDevice,
        date: Utils.fromDateToTimestamp(this.getAnalyticsSelectedDate),
        page: this.page,
      };
      if (this.selectedFacet == "") {
        postObject.sort = this.facetsSort;
        postObject.sortingSort = this.sortingSort;
        await axios
          .post(
            `/analytics/${this.getCurrentConfiguration.id}/facets-info`,
            postObject,
            { cancelToken: this.cancelToken.token }
          )
          .then((response) => (this.facetsInfo = response.data));
      } else {
        postObject.sort = this.facetSort;
        postObject.facet = this.selectedFacet;
        await axios
          .post(
            `/analytics/${this.getCurrentConfiguration.id}/facet-info`,
            postObject,
            { cancelToken: this.cancelToken.token }
          )
          .then((response) => (this.detailInfo = response.data));
      }
      this.isLoading = false;
    },
    onChangedWebsite() {
      this.selectedFacet = "";
      this.page = 1;
      this.getInfo();
    },
    async onReachEnd() {
      if (this.isLoading || this.stopPaging) return null;
      this.isLoading = true;
      this.page++;
      var postObject = {
        device: this.getAnalyticsSelectedDevice,
        date: Utils.fromDateToTimestamp(this.getAnalyticsSelectedDate),
        page: this.page,
      };
      if (this.selectedFacet == "") {
        postObject.sort = this.facetsSort;
        postObject.sortingSort = this.sortingSort;
        await axios
          .post(
            `/analytics/${this.getCurrentConfiguration.id}/facets-info`,
            postObject,
            { cancelToken: this.cancelToken.token }
          )
          .then((response) => {
            this.facetsInfo.facets = [
              ...this.facetsInfo.facets,
              ...response.data.facets,
            ];
            if (response.data.facets.length == 0) this.stopPaging = true;
          });
      } else {
        postObject.sort = this.facetSort;
        postObject.facet = this.selectedFacet;
        await axios
          .post(
            `/analytics/${this.getCurrentConfiguration.id}/facet-info`,
            postObject,
            { cancelToken: this.cancelToken.token }
          )
          .then((response) => {
            this.detailInfo.facets = [
              ...this.detailInfo.facets,
              ...response.data.facets,
            ];
            if (response.data.facets.length == 0) this.stopPaging = true;
          });
      }
      this.isLoading = false;
    },
    goBack() {
      this.selectedFacet = "";
      this.selector = {
        best: "searches",
        worst: "searches",
      };
      this.getInfo();
    },
  },
  mounted() {
    this.$root.$on("changed-website", this.onChangedWebsite);
    this.$root.$on("update-analytics", this.getInfo);
    this.getInfo();
  },
  beforeDestroy() {
    this.$root.$off("changed-website", this.onChangedWebsite);
    this.$root.$off("update-analytics", this.onUpdateAnalytics);
  },
};
</script>

<style src="./AnalyticsFilters.less" lang="less" scoped></style>
