<template>
  <perfect-scrollbar class="layoutDesign-card" ref="layoutDesign-card" @ps-scroll-y="onScroll">
    <div class="function-row">
      <div class="info-column">
        <div class="sub-title"> {{$t('layoutDesign.card.configurable')}} </div>
        <div class="desc"> {{$t('layoutDesign.card.management')}} </div>
        <div class="info-box" v-if="getCurrentConfiguration.configurable_attributes.length==0">
          <div class="icon"></div>
          <div class="label"> {{$t('layoutDesign.card.configurableInfoBox')}} </div>
        </div>
        <div class="values" v-else>
          <div class="label"> {{$t('layoutDesign.card.attribute',{var1:1})}} </div>
          <b-select :options="configurableAttributesKeys" :value="configurableAttributes[0].key" @change="(value)=>setItem('key',0,value)"> </b-select>
          <div class="selects-row">
            <div class="select-container">
              <div class="label"> {{$t('layoutDesign.card.label')}} </div>
              <b-input :value="configurableAttributes[0].label" @change="(value)=>setItem('label',0,value)"> </b-input>
            </div>
            <div class="select-container">
              <div class="label"> {{$t('layoutDesign.card.type')}} </div>
              <b-select :options="$options.configurableValuesOptions" :value="configurableAttributes[0].type" @change="(value)=>setItem('type',0,value)"></b-select>
            </div>
          </div>
          <div :class="['label',{disabled:checkFirstItem}]"> {{$t('layoutDesign.card.attribute',{var1:2})}} </div>
          <b-select :class="[{disabled:checkFirstItem}]" :options="configurableAttributesKeys" :value="configurableAttributes[1].key" @change="(value)=>setItem('key',1,value)"> </b-select>
          <div :class="['selects-row',{disabled:checkFirstItem}]">
            <div class="select-container">
              <div class="label"> {{$t('layoutDesign.card.label')}} </div>
              <b-input :value="configurableAttributes[1].label" @change="(value)=>setItem('label',1,value)"> </b-input>
            </div>
            <div class="select-container">
              <div class="label"> {{$t('layoutDesign.card.type')}} </div>
              <b-select :options="$options.configurableValuesOptions" :value="configurableAttributes[1].type" @change="(value)=>setItem('type',1,value)"></b-select>
            </div>
          </div>
        </div>
      </div>
      <div class="img-column configurable" ref="configurable-row"> <div :class="['img']"></div> </div>
      <!-- <div class="img-column configurable" ref="configurable-row"> <div :class="['img',scrollCheck($refs['configurable-row'])]"></div> </div> -->
    </div>
  </perfect-scrollbar>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'
import lodash from 'lodash'

export default { 
  name: 'LayoutDesignCard',
  configurableValuesOptions:[
    {value: null, text: '-- Select --', disabled:true},
    {value: 'value', text: 'Value'},
    {value: 'image', text: 'Image'}
  ],
  emptyAttribute:{
    key:null,
    label:"",
    type:null,
    scrollTop:0
  },
  data(){
    return {
    }
  },
  computed:{
    ...mapGetters(['getCurrentConfiguration','getHistoryChanges']),
    configurableAttributes(){return this.getCurrentConfiguration.layout.card?.configurableAttributes && this.getCurrentConfiguration.layout.card?.configurableAttributes.length>0?this.getCurrentConfiguration.layout.card?.configurableAttributes:[lodash.cloneDeep(this.$options.emptyAttribute),lodash.cloneDeep(this.$options.emptyAttribute)]},
    configurableAttributesKeys(){
      return [{value: null, text: '-- Select --'},...this.getCurrentConfiguration.configurable_attributes.map(item=>({
        text:item,
        value:item
      }))]
    },
    checkFirstItem(){
      return this.configurableAttributes[0].key==null || this.configurableAttributes[0].type==null || this.configurableAttributes[0].label==''
    }
  },
  methods:{
    ...mapActions(['setCurrentConfigurationByField','setHistoryChanges']),
    pushHistoryChanges(){
      var historyChanges=lodash.cloneDeep(this.getHistoryChanges)
      historyChanges.push(lodash.cloneDeep(this.getCurrentConfiguration));
      this.setHistoryChanges(historyChanges);
    },
    setValue(key,value){
      this.pushHistoryChanges();
      var currentConfiguration=lodash.cloneDeep(this['getCurrentConfiguration']);
      currentConfiguration.layout.card=currentConfiguration.layout.card?currentConfiguration.layout.card:{};
      currentConfiguration.layout.card[key]=value;
      this.setCurrentConfigurationByField({field:'layout',value:currentConfiguration.layout})
    },
    setItem(key,index,value){
      var configurableAttributesTemp=lodash.cloneDeep(this.configurableAttributes);
      if(this.checkFirstItem){
        configurableAttributesTemp[1].key=null;
        configurableAttributesTemp[1].type=null;
        configurableAttributesTemp[1].label='';
      } 
      configurableAttributesTemp[index][key]=value;
      this.setValue('configurableAttributes',configurableAttributesTemp);
    },
    onScroll(){
      this.scrollTop=this.$refs['layoutDesign-card']?.$el.scrollTop;
    },
    scrollCheck(img){
      if(this.scrollTop<img?.offsetTop-10) return ""
      if(this.scrollTop>img?.offsetTop+img?.offsetHeight-img?.childNodes[0].offsetHeight-10) return "scrolled"
      return "fixed"
    },
  }
}
</script>

<style src="./LayoutDesignCard.less" lang="less" scoped></style>