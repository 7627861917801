<template>
  <div :class="['as-panel', { show: showPanel }]">
    <div class="overlay" @click="closePanel"></div>
    <div :class="['panel', { bannerCard: actionType == 'bannerCard' }]">
      <div class="header" v-if="actionType == 'bannerCard'">
        <div class="title">{{ $t("asPanel.bannerPosition") }}</div>
        <div class="desc">{{ $t("asPanel.youCanOrder") }}</div>
        <div class="icon close" @click="closePanel"></div>
      </div>
      <div class="header" v-else>
        <div class="delete" @click="deleteWord" v-if="searchWord !== ''"></div>
        <b-input
          class="as-ipnut"
          v-model="searchWord"
          @keyup="onInputKeyUp"
        ></b-input>
        <div class="icon close" @click="closePanel"></div>
      </div>
      <div class="results" v-if="actionType == 'bannerCard'">
        <div class="results-container">
          <perfect-scrollbar class="results-list">
            <draggable
              v-model="bannerArray"
              :move="onMove"
              v-bind="$options.DRAG_OPTIONS"
            >
              <transition-group type="transition" name="flip-list">
                <div
                  :class="['list-item']"
                  v-for="(product, index) in bannerArray"
                  :key="'bannerCard_' + index"
                >
                  <div class="card-item">
                    <div class="drag" v-if="product.type == 'banner'"></div>
                    <div
                      :class="[
                        'img',
                        { banner: product.type == 'banner' },
                        {
                          dummy: product.type == 'banner' && product.url == '',
                        },
                      ]"
                    >
                      <img
                        :src="product.url"
                        :alt="product.url"
                        v-if="product.url"
                        @error="replaceByDefault"
                      />
                    </div>
                    <div
                      class="title-container banner"
                      v-if="product.type == 'banner'"
                    >
                      <div class="title">
                        {{
                          $t("asPanel.productCardBanner", {
                            var1: product.index + 1,
                          })
                        }}
                      </div>
                    </div>
                    <div class="title-container" v-else>
                      <div class="title"><div class="value"></div></div>
                      <div class="price"><div class="value"></div></div>
                    </div>
                    <div class="position">
                      <div class="value">{{ index + 1 }}</div>
                    </div>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </perfect-scrollbar>
        </div>
      </div>
      <div class="results" v-else>
        <div
          class="empty"
          v-if="searchWord == '' && VMSelectedProducts.length == 0"
        >
          {{ $t("asPanel.noProductSelected") }}
        </div>
        <div
          class="empty"
          v-else-if="searchWord !== '' && products.length == 0"
        >
          {{ $t("asPanel.noProductsFound") }} "{{ searchWord }}"
        </div>
        <div
          class="results-container"
          v-else-if="searchWord == '' && VMSelectedProducts.length > 0"
        >
          <div class="label">
            {{ $t("asPanel.belowAreShown") }}
          </div>
          <div class="label" v-if="isPinType">
            {{ $t("asPanel.thisProductsCanBeOrdered") }}
          </div>
          <perfect-scrollbar v-if="isPinType" class="results-list">
            <draggable
              v-model="VMSelectedProducts"
              v-bind="$options.DRAG_OPTIONS"
            >
              <transition-group type="transition" name="flip-list">
                <div
                  :class="[
                    'list-item',
                    'selected',
                    { 'is-not-alive': !product.isAlive },
                  ]"
                  v-for="product in VMSelectedProducts"
                  :key="'selectedProduct_' + product.id"
                >
                  <div class="card-item">
                    <div class="drag"></div>
                    <div class="img">
                      <img :src="product.image" :alt="product.title" />
                    </div>
                    <div class="title-container">
                      <div class="title">{{ product.title }}</div>
                      <div class="price">
                        {{
                          $options.setNumberFormat(product.price, 2, ",", ".")
                        }}
                        €
                      </div>
                    </div>
                    <div class="selected"></div>
                    <div
                      class="icon delete"
                      @click.stop="onProductClick(product)"
                    ></div>
                  </div>
                  <div class="desc" v-if="!product.isAlive">
                    {{ $t("asPanel.asFind") }}
                  </div>
                </div>
              </transition-group>
            </draggable>
          </perfect-scrollbar>
          <perfect-scrollbar class="results-list" v-else>
            <div
              :class="[
                'list-item',
                'selected',
                { 'is-not-alive': !product.isAlive },
              ]"
              v-for="product in VMSelectedProducts"
              :key="'selectedProduct_' + product.id"
            >
              <div class="card-item">
                <div class="img">
                  <img :src="product.image" :alt="product.title" />
                </div>
                <div class="title-container">
                  <div class="title">{{ product.title }}</div>
                  <div class="price">
                    {{ $options.setNumberFormat(product.price, 2, ",", ".") }} €
                  </div>
                </div>
                <div class="selected"></div>
                <div class="icon delete" @click="onProductClick(product)"></div>
              </div>
              <div class="desc" v-if="!product.isAlive">
                {{ $t("asPanel.asFind") }}
              </div>
            </div>
          </perfect-scrollbar>
        </div>
        <div class="results-container searching" v-else>
          <div class="top-list">
            <div class="cta">
              <div
                :class="['filters', { opened: showFilters }]"
                @click="showFilters = !showFilters"
              >
                <span class="icon"></span> {{ $t("asPanel.showFilters") }}
              </div>
              <div class="sort">
                <b-select
                  v-model="selectedSort"
                  :options="sortOptions"
                  @change="onSortChange"
                >
                </b-select>
              </div>
            </div>
            <div class="products" v-if="productsCount > 0">
              {{ productsCount }} {{ $t("asPanel.products") }}
            </div>
          </div>
          <perfect-scrollbar
            class="results-list"
            @ps-y-reach-end="onReachEnd"
            ref="ps"
          >
            <div
              :class="[
                'list-item',
                { selected: selectedProductsId.includes(product.id) },
              ]"
              v-for="product in products"
              :key="'product_' + product.id"
              @click="onProductClick(product)"
            >
              <div class="card-item">
                <div class="img">
                  <img :src="product.image" :alt="product.title" />
                </div>
                <div class="title-container">
                  <div class="title">{{ product.title }}</div>
                  <div class="price">
                    {{ $options.setNumberFormat(product.price, 2, ",", ".") }} €
                  </div>
                </div>
                <div class="selected"></div>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
        <div class="bottom" v-if="searchWord !== ''">
          <div class="btn inactive" v-if="VMSelectedProducts.length == 0">
            {{ VMSelectedProducts.length }} {{ $t("asPanel.selectedProducts") }}
          </div>
          <div class="btn" v-else @click="deleteWord">
            {{
              $t("asPanel.showProducts", { var1: VMSelectedProducts.length })
            }}
          </div>
        </div>
      </div>
    </div>
    <div
      :class="['filters-container', { show: showFilters && searchWord !== '' }]"
    >
      <div class="top">
        <div class="icon" @click="showFilters = false"></div>
        {{ $t("asPanel.filterBy") }}
      </div>
      <perfect-scrollbar class="container">
        <div
          :class="['filter', { expanded: openFilters[index] }]"
          v-for="(filterName, index) in Object.keys(filters)"
          :key="filterName"
        >
          <div class="header" @click="() => collapseAction(index)">
            <div class="icon"></div>
            {{ filterName }}
            <span
              v-if="filterName == 'sellingPrice' && isPriceFilterActive"
              @click.stop="
                () => {
                  activeFilters.sellingPrice = { min: 0, max: 0 }
                  onCategoryChange()
                }
              "
            >
              {{ $t("asPanel.clear") }}
            </span>
          </div>
          <b-collapse
            v-model="openFilters[index]"
            v-if="filterName == 'sellingPrice'"
          >
            <div class="container">
              <div class="from">
                <div class="label">{{ $t("asPanel.from") }}</div>
                <b-input
                  :value="
                    $options.setNumberFormat(priceFilterValues[0], 2, ',', '.')
                  "
                  disabled
                >
                </b-input>
              </div>
              <div class="to">
                <div class="label">{{ $t("asPanel.to") }}</div>
                <b-input
                  :value="
                    $options.setNumberFormat(priceFilterValues[1], 2, ',', '.')
                  "
                  disabled
                ></b-input>
              </div>
            </div>
            <vue-slider
              v-model="priceFilterValues"
              :width="200"
              :interval="
                (filters.sellingPrice.max - filters.sellingPrice.min) / 100
              "
              :lazy="true"
              @change="onPriceChange"
              :min="filters.sellingPrice.min"
              :max="filters.sellingPrice.max"
            ></vue-slider>
          </b-collapse>
          <b-collapse v-model="openFilters[index]" v-else>
            <perfect-scrollbar class="values-container">
              <b-form-checkbox-group
                v-model="activeFilters[filterName]"
                @change="onCategoryChange"
              >
                <b-form-checkbox
                  v-for="filter in filters[filterName]"
                  :key="'filter_' + filter"
                  :value="filter"
                >
                  {{ filter.split("/").slice(-1)[0] }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </perfect-scrollbar>
          </b-collapse>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import lodash from "lodash"
import axios from "@/utils/Axios"
import Utils from "@/utils/Utils"

export default {
  name: "ASPanel",
  emptyFilters: { categories: [], sellingPrice: { min: 0, max: 0 } },
  setNumberFormat: Utils.setNumberFormat,
  DRAG_OPTIONS: Utils.DRAG_OPTIONS,
  props: ["showPanel", "actionType", "actions"],
  data() {
    return {
      products: [],
      searchWord: "",
      productsCount: 0,
      activeFilters: lodash.cloneDeep(this.$options.emptyFilters),
      filters: lodash.cloneDeep(this.$options.emptyFilters),
      showFilters: false,
      openCategories: false,
      openPrice: false,
      selectedSort: null,
      offset: 0,
      openFilters: [],
      bannerArray: [],
    }
  },
  computed: {
    ...mapGetters([
      "getVMSelectedProducts",
      "getCurrentConfiguration",
      "getBannerPosition",
    ]),
    isPinType() {
      return this.actionType == "pin"
    },
    VMSelectedProducts: {
      get() {
        return this.getVMSelectedProducts
      },
      set(value) {
        this.setVMSelectedProducts(value)
      },
    },
    bannerPosition: {
      get() {
        return this.getBannerPosition
      },
      set(value) {
        this.setBannerPosition(value)
      },
    },
    selectedProductsId() {
      return this.VMSelectedProducts.map((product) => product.id)
    },
    sortOptions() {
      return this.getCurrentConfiguration.layout.sort.values
        .filter((sort) => sort.isDefault)
        .map((sort) => {
          return {
            value: {
              key: sort.key,
              value: sort.value,
            },
            text: sort.label,
          }
        })
    },
    facets() {
      return this.getCurrentConfiguration.layout.facets
        .filter(
          (facet) => facet.name == "categories" || facet.name == "sellingPrice"
        )
        .map((facet) => {
          return {
            value: facet.name,
            text: facet.label,
          }
        })
    },
    priceFilterValues: {
      get() {
        var valueToReturn =
          this.isPriceFilterActive &&
          this.filters.sellingPrice.min <=
            this.activeFilters.sellingPrice.min &&
          this.filters.sellingPrice.max >= this.activeFilters.sellingPrice.max
            ? lodash.cloneDeep(this.activeFilters.sellingPrice)
            : lodash.cloneDeep(this.filters.sellingPrice)
        return [valueToReturn.min, valueToReturn.max]
      },
      set(value) {
        if (
          value ==
          [this.filters.sellingPrice.min, this.filters.sellingPrice.max]
        )
          this.activeFilters.sellingPrice = {
            min: 0,
            max: 0,
          }
        else
          this.activeFilters.sellingPrice = {
            min: value[0],
            max: value[1],
          }
      },
    },
    isPriceFilterActive() {
      return this.activeFilters.sellingPrice.max > 0
    },
  },
  methods: {
    ...mapActions([
      "setASPanelShow",
      "setVMSelectedProducts",
      "setBannerPosition",
    ]),
    closePanel() {
      this.$emit("closeASPanel")
      this.offset = 0
      if (this.actionType == "bannerCard")
        this.bannerArray.forEach((item, index) => {
          if (item.type == "banner") this.actions[item.index].position = index
        })
    },
    onProductClick(product) {
      var selectedProducts = lodash.cloneDeep(this.VMSelectedProducts)
      var index = this.selectedProductsId.indexOf(product.id)
      if (index == -1) {
        product.isAlive = true
        selectedProducts.push(product)
      } else selectedProducts.splice(index, 1)
      this.VMSelectedProducts = selectedProducts
    },
    onInputKeyUp() {
      this.activeFilters = lodash.cloneDeep(this.$options.emptyFilters)
      this.offset = 0
      this.setValues()
    },
    onSortChange() {
      this.offset = 0
      this.setValues(false)
    },
    onReachEnd() {
      this.offset += 10
      this.addProducts()
    },
    replaceByDefault(e) {
      e.target.src = require("@/assets/icons/img-not found.svg")
    },
    getASUrl() {
      var urlToReturn =
        "shops/" +
        this.getCurrentConfiguration.key +
        "/visual-machine/search?customer_group_id=default&currency=EUR"
      urlToReturn +=
        "&sort[0][" + this.selectedSort.key + "]=" + this.selectedSort.value
      Object.keys(this.activeFilters).forEach((filterName, index) => {
        if (filterName == "sellingPrice" && this.isPriceFilterActive)
          urlToReturn +=
            "&filters[" +
            index +
            "][0][sellingPrice][from]=" +
            this.activeFilters.sellingPrice.min +
            "&filters[" +
            index +
            "][0][sellingPrice][to]=" +
            this.activeFilters.sellingPrice.max
        else if (this.activeFilters[filterName].length > 0)
          urlToReturn +=
            "&filters[" +
            index +
            "][][" +
            filterName +
            "]=" +
            encodeURIComponent(this.activeFilters[filterName][0])
      })
      urlToReturn += "&offset=" + this.offset
      urlToReturn += "&q=" + this.searchWord
      return urlToReturn
    },
    async setValues(changePrice = true) {
      if (this.$refs.ps) this.$refs.ps.$el.scrollTop = 0
      var ASUrl = this.getASUrl()
      await axios.get(ASUrl).then((response) => {
        this.productsCount = response.data.header.results_count
        if (this.productsCount > 0) {
          var filtersName = Object.keys(response.data.stats).filter(
            (name) => name !== "sellingPrice" && name !== "listingPrice"
          )
          var filterSellingPrice = this.filters.sellingPrice
          var filtersTemp = {}
          filtersName.forEach((filterName) => {
            filtersTemp[filterName] = Object.keys(
              response.data.stats[filterName]
            )
          })
          filtersTemp.sellingPrice = filterSellingPrice
          this.filters = filtersTemp
          // this.openFilters=Object.keys(this.filters).map(()=> false)
          if (changePrice)
            this.filters.sellingPrice = response.data.stats.sellingPrice
          else if (this.isPriceFilterActive)
            this.activeFilters.sellingPrice = response.data.stats.sellingPrice
          this.products = response.data.data.map((product) => {
            return {
              title: product.data.title,
              image: product.data.image[0].replace(
                "{:image}",
                response.data.header.imagePrefix
              ),
              id: product.header.externalId,
              price: product.pricing.sellingPrice,
              url: product.data.url,
            }
          })
        } else {
          this.resetFilters()
        }
      })
    },
    async addProducts() {
      var ASUrl = this.getASUrl()
      await axios.get(ASUrl).then((response) => {
        this.products = [
          ...this.products,
          ...response.data.data.map((product) => {
            return {
              title: product.data.title,
              image: product.data.image[0].replace(
                "{:image}",
                response.data.header.imagePrefix
              ),
              id: product.header.externalId,
              price: product.pricing.sellingPrice,
              url: product.data.url,
            }
          }),
        ]
      })
    },
    onCategoryChange() {
      this.offset = 0
      this.setValues()
    },
    onPriceChange() {
      this.offset = 0
      this.setValues(false)
    },
    deleteWord() {
      this.searchWord = ""
      this.resetFilters()
    },
    resetFilters() {
      this.showFilters = false
      this.filters = lodash.cloneDeep(this.$options.emptyFilters)
      this.activeFilters = lodash.cloneDeep(this.$options.emptyFilters)
      this.products = []
    },
    collapseAction(index) {
      var tempCollapse = lodash.cloneDeep(this.openFilters)
      tempCollapse[index] = !this.openFilters[index]
      this.openFilters = tempCollapse
    },
    onMove({ draggedContext }) {
      const draggedElement = draggedContext.element
      return draggedElement.type == "banner"
    },
    // onEnd(evt){
    //     if(evt.item._underlying_vm_.type=='banner') this.setBannerPosition(evt.newIndex);
    // },
    initializeList() {
      this.bannerArray = Array.from({ length: 20 }, () => ({ type: "sample" }))
      this.actions.forEach((action, index) => {
        if (action.type == "bannerCard")
          this.bannerArray[action.position] = {
            type: "banner",
            url: action.imageUrl,
            index: index,
          }
      })
    },
  },
  mounted() {
    this.selectedSort = this.sortOptions[0].value
  },
}
</script>

<style src="./ASPanel.less" lang="less" scoped></style>
