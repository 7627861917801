<template>
  <div class="analytics">
    <div class="overheader">
      <div class="title">{{ $t("menu.dashboard") }}</div>
      <div class="device-selector">
        <div class="label">{{ $t("analytics.filter") }}</div>
        <div class="device-selector-container">
          <div
            :class="[
              'device-item',
              { selected: device == getAnalyticsSelectedDevice },
            ]"
            v-for="device in $options.DEVICES"
            :key="device"
            @click="() => setAnalyticsDevice(device)"
          >
            {{ $t("analytics." + device) }}
          </div>
        </div>
      </div>
      <div class="date-picker">
        <div class="label">{{ $t("analytics.timeframe") }}</div>
        <Datepicker
          v-model="date"
          opens="left"
          :ranges="ranges"
          :locale-data="locale"
          :min-date="minDate"
          :max-date="maxDate"
        />
      </div>
    </div>
    <Header />
    <router-view />
  </div>
</template>

<script>
import Header from "@/components/Header/Header";
import { mapGetters, mapActions } from "vuex";
import Utils from "@/utils/Utils";

export default {
  name: "Analytics",
  DEVICES: Utils.DEVICES,
  components: { Header },
  data() {
    return {
      locale: {
        applyLabel: this.$t("analytics.setTimeRange"),
        cancelLabel: this.$t("analytics.cancel"),
        format: this.$i18n.locale == "it" ? "dd/mm/yyyy" : "mm/dd/yyyy",
      },
      minDate: new Date("2023-06-15"),
      maxDate: new Date().setDate(new Date().getDate() - 1),
      info: {
        totalSearches: 0,
        totalProductClicks: 0,
        noResultsQueries: [],
        mostSearchedQueries: [],
        mostClickedProducts: [],
      },
    };
  },
  methods: {
    ...mapActions(["setAnalyticsSelectedDate", "setAnalyticsSelectedDevice"]),
    setAnalyticsDevice(device) {
      this.setAnalyticsSelectedDevice(device);
      this.$root.$emit("update-analytics");
    },
  },
  computed: {
    ...mapGetters(["getAnalyticsSelectedDate", "getAnalyticsSelectedDevice"]),
    date: {
      set(value) {
        this.setAnalyticsSelectedDate(value);
        this.$root.$emit("update-analytics");
      },
      get() {
        return this.getAnalyticsSelectedDate;
      },
    },
    ranges() {
      var rangesToReturn = {};
      var today = new Date(new Date().setHours(0, 0, 0, 0));
      var midnight = new Date();
      midnight.setDate(today.getDate() - 1);
      midnight.setHours(23, 59, 59, 0);
      var yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);
      rangesToReturn[this.$t("analytics.yesterday")] = [yesterday, midnight];
      var lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);
      rangesToReturn[this.$t("analytics.pastWeek")] = [lastWeek, midnight];
      var lastMonth = new Date();
      lastMonth.setMonth(today.getMonth() - 1);
      lastMonth.setHours(0, 0, 0, 0);
      rangesToReturn[this.$t("analytics.pastMonth")] = [lastMonth, midnight];
      var last3Month = new Date();
      last3Month.setMonth(today.getMonth() - 3);
      last3Month.setHours(0, 0, 0, 0);
      rangesToReturn[this.$t("analytics.past3Month")] = [last3Month, midnight];
      var last6Month = new Date();
      last6Month.setMonth(today.getMonth() - 6);
      last6Month.setHours(0, 0, 0, 0);
      rangesToReturn[this.$t("analytics.past6Month")] = [last6Month, midnight];
      var lastYear = new Date();
      lastYear.setFullYear(lastYear.getFullYear() - 1);
      lastYear.setHours(0, 0, 0, 0);
      rangesToReturn[this.$t("analytics.pastYear")] = [lastYear, midnight];
      return rangesToReturn;
    },
  },
};
</script>

<style src="./Analytics.less" lang="less" scoped></style>
