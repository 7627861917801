<template>
  <div class="login">
    <div class="label">{{ $t("home.login.accountLogin") }}</div>
    <b-form-input
      class="usr"
      placeholder="example@email.com"
      :state="emailState ? EMAIL_REGEX.test(mail) : null"
      v-model="mail"
      @change="() => (emailState = true)"
    />
    <div
      :class="['eye', { show: pwdVisibility }]"
      @click="pwdVisibility = !pwdVisibility"
    ></div>
    <b-form-input
      :type="pwdVisibility ? 'password' : 'text'"
      class="pwd"
      :placeholder="$t('home.login.password')"
      v-model="password"
      @change="() => (pwdState = true)"
    />
    <div class="error" v-if="pwdState && !PWD_REGEX.test(password)">
      {{ $t("home.login.passwordContain") }}
    </div>
    <div class="forgot-pwd">
      <router-link to="/forgot-password">
        {{ $t("home.login.forgotPassword") }}
      </router-link>
    </div>
    <vue-recaptcha
      ref="recaptcha"
      class="recaptcha"
      @verify="onVerify"
      sitekey="6LeWbjQeAAAAANjAqehyt3UMSOXMTMI-5oevq4ZI"
    >
    </vue-recaptcha>
    <b-button class="cta" @click="loginAction" :disabled="disabled">
      {{ $t("home.login.login") }}
    </b-button>
    <div class="register">
      {{ $t("home.login.noAccount") }}
      <router-link to="/signup"> {{ $t("home.login.signUp") }} </router-link>
    </div>
  </div>
</template>

<script>
import Utils from "@/utils/Utils";
import axios from "@/utils/Axios";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "Login",
  data() {
    return {
      EMAIL_REGEX: Utils.EMAIL_REGEX,
      PWD_REGEX: Utils.PWD_REGEX,
      mail: "",
      password: "",
      emailState: false,
      pwdState: false,
      pwdVisibility: true,
      isRobot: Utils.isLocalhost() || Utils.isDev() ? false : true,
    };
  },
  computed: {
    disabled() {
      return (
        !Utils.EMAIL_REGEX.test(this.mail) ||
        !Utils.PWD_REGEX.test(this.password) ||
        this.isRobot
      );
    },
  },
  methods: {
    async loginAction() {
      if (!this.isRobot) {
        var loginData = { username: this.mail, password: this.password };
        axios.post(Utils.SESSION, loginData).then(async (response) => {
          if (response.data.status !== Utils.ERROR && response.data.api_key) {
            window.localStorage.setItem("token", response.data.jwt);
            window.localStorage.setItem("apikey", response.data.api_key);
            this.$router.push({ name: "analytics" });
          } else if (!response.data.api_key)
            this.$bvToast.toast(
              this.$t(
                "home.login." +
                  Utils.API_RESPONSE_CODES[response.data.code].message
              ),
              {
                title: this.$t(
                  "home.login." +
                    Utils.API_RESPONSE_CODES[response.data.code].title
                ),
                autoHideDelay: 2000,
                solid: true,
                variant: "danger",
              }
            );
          else
            this.$bvToast.toast(this.$t("home.login.tryAgain"), {
              title: "",
              autoHideDelay: 2000,
              solid: true,
              variant: "danger",
            });
        });
      } else {
        this.$bvToast.toast("You are a robot", {
          title: "",
          autoHideDelay: 2000,
          solid: true,
          variant: "danger",
        });
      }
    },
    onVerify: function(response) {
      if (response) this.isRobot = false;
    },
  },
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
};
</script>

<style src="./Login.less" lang="less" scoped></style>
