<template>
  <div id="app">
    <b-modal id="info" hide-footer hide-header size="lg" v-model="modalInfo">
      <div class="title">{{ $t("home.signup.modalInfoTitleTerms") }}</div>
      <div class="close" @click="modalInfo = false"></div>
      <perfect-scrollbar class="infoContent">
        <TermsConditions />
      </perfect-scrollbar> 
      <div class="button-container">
        <b-button class="button" @click="modalInfo = false">
          {{ $t("home.signup.modalInfoConfirm") }}
        </b-button>
      </div>
    </b-modal>
    <ChangesModal />  
    <NotificationsModal v-if="getNotificationModalVisible" />
    <NotificationDetailModal v-if="getNotificationDetailModalVisible" />
    <Menu v-if="!isAuth && !isPreview" />
    <SaveChanges v-if="!isAuth && !isWizard && !isPreview && !isBilling && !isAnalytics" />
    <div class="content">
      <router-view />
    </div>
    <div class="footer" v-if="!isAuth && !isPreview">
      <div class="rights"> {{ $t("footer.rights") }}</div>
      <div class="privacy"><a :href="$i18n.locale=='it'?'https://accelasearch.com/privacy-policy/':'https://accelasearch.com/en/privacy-policy/'" target="_blank"> {{ $t("footer.privacy") }} </a></div>
      <div class="terms" @click="modalInfo = true"> {{ $t("footer.terms") }} </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu/Menu";
import Utils from "@/utils/Utils";
import SaveChanges from "@/components/SaveChanges/SaveChanges";
import ChangesModal from "@/components/ChangesModal/ChangesModal";
import NotificationsModal from "@/components/NotificationsModal/NotificationsModal";
import NotificationDetailModal from "@/components/NotificationDetailModal/NotificationDetailModal";
import TermsConditions from "@/components/TermsConditions/TermsConditions";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    Menu,
    SaveChanges,
    ChangesModal,
    NotificationsModal,
    TermsConditions,
    NotificationDetailModal
  },
  data() {
    return {
      modalInfo: false,
    };
  },
  computed: {
    ...mapGetters(["getNotificationModalVisible",'getNotificationDetailModalVisible', "getNotifications"]),
    getNotificationToRead() {
      return this.getNotifications.filter((notification) => !notification.readAt);
    },
    // isAuth(){ return this.$route.path==='/' || this.$route.path==='/signup' || this.$route.path==='/activation' || this.$route.path==='/activation-mail'},
    isAuth() {
      return Utils.isHomeRoute(this.$route.name);
    },
    isWizard() {
      return this.$route.matched[0]?.name === "wizard";
    },
    isBilling() {
      return this.$route.matched[0]?.name === "billing";
    },
    isAnalytics() {
      return this.$route.matched[0]?.name === "analytics";
    },
    isPreview() {
      return this.$route.path === "/preview";
    },
  },
};
</script>

<style lang="less" src="./App.less"></style>
