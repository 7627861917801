<template>
  <div class="menu">
    <div class="header" @click="$router.push({ name: 'analytics' })"></div>
    <perfect-scrollbar class="container">
      <div
        :class="['current-website', { open: getOpenedWebsitesList }]"
        @click="() => setOpenedWebsitesList(!getOpenedWebsitesList)"
        v-if="getDisplayedWebsites.length > 0"
        id="spinner-indexing"
      >
        <!-- <div class="icon"> </div> -->
        <div class="value">
          {{
            getCurrentConfiguration.language_iso
              ? getCurrentConfiguration.language_iso.toUpperCase() + " - "
              : ""
          }}
          {{ beautifyWebsite }}
          <b-spinner class="busy" v-if="isBusy"></b-spinner>
        </div>
        <!-- <div class="value"> {{getCurrentConfiguration.language_iso?fromCodeToEmoji(getCurrentConfiguration.language_iso.toLowerCase())+" - ":""}} {{beautifyWebsite}} <b-spinner class="busy" v-if="isBusy"></b-spinner> </div> -->
        <b-tooltip target="spinner-indexing" triggers="hover" v-if="isBusy">
          {{ $t("menu.indexing") }}
        </b-tooltip>
        <div :class="['arrow', { open: getOpenedWebsitesList }]"></div>
      </div>
      <div
        class="sub-menu websites"
        v-if="getDisplayedWebsites.length > 0 && getOpenedWebsitesList"
      >
        <div
          v-for="website in activeWebsitesWithoutCurrentWebsite"
          class="website"
          :key="'website_' + website.url"
          @click="() => websiteClick(website)"
        >
          {{
            website.language_iso
              ? website.language_iso.toUpperCase() + " - "
              : ""
          }}
          {{ $options.beautifyWebsite(website.url) }}
        </div>
        <!-- <router-link to="/wizard" class="website wizard">{{getCurrentConfiguration.syncing?'Syncing...':'New Wizard'}} </router-link>  -->
        <router-link to="/wizard" class="website wizard">
          {{ $t("menu.addNewShop") }}
        </router-link>
      </div>
      <div
        class="sub-menu"
        v-if="getDisplayedWebsites.length > 0 && !showWizardState"
      >
        <!-- <div> <router-link to="/dashboard" class="dashboard"> <div class="icon"></div> {{$t('menu.dashboard')}} </router-link> </div> -->

        <div>
          <router-link to="/analytics" class="dashboard">
            <div class="icon"></div>
            {{ $t("menu.dashboard") }}
          </router-link>
        </div>
        <div>
          <router-link to="/layoutDesign" class="layout-design">
            <div class="icon"></div>
            {{ $t("menu.layoutDesign") }}
          </router-link>
        </div>
        <div>
          <router-link to="/fineTuning" class="fineTuning">
            <div class="icon"></div>
            {{ $t("menu.fineTuning") }}
          </router-link>
        </div>
        <div>
          <router-link
            to="/visualMachine"
            :class="[
              'visualMachine',
              {
                disabled: !$options.isStandardOrMore(
                  Object.values(getSelectedPlan)[0].key
                ),
              },
            ]"
          >
            <div class="icon"></div>
            {{ $t("menu.visualMachine") }}
          </router-link>
        </div>
        <div>
          <router-link to="/setup" class="setup">
            <div class="icon"></div>
            {{ $t("menu.setup") }}
          </router-link>
        </div>
      </div>
      <div v-if="getDisplayedWebsites.length == 0" class="sub-menu">
        <router-link to="/wizard" class="wizard"
          >{{
            getCurrentConfiguration.syncing
              ? $t("menu.syncing")
              : $t("menu.newWizard")
          }}
          <span :class="['state' + completed]"
        /></router-link>
      </div>
      <div class="divider"></div>
      <div>
        <router-link to="/account" class="account">
          {{ $t("menu.accountSettings") }}
        </router-link>
      </div>
      <div>
        <a class="notifications" @click="openNotificationModal">
          {{ $t("menu.notifications") }}
          <span :class="{ active: getNewNotificationCounter > 0 }">
            {{
              getNewNotificationCounter > 9 ? "9+" : getNewNotificationCounter
            }}
          </span>
        </a>
      </div>
      <div v-if="isShopify == false">
        <router-link
          to="/billing"
          :class="['billing', { 'with-icon': checkInfo }]"
        >
          {{ $t("menu.billingPayments") }} <span class="icon"></span>
        </router-link>
      </div>
      <div>
        <a @click="logoutAction" class="logout"> {{ $t("menu.logout") }} </a>
      </div>
      <div class="version">{{ version }}</div>
    </perfect-scrollbar>
    <RecapSquare type="menu" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import RecapSquare from "@/components/RecapSquare/RecapSquare"
import Utils from "@/utils/Utils"
import axios from "@/utils/Axios"
import lodash from "lodash"
import metadata from "@/metadata.json"
import emojiMap from "emoji-name-map"

export default {
  name: "Menu",
  beautifyWebsite: Utils.beautifyWebsite,
  isDev: Utils.isDev,
  isLocalhost: Utils.isLocalhost,
  isStandardOrMore: Utils.isStandardOrMore,
  components: { RecapSquare },
  data() {
    return {
      isBusy: false,
    }
  },
  methods: {
    ...mapActions([
      "setCurrentConfiguration",
      "setOpenedWebsitesList",
      "setWebsites",
      "setShowSaveConfigModal",
      "setHistoryChanges",
      "setNotificationModalVisible",
      "setNotificationInterval",
      "setNotifications",
      "setPendingWebsite",
    ]),
    logoutAction() {
      window.localStorage.removeItem("token")
      window.localStorage.removeItem("apikey")
      this.$router.push({ name: "login" })
    },
    async websiteClick(website) {
      if (this.getSelectedCampaign)
        this.$root.$emit("before-change-website", website)
      else if (this.getHistoryChanges.length > 0) {
        this.setShowSaveConfigModal(true)
        this.setPendingWebsite(website)
      } else {
        this.setOpenedWebsitesList(false)
        await this.setCurrentConfiguration(
          lodash.clone(
            Utils.getConfigurationFromWebsite(
              this.getDisplayedWebsites,
              website
            )
          )
        )
        await axios.get(Utils.SHOPS).then((response) => {
          this.setWebsites(response.data)
        })
        if (Utils.checkConfiguring(website))
          this.$router.push({ name: "wizard-settings" })
        else {
          if (this.showWizardState) this.$router.push({ name: "dashboard" })
          this.$root.$emit("changed-website")
          this.$root.$emit("check-sync")
          this.checkBusy()
        }
      }
    },
    async openNotificationModal() {
      await axios
        .get(
          Utils.NOTIFICATIONS +
            "?" +
            Utils.FROM +
            "=0&" +
            Utils.SIZE +
            "=" +
            Utils.NOTIFICATION_PAGE_SIZE
        )
        .then((response) => {
          this.setNotifications(response.data)
        })
      clearInterval(this.getNotificationInterval)
      this.setNotificationInterval(-1)
      this.setNotificationModalVisible(true)
    },
    async checkBusy() {
      await axios
        .get(Utils.SHOPS + "/" + this.getCurrentConfiguration.id + Utils.STATUS)
        .then((response) => {
          if (response.data.isIndexing) {
            this.isBusy = true
            setTimeout(this.checkBusy, 5000)
          } else {
            this.isBusy = false
            this.$root.$emit("not-busy")
          }
        })
    },
    fromCodeToEmoji(lang) {
      return emojiMap.get(":" + lang + ":")
    },
  },
  computed: {
    ...mapGetters([
      "getSelectedPlan",
      "getBillingInfo",
      "getCurrentConfiguration",
      "getOpenedWebsitesList",
      "getWebsites",
      "getDisplayedWebsites",
      "getDisplayedWebsites",
      "getShowSaveConfigModal",
      "getNewNotificationCounter",
      "getNotificationInterval",
      "getSelectedCampaign",
      "getHistoryChanges",
      "getUserInfo",
    ]),
    checkInfo() {
      return (
        Utils.checkBillingInfo(this.getBillingInfo) ||
        !Object.values(this.getSelectedPlan)[0].isActive ||
        this.getBillingInfo.isDeactivating
      )
    },
    isShopify() {
      const cmsName = this.getCurrentConfiguration?.cms?.name

      return cmsName?.toLowerCase() === "shopify"
    },
    completed() {
      // DA VERIFICARE
      if (this.$route.name == "wizard-start") return 0
      if (this.$route.name == "wizard-cms") return 1
      if (
        this.$route.name == "wizard-shop" &&
        !this.getCurrentConfiguration.syncing
      )
        return 2
      if (
        this.$route.name == "wizard-shop" &&
        this.getCurrentConfiguration.syncing
      )
        return 3
      return 4
    },
    version() {
      return (
        this.$t("menu.version") +
        metadata.buildMajor +
        "." +
        metadata.buildMinor +
        "." +
        metadata.buildRevision
      )
    },
    // isTestMail(){
    //   return this.getUserInfo.email.includes('marco.macari+abtest') && this.getUserInfo.email.includes('@accelasearch.com')
    // },
    // getNotificationToRead(){return this.getNotifications.filter(notification=>!notification.readAt)},
    beautifyWebsite() {
      return this.getCurrentConfiguration.url
        ? Utils.beautifyWebsite(this.getCurrentConfiguration.url)
        : this.$t("menu.noSyncedShop")
    },
    activeWebsitesWithoutCurrentWebsite() {
      return this.getDisplayedWebsites.filter(
        (website) => website.id !== this.getCurrentConfiguration.id
      )
    }, //possible multishop bug
    showWizardState() {
      return this.$route.matched[0]?.name == "wizard"
    },
  },
  mounted() {
    this.checkBusy()
    this.$root.$on("check-indexing", this.checkBusy)
    this.$root.$on("change-website", this.websiteClick)
  },
}
</script>

<style src="./Menu.less" lang="less" scoped></style>
