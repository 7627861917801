<template>
  <b-modal id="fine-tuning-modal" hide-footer hide-header size="lg" @show="resetInfo" @hidden="valueToAdd=''"> 
    <div class="close" @click="()=>$bvModal.hide('fine-tuning-modal')"></div>
    <div class="title"> {{getFineTuningModal.title}} </div>
    <div class="desc"> {{getFineTuningModal.desc}} 
    </div>
    <div class="input-container">
      <div class="left">
        <b-input :placeholder="getFineTuningModal.placeholder" :state="canAdd(valueToAdd)" maxlength="30" :value="valueToAdd" @keyup="addItem" class="input"> </b-input>
        <div class="sub-input-error" v-if="!canAdd(valueToAdd)"> {{getFineTuningModal.type=='ips'?$t('fineTuningModal.noIp'):$t('fineTuningModal.noWords')}} </div>
        <div class="sub-input"> {{$t('fineTuningModal.pressEnter')}} </div>
      </div>
      <div class="right">
        <b-input v-if="getFineTuningModal.type=='stopwords'" maxlength="30" :placeholder="$t('fineTuningModal.searchAmong',{var1:getFineTuningModal.list.length})" :value="filter" @input="setFilter" class="input">  </b-input>
        <b-input v-else-if="getFineTuningModal.type=='synonyms'" maxlength="30" :placeholder="$t('fineTuningModal.filterAmong',{var1:getFineTuningModal.list.length})" :value="filter" @input="setFilter" class="input">  </b-input>
      </div>
    </div>
    <perfect-scrollbar class='list synonyms' v-if="getFineTuningModal.type=='synonyms'">
      <div :class="['list-item','collapse',{collapsed:collapse[index]}]" v-for="(item,index) in filteredSynonyms" :key="'list_item_'+index">
        <div class="info-container" @click="()=>collapseAction(index)">
          <div class="collapsed-container">
            <div class="expander" @click.stop="()=>collapseAction(index)" v-if="collapse[index]"> + {{$t('fineTuningModal.addSynonym')}} </div>
            <div class="input-container">
              <b-input :placeholder="'+ '+$t('fineTuningModal.addSynonym')" :state="state[index]" @keyup="(synonym)=>addSynonym(synonym,index)" class="input" @click.stop="" maxlength="30"> </b-input>
              <div class="sub-input-error" v-if="!state[index]"> {{$t('fineTuningModal.noWords')}} </div>
              <div class="sub-input"> {{$t('fineTuningModal.pressEnter')}} </div>
            </div>
            <div class="list-container">
              <div class="list-item" v-for="(subItem,subIndex) in item" :key="'synonym_'+subIndex" @click.stop="">
                {{subItem}}
                <!-- <span class="remove" @click="()=>removeSynonym(index,subIndex)"></span> -->
                <span class="remove" @click="()=>removeSynonymFromArray(item,subIndex)"></span>
              </div>
            </div>
            <div class="remove-group" v-if="!showConfirm" @click.stop=""> 
            <div class="remove" @click.stop="()=>showConfirm=true" v-if="!showConfirm"> {{$t('fineTuningModal.deleteGroup')}} </div>
            </div>
            <div class="remove-group" v-else @click.stop=""> 
              <div class="title"> {{$t('fineTuningModal.deleteGroup')}} </div>
              <div class="label"> {{$t('fineTuningModal.areSure')}} </div>
              <div class="cta-container">
                <!-- <div class="yes" @click.stop="()=>removeItem(index)"> {{$t('fineTuningModal.yes')}} </div> -->
                <div class="yes" @click.stop="()=>removeItemFromArray(item)"> {{$t('fineTuningModal.yes')}} </div>
                <div class="no" @click.stop="()=>showConfirm=false"> {{$t('fineTuningModal.no')}} </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <perfect-scrollbar class='list' v-else>
      <div class="list-item" v-for="(item,index) in filteredList" :key="'list_item_'+index" >
        <div class="info-container">
          {{item}}
          <!-- <div class="remove" @click="()=>removeItem(index)" > {{$t('fineTuningModal.remove')}} </div> -->
          <div class="remove" @click="()=>removeItemFromArray(item)" > {{$t('fineTuningModal.remove')}} </div>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="footer">
      <div class="btn-container">
        <button class="btn" @click="clickBtn" > {{$t('fineTuningModal.confirm')}} </button>
      </div>
    </div>  
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import lodash from 'lodash'
import Utils from '@/utils/Utils'

export default {
  name: 'FineTuningModal',
  IP_REGEX:Utils.IP_REGEX,
  data(){
    return {
      weights:{},
      selected:{},
      filter:'',
      valueToAdd:'',
      collapse:[],
      showConfirm:false,
      state:[]
    }
  },
  computed:{
    ...mapGetters(['getFineTuningModal','getCurrentConfiguration','getWebsiteAttributes','getHistoryChanges']),
    filteredList(){ return this.getFineTuningModal.list.filter(item=>item.includes(this.filter)) },
    filteredSynonyms(){ return this.getFineTuningModal.list.filter(array=>array.some(subArray=>subArray.includes(this.filter))) },
  },
  methods:{
    ...mapActions(['setFineTuningModal','setCurrentConfigurationByField','setHistoryChanges']),
    setFilter(value){
      this.filter=value;
    },
    clickBtn(){
      this.$bvModal.hide('fine-tuning-modal');
    },
    saveInfo(){
      var historyChanges=lodash.cloneDeep(this.getHistoryChanges)
      historyChanges.push(lodash.cloneDeep(this.getCurrentConfiguration));
      this.setHistoryChanges(historyChanges);
      var valueToSave=lodash.cloneDeep(this.getCurrentConfiguration.fineTuning);
      valueToSave[this.getFineTuningModal.type]=lodash.cloneDeep(this.getFineTuningModal.list);
      this.setCurrentConfigurationByField({field:'fineTuning',value:valueToSave})
    },
    addItem(item){
      this.valueToAdd=item.target.value;
      var tempFineTuningModal={};
      this.filter='';
      if(this.getFineTuningModal.type!=='synonyms' && item.key=='Enter' && item.target.value!='' && this.canAdd(item.target.value)){
        tempFineTuningModal=lodash.cloneDeep(this.getFineTuningModal);
        tempFineTuningModal.list.push(item.target.value);
        this.setFineTuningModal(tempFineTuningModal);
        item.target.value='';
        this.saveInfo();
        this.valueToAdd='';
      } else if(this.getFineTuningModal.type=='synonyms' && item.key=='Enter' && item.target.value!='' && this.canAdd(item.target.value)){
        this.collapseAll();
        tempFineTuningModal=lodash.cloneDeep(this.getFineTuningModal);
        tempFineTuningModal.list.unshift([item.target.value]);
        this.state.unshift(true);
        this.setFineTuningModal(tempFineTuningModal);
        this.collapse.unshift(false);
        this.saveInfo();
        item.target.value='';
        this.valueToAdd='';
      }
    },
    canAdd(value){
      if(value.split(" ").length>1) return false
      if(this.getFineTuningModal.type==='ips') return !this.getFineTuningModal.list.includes(value) && Utils.IP_REGEX.test(value) || value==""
      if(this.getFineTuningModal.type=='stopwords') return !this.getFineTuningModal.list.includes(value)
      return !this.getFineTuningModal.list.some(array=>array.includes(value))
    },
    removeItemFromArray(item){
      this.showConfirm=false;
      var tempFineTuningModal=lodash.cloneDeep(this.getFineTuningModal);
      var index=lodash.findIndex(tempFineTuningModal.list,(listItem)=>lodash.isEqual(listItem,item));
      tempFineTuningModal.list.splice(index,1);
      this.setFineTuningModal(tempFineTuningModal);
      this.saveInfo();
    },
    removeItem(index){
      this.showConfirm=false;
      var tempFineTuningModal=lodash.cloneDeep(this.getFineTuningModal);
      tempFineTuningModal.list.splice(index,1);
      this.setFineTuningModal(tempFineTuningModal);
      this.saveInfo();
    },
    addSynonym(synonym,index){
      var tempState=lodash.cloneDeep(this.state);
      tempState[index]=this.canAdd(synonym.target.value);
      this.state=tempState;
      if(synonym.key=='Enter' && synonym.target.value!=''  && this.canAdd(synonym.target.value) /* !this.getFineTuningModal.list[index].includes(synonym.target.value) */ ){
        var tempFineTuningModal=lodash.cloneDeep(this.getFineTuningModal);
        tempFineTuningModal.list[index].push(synonym.target.value);
        this.setFineTuningModal(tempFineTuningModal);
        synonym.target.value='';
        this.saveInfo();
      }
    },
    removeSynonym(index,subIndex){
      this.showConfirm=false;
      var tempFineTuningModal=lodash.cloneDeep(this.getFineTuningModal);
      tempFineTuningModal.list[index].splice(subIndex,1);
      this.setFineTuningModal(tempFineTuningModal);
      this.saveInfo();
      if(tempFineTuningModal.list[index].length==0) this.removeItem(index);
    },
    removeSynonymFromArray(item,subIndex){
      this.showConfirm=false;
      var tempFineTuningModal=lodash.cloneDeep(this.getFineTuningModal);
      var index=lodash.findIndex(tempFineTuningModal.list,(listItem)=>lodash.isEqual(listItem,item));
      tempFineTuningModal.list[index].splice(subIndex,1);
      this.setFineTuningModal(tempFineTuningModal);
      this.saveInfo();
      if(tempFineTuningModal.list[index].length==0) this.removeItem(index);
    },
    collapseAction(index){
      var tempValue=this.collapse[index];
      this.collapseAll();
      var tempCollapse=lodash.cloneDeep(this.collapse);
      tempCollapse[index]=!tempValue;
      this.collapse=tempCollapse;
    },
    collapseAll(){
      this.collapse=[];
      this.showConfirm=false;
      this.getFineTuningModal.list.forEach(()=>{
        this.collapse.push(true)
      })
    },
    resetInfo(){
      this.collapseAll();
      this.filter="";
      this.state=[];
      this.getFineTuningModal.list.forEach(()=>{
        this.state.push(true)
      })
    }
  },
}
</script>

<style src="./FineTuningModal.less" lang="less" scoped></style>