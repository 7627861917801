import Vue from 'vue'
import App from '@/App.vue'
import Utils from '@/utils/Utils'
import router from '@/router'
import store from '@/store'
import i18n from "./i18n";
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import ToggleButton from 'vue-js-toggle-button';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import Draggable from 'vuedraggable'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { StripePlugin } from '@vue-stripe/vue-stripe';
import { Chrome } from 'vue-color';
import VueApexCharts from 'vue-apexcharts'

const optionsDev = { pk: "pk_test_51JgBL4EFAK5wRRkQuwiU6HyMunX5mAVL2sOajH1jlS1Hn0lU7HuKpK6R59JcQqSUmK8wQ67gDVt1I62v8e0iBuSP00iKt4Urr0" };
const options = { pk: "pk_live_51JgBL4EFAK5wRRkQo2t9bZuGlhjt0PA4JyV9ZxbbD0dD8aNTJXUitvtJLnDENmXwE8WlZcoIgXjkfj7KGdf1A2sz00jiskm3Ba" };

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
Vue.component('VueSlider', VueSlider)
Vue.component('draggable', Draggable)
Vue.component('Datepicker', DateRangePicker);
Vue.component('color-picker', Chrome);
Vue.use(ToggleButton);
Vue.use(StripePlugin, Utils.isDev() || Utils.isLocalhost()?optionsDev:options);
Vue.use(BootstrapVue);
Vue.use(PerfectScrollbar);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
