var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'save-changes',
    { 'with-changes': _vm.getHistoryChanges.length > 0 },
    { beforeUnload: _vm.beforeUnload },
    { locked: !_vm.selectedPlanValue.isActive } ]},[_c('div',{staticClass:"relative-save"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideVersions),expression:"hideVersions"}],class:[
        'version',
        { 'old-version': _vm.savedVersion !== _vm.currentVersion.versionName } ],on:{"click":_vm.showVersions}},[_c('div',{staticClass:"version-icon-main"},[_vm._v("?")]),_vm._v(" "+_vm._s(_vm.$t("menu.version"))+" "+_vm._s(_vm.currentVersion.versionName.replace("v", ""))+" "),_c('div',{staticClass:"arrow-down"}),(_vm.visible)?_c('div',{attrs:{"id":"version-toast"}},_vm._l((_vm.versions),function(version,index){return _c('b-card-group',{key:'version_' + index},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('accordion-' + index),expression:"'accordion-' + index"}],attrs:{"block":""},on:{"click":function($event){return _vm.setCollapsedIndex(index)}}},[_c('div',{staticClass:"version-icon"},[_vm._v("?")]),_c('div',{staticClass:"btn-text"},[_vm._v(" "+_vm._s(_vm.$t("menu.version"))+_vm._s(version.versionName.replace("v", ""))+" ")]),_c('div',{class:_vm.collapsedIndex === index
                      ? 'arrow-up-btn'
                      : 'arrow-down-btn'})])],1),_c('b-collapse',{attrs:{"id":'accordion-' + index,"accordion":"my-accordion","role":"tabpanel","visible":version.versionName === _vm.currentVersion.versionName}},[_c('b-card-body',{on:{"click":function($event){return _vm.onChangeVersion(version)}}},[_c('b-card-text',[_vm._v(_vm._s(_vm.$t("menu.version") + version.versionName.replace("v", "") + _vm.$t("saveChanges.currentVersion2").replace("version", "") + _vm.$t("saveChanges.versionInfo")))]),_c('b-card-text',[_vm._v(_vm._s(version.description[_vm.$i18n.locale]))])],1)],1)],1)],1)}),1):_vm._e()]),(_vm.getHistoryChanges.length == 0)?_c('div',{staticClass:"activation-toggle",on:{"click":_vm.toBillingInfo}},[(!_vm.selectedPlanValue.isActive)?_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t("saveChanges.locked"))+" ")]):_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.getCurrentConfiguration.is_active ? _vm.$t("saveChanges.published") : _vm.$t("saveChanges.notPublished"))+" ")]),_c('div',{attrs:{"id":"version-tooltip"}},[_c('toggle-button',{staticClass:"toggle-button",attrs:{"color":"#398ff4","sync":true,"width":40,"value":_vm.getCurrentConfiguration.is_active},on:{"change":_vm.activationToggle}})],1),_c('b-tooltip',{staticClass:"custom-tootlip",attrs:{"target":"version-tooltip","placement":"top"}},[_vm._v(" "+_vm._s(_vm.$t("saveChanges.currentVersion") + _vm.currentVersion.versionName.replace("v", "") + _vm.$t("saveChanges.currentVersion2"))+" ")])],1):_vm._e(),(_vm.getHistoryChanges.length > 0)?_c('div',{staticClass:"changes-container"},[_c('div',{staticClass:"info"}),_c('div',{staticClass:"buttons"},[_c('div',{staticClass:"discard",on:{"click":_vm.discardChanges}},[_c('a',{staticClass:"icon"})]),_c('div',{staticClass:"update",class:{
            'is-active': _vm.getCurrentConfiguration.is_active,
            disabled: _vm.disabled,
          },attrs:{"id":"next-version-tooltip"},on:{"click":_vm.handleSaveChanges}},[_vm._v(" "+_vm._s(_vm.$t("saveChanges.saveChanges"))+" ")]),_c('b-tooltip',{staticClass:"custom-tootlip",attrs:{"target":"next-version-tooltip","placement":"top"}},[_vm._v(" "+_vm._s(_vm.$t("saveChanges.nextVersion").replace( "[version]", _vm.currentVersion.versionName.replace("v", "") ))+" ")]),_c('b-modal',{attrs:{"id":"change-version-modal","hide-footer":"","hide-header":"","size":"lg"},model:{value:(_vm.versionModal),callback:function ($$v) {_vm.versionModal=$$v},expression:"versionModal"}},[_c('div',{staticClass:"close",on:{"click":function () { return (_vm.versionModal = false); }}}),_c('div',{staticClass:"title"}),_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.$t("saveChanges.confirmChanges").replace( "[version]", _vm.currentVersion.versionName.replace("v", "") ))+" ")]),_c('div',{staticClass:"btn-confirm"},[_c('button',{staticClass:"button",on:{"click":_vm.saveChanges}},[_vm._v(" "+_vm._s(_vm.$t("layoutDesign.desktop.confirm"))+" ")])])])],1)]):_vm._e(),_c('div',{staticClass:"preview-container"},[_c('div',{staticClass:"preview",on:{"click":_vm.toPreview}},[_vm._v(" "+_vm._s(_vm.$t("saveChanges.seePreview"))+" "),_c('a',{staticClass:"icon"})]),_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tutorial"},[_c('a',{staticClass:"icon"})])}]

export { render, staticRenderFns }