<template>
  <PerfectScrollbar class="wizard-cms">
      <div class="title"> {{$t('wizard.cms.noCMS')}} </div>
      <div class="desc"> {{$t('wizard.cms.cmsPlugin')}} </div>
      <div class="ready-shop" v-if="getToBeConfiguredWebsites.length>0">
        <div class="label"> <span class="icon"></span> {{$t('wizard.cms.shopsReady')}} </div>
      </div>
      <div class="cms-list" @click="()=>selectCms(selectedCms.type)">
        <div :class="['list-item',cms,{'selected':selectedCms.type==cms},{'coming-soon':cmsToShow[cms][0] && cmsToShow[cms][0].version==''}]" v-for="(cms,index) in Object.keys(cmsToShow)" :key="'cms_'+index" @click.stop="()=>selectCms(cms)"> 
          <span class="icon"/> 
          <div class="label-container">
            <div class="label"> {{ cms }} </div>
            <div class="sub-label" v-if="cmsToShow[cms][0] && cmsToShow[cms][0].version==''"> {{$t('wizard.cms.comingSoon')}} </div>
          </div>
        </div>
      </div>
      <div id="version">
        <div class="version" v-if="selectedCms.type==='Shopify'">
          <div class="column">
            <div class="label"> {{$t('wizard.cms.shopifyWebsite')}} </div>
            <b-input v-model="shopifyWebsite" ></b-input>
          </div>
        </div>
        <div class="version" v-else-if="selectedCms.type==='Google Shopping'">
          <div class="column">
            <div class="label"> {{$t('wizard.cms.websiteUrl')}} </div>
            <b-input v-model="gsWebsite" ></b-input>
          </div>
          <div class="column">
            <div class="label"> {{$t('wizard.cms.languageShop')}} </div>
            <b-form-select v-model="gsLanguage" :options="languageOptions"></b-form-select>
          </div>
          <div class="column">
            <div class="label"> {{$t('wizard.cms.feedUrl')}} </div>
            <b-input v-model="feedUrl" @change="checkUrl"></b-input>
            <div class="label" v-if="!incorrectFeedUrl && !isPhysical"> {{$t('wizard.cms.syncTime')}} </div>
            <b-input type="time" v-model="syncTime" v-if="!incorrectFeedUrl && !isPhysical"/>
          </div>
        </div>
        <!-- <div class="version" v-else-if="selectedCms.type!='Custom Integration' && selectedCms.type!=''">
          <div class="column">
            <div class="label"> Select the CMS Version </div>
            <b-form-select v-model="selectedCms.version" :options="options"></b-form-select>
          </div>
          <div class="column">
            <div class="label"> Add the Plugin to your server </div>
            <button class="btn" :disabled="!selectedCms.version"> Download Plugin</button>
          </div>
        </div> -->
      </div>
      <div class="footer"> 
        <button @click="nextStep" class="btn" :disabled="disabled"> {{$t('wizard.cms.nextStep')}} </button> 
      </div>
  </PerfectScrollbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from '@/utils/Axios'
import Utils from '@/utils/Utils'
import lodash from 'lodash'

export default { 
  name: 'WizardCms',
  data(){
    return{
      selectedCms:{
        type:"",
        version:null
      },
      shopifyWebsite:"",
      gsWebsite:"",
      gsLanguage:"",
      feedUrl:"",
      cms:[],
      isPhysical:true,
      incorrectFeedUrl:false,
      syncTime:"01:00",
      feedLoading:false
    }
  },
  methods:{ 
    ...mapActions(['setCurrentConfigurationByField']),
    async nextStep(){
      this.setCurrentConfigurationByField({field:"cms",value:this.selectedCms});
      var baseUrl=axios.defaults.baseURL.replace("/API","");
      if(this.selectedCms.type==='Shopify') {
        await axios.post(baseUrl+Utils.INTEGRATIONS+Utils.SHP+'?'+Utils.SHOP_DOMAIN+'='+ this.shopifyWebsite.trim())
        .then(()=>{ 
          document.location.href=baseUrl+'/integrations/shp?shop='+ this.shopifyWebsite.trim();
        })
        .catch(()=>{
          this.$bvToast.toast(this.$t('wizard.cms.wentWrong'), {
            title: '',
            solid:true,
            variant:'danger'
          })
        })
      } else if(this.selectedCms.type==='Google Shopping') {
        var objectToSend={};
        objectToSend.shopUrl=this.gsWebsite;
        objectToSend.languageIso=this.gsLanguage;
        objectToSend.feedUrl=this.feedUrl;
        if(!this.isPhysical) objectToSend.preferredSynchronizationTime=this.syncTime;
        await axios.post(baseUrl+Utils.INTEGRATIONS+Utils.GOOGLE_SHOPPING,objectToSend)
        .then(()=>{ 
          this.$router.push({name:'wizard-shop'});
        })
        .catch(()=>{
          this.$bvToast.toast(this.$t('wizard.cms.wentWrong'), {
            title: '',
            solid:true,
            variant:'danger'
          })
        })
      }
      else this.$router.push({name:'wizard-shop'});
    },
    selectCms(cms){
      if(this.selectedCms.type==cms) this.selectedCms.type="";
      else {
        this.selectedCms.type=cms;
        setTimeout(()=>document.querySelector("#version").scrollIntoView({ block: 'start'}),10)
      }
      this.selectedCms.version=null;
    },
    async checkUrl(){
      this.feedLoading=true;
      await axios.get(this.feedUrl.trim(), {
        transformRequest: (data, headers) => {
          delete headers.common["X-Accelasearch-Apikey"]
          delete headers.common["X-Accelasearch-Jwt"]
          return data;
        }
      })
      .then(response=>{ 
        this.isPhysical=!!response.headers['last-modified'];
        this.incorrectFeedUrl=false;
      })
      .catch(err=>{
        console.log(err);
        this.incorrectFeedUrl=true;
      })
      .finally(()=>{
        this.feedLoading=false
      })
    }
  },
  computed:{
    ...mapGetters(['getCurrentConfiguration','getToBeConfiguredWebsites','getLanguages']),
    disabled(){
      return (this.selectedCms.type!=="" && this.selectedCms.type!=="Prestashop" && this.selectedCms.type!=="Custom Integration" && this.selectedCms.type!=="Google Shopping" && this.selectedCms.type!=="Shopify" && this.selectedCms.type!=="Storeden" && this.selectedCms.version===null) || 
        (this.selectedCms.type==="Shopify" && this.shopifyWebsite=="") ||
        (this.selectedCms.type==="Google Shopping" && (this.gsWebsite=="" || this.gsLanguage=="" || this.feedUrl=="")) 
    },
    cmsToShow(){
      var objToReturn={};
      this.cms.forEach(cms=>{
        if(!objToReturn[cms.label]) {
          objToReturn[cms.label]=[cms];
          objToReturn[cms.label][0].text=cms.version
          objToReturn[cms.label][0].value=cms.id
        } else {
          objToReturn[cms.label].push(cms);
          objToReturn[cms.label][objToReturn[cms.label].length-1].text=cms.version
          objToReturn[cms.label][objToReturn[cms.label].length-1].value=cms.id
        }
      })
      return objToReturn;
    },
    options(){
      var arrayToReturn=[{ value: null, text: '-- Select --', disabled:true }]
      this.cmsToShow[this.selectedCms.type].forEach(item=>arrayToReturn.push(item))
      return arrayToReturn
    },
    languageOptions(){
      var arrayToReturn=lodash.cloneDeep(this.getLanguages);
      arrayToReturn.splice(0,0,{value: null, text: '-- Select --', disabled:true})
      return arrayToReturn;
    }
  },
  mounted(){ 
    this.selectedCms=this.getCurrentConfiguration.cms;
    this.cms=Utils.CMS_LIST;
  }
}
</script>

<style src="./WizardCms.less" lang="less" scoped></style>