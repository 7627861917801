<template>
  <perfect-scrollbar
    class="layoutDesign-general"
    ref="layoutDesign-general"
    @ps-scroll-y="onScroll"
  >
    <div class="function-row">
      <div class="info-column">
        <div class="sub-title">{{ $t("layoutDesign.general.primary") }}</div>
        <div class="desc">{{ $t("layoutDesign.general.changePrimary") }}</div>
        <div class="label">{{ $t("layoutDesign.general.hex") }}</div>
        <div id="color-pick">
          <div
            class="color-square"
            :style="{ backgroundColor: hexColor }"
          ></div>
          <b-input
            type="text"
            :value="hexColor"
            @input="(value) => (color = value)"
            @change="(value) => (hexColor = value)"
          />
        </div>
        <b-popover
          target="color-pick"
          triggers="click blur"
          placement="bottom"
          @hide="onHide"
        >
          <template>
            <color-picker v-model="color" />
          </template>
        </b-popover>
      </div>
      <div class="img-column primary" ref="primary-row">
        <div :class="['img', scrollCheck($refs['primary-row'])]"></div>
      </div>
    </div>
    <div class="function-row facets">
      <div class="info-column">
        <div class="sub-title">{{ $t("layoutDesign.general.facets") }}</div>
        <div class="label">{{ $t("layoutDesign.general.basedFacets") }}</div>
        <div class="change-facets" @click="sortFacets = true">
          {{ $t("layoutDesign.general.changeFacets") }}
        </div>
      </div>
      <div class="img-column">
        <div class="img">
          <div class="label">{{ $t("layoutDesign.general.haveLook") }}</div>
          <div class="button" @click="facetsPreviews = true">
            {{ $t("layoutDesign.general.facetsPreview") }}
          </div>
        </div>
      </div>
    </div>
    <div class="function-row facets-detail">
      <div class="facets-value">
        <div
          class="facet-value"
          v-for="(facet, index) in Object.values(
            getCurrentConfiguration.layout.facets
          )"
          :key="'facet_item_' + index"
        >
          <div class="content">
            <div class="top-content">
              <b-checkbox
                :checked="facet.active"
                @change="(value) => checkboxClick(value, index)"
              />
              <b-input
                class="facet-label"
                :ref="'input_' + index"
                v-if="editLabelIndex == index"
                :value="facetLabel"
                maxlength="20"
                @input="(value) => onFacetKeyDown(value)"
                @focus="facetLabel = facet.label"
                @blur="() => onFacetLabelBlur(facet)"
              />
              <div class="facet-label" v-else>{{ facet.label }}</div>
              <div
                :class="['edit-label', { editing: editLabelIndex == index }]"
                @click="() => editClick(index)"
              >
                {{
                  editLabelIndex == index
                    ? $t("layoutDesign.general.editing")
                    : $t("layoutDesign.general.editLabel")
                }}
              </div>
            </div>
            <div class="bottom-content">
              <div class="left">
                <div class="tag">{{ facet.name }}</div>
                <div
                  class="inner-search"
                  v-if="facet.type !== 'slider' && facet.type !== 'rating'"
                >
                  <div class="label">
                    {{ $t("layoutDesign.general.innerSearch") }}
                  </div>
                  <toggle-button
                    class="toggle-button"
                    color="#398ff4"
                    :sync="true"
                    :width="50"
                    :value="facet.withSearch"
                    @change="(value) => withSearchToggle(facet, value)"
                  />
                </div>
              </div>
              <div class="right">
                <select
                  :value="facet.type"
                  @change="
                    (value) => {
                      pushHistoryChanges();
                      facet.type = value.target.value;
                    }
                  "
                  class="custom-select"
                  v-if="facet.name == 'sellingPrice'"
                >
                  <option
                    v-for="option in priceFacetTypeOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
                <select
                  :value="facet.type"
                  @change="
                    (value) => {
                      pushHistoryChanges();
                      facet.type = value.target.value;
                    }
                  "
                  class="custom-select"
                  v-else-if="facet.name == 'categories'"
                >
                  <option
                    v-for="option in categoriesFacetTypeOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
                <select
                  :value="facet.type"
                  @change="(value) => changeFacetType(value, facet)"
                  class="custom-select"
                  v-else
                >
                  <option
                    v-for="option in facetTypeOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
                <div
                  class="label"
                  v-if="
                    facet.type !== 'slider' &&
                      facet.type !== 'rating' &&
                      facet.name !== 'categories'
                  "
                >
                  {{ $t("layoutDesign.general.filterSorting") }}
                </div>
                <select
                  :value="facet.filterSorting"
                  @change="
                    (value) => {
                      pushHistoryChanges();
                      facet.filterSorting = value.target.value;
                    }
                  "
                  class="custom-select"
                  v-if="facet.type == 'alph-MC' || facet.type == 'alph-SC'"
                >
                  <option
                    v-for="option in alphabeticalFacetSortOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
                <select
                  :value="facet.filterSorting"
                  @change="
                    (value) => {
                      pushHistoryChanges();
                      facet.filterSorting = value.target.value;
                    }
                  "
                  class="custom-select"
                  v-else-if="
                    facet.type !== 'slider' &&
                      facet.type !== 'rating' &&
                      facet.name !== 'categories'
                  "
                >
                  <option
                    v-for="option in facetSortOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div
          class="facet-value dynamic-facets"
          v-if="
            getCurrentConfiguration.setup.config.dynamicFacets
              ? getCurrentConfiguration.setup.config.dynamicFacets.isActive
              : false
          "
        >
          <div class="content">
            <div class="top-content">
              <div class="facet-label">
                {{ $t("layoutDesign.general.dynamicFacets") }}
              </div>
              <div class="edit-label" @click="() => (labelsModal = true)">
                {{ $t("layoutDesign.general.importLabels") }}
              </div>
            </div>
            <div class="bottom-content">
              <div class="left">
                <div class="label">{{ $t("layoutDesign.general.style") }}</div>
                <b-select
                  :options="dynamicFacetsOptions"
                  :value="
                    dynamicFacetsLayout.dynamicType
                      ? dynamicFacetsLayout.dynamicType
                      : dynamicFacetsOptions[0]
                  "
                  @change="
                    (value) => setDynamicFacetLayout('dynamicType', value)
                  "
                />
                <div class="inner-search">
                  <div class="label">
                    {{ $t("layoutDesign.general.innerSearch") }}
                  </div>
                  <toggle-button
                    class="toggle-button"
                    color="#398ff4"
                    :width="50"
                    :sync="true"
                    :value="dynamicFacetsLayout.dynamicSearch"
                    @change="
                      (value) =>
                        setDynamicFacetLayout('dynamicSearch', value.value)
                    "
                  />
                </div>
              </div>
              <div class="right">
                <div class="label">
                  {{ $t("layoutDesign.general.filterSorting") }}
                </div>
                <b-select
                  :options="facetSortOptions"
                  :value="
                    dynamicFacetsLayout.dynamicSorting
                      ? dynamicFacetsLayout.dynamicSorting
                      : facetSortOptions[0]
                  "
                  @change="
                    (value) => setDynamicFacetLayout('dynamicSorting', value)
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="facet-value"
          v-if="
            ((getCurrentConfiguration.setup.dynamicFacets ? 1 : 0) +
              Object.values(getCurrentConfiguration.layout.facets).length) %
              2 ==
              1
          "
        ></div>
      </div>
    </div>
    <div
      class="available"
      v-if="!$options.isStartOrMore(Object.values(getSelectedPlan)[0].key)"
    >
      <span v-html="$t('layoutDesign.available')"> </span>
      <span class="icon"> </span>
    </div>
    <div class="function-row">
      <div class="info-column">
        <div class="sub-title">{{ $t("layoutDesign.general.sorting") }}</div>
        <div class="desc">{{ $t("layoutDesign.general.chooseThrough") }}</div>
        <div class="label">
          {{ $t("layoutDesign.general.enableDisable") }}
          <toggle-button
            class="toggle-button"
            color="#398ff4"
            :sync="true"
            :width="50"
            v-model="sortActive"
          />
        </div>
        <div class="sort-sortable">
          <draggable v-model="sortValues">
            <div
              v-for="(item, index) in sortValues"
              :key="index"
              :class="['drag-sortable', { default: !item.isDefault }]"
            >
              <div class="top-content">
                <div
                  class="delete"
                  v-if="!item.isDefault"
                  @click="deleteSort(index)"
                ></div>
                <div class="icon"></div>
                <b-checkbox
                  :checked="item.active"
                  @change="(value) => checkboxClick(value, index)"
                />
                <b-input
                  class="sort-label"
                  :ref="'input_sort_' + index"
                  v-if="sortLabelIndex == index"
                  :value="sortLabel"
                  maxlength="24"
                  @input="(value) => onSortKeyDown(value)"
                  @focus="sortLabel = item.label"
                  @blur="() => onSortLabelBlur(item)"
                >
                </b-input>
                <div class="sort-label" v-else>
                  {{
                    item.label +
                      (index == 0
                        ? " (" + $t("layoutDesign.general.default") + ")"
                        : "")
                  }}
                </div>
                <div
                  :class="['edit-label', { editing: sortLabelIndex == index }]"
                  @click.prevent.stop="() => editSortClick(index)"
                >
                  {{
                    sortLabelIndex == index
                      ? $t("layoutDesign.general.editing")
                      : $t("layoutDesign.general.editLabel")
                  }}
                </div>
              </div>
              <b-select
                v-if="!item.isDefault"
                :options="sortOptions"
                v-model="item.value.value"
              >
              </b-select>
              <div class="desc" v-if="item.isDefault">{{ item.desc }}</div>
              <div class="desc" v-else>
                {{ $t("layoutDesign.general.customSorting") }} <br />
                {{ $t("layoutDesign.general.youMay") }}
                <span class="refine" @click="openSortModal(item, index)">
                  {{ $t("layoutDesign.general.refine") }}
                </span>
                {{ $t("layoutDesign.general.rightOrder") }}
              </div>
            </div>
          </draggable>
        </div>
        <div class="add" @click="() => openSortModal(emptySort)">
          + {{ $t("layoutDesign.general.addCustomSorting") }}
        </div>
      </div>
      <div class="img-column sorting" ref="sorting-row">
        <div :class="['img', scrollCheck($refs['sorting-row'])]"></div>
      </div>
    </div>
    <div class="function-row">
      <div class="info-column">
        <div class="sub-title">
          {{ $t("layoutDesign.general.imagePlaceholder") }}
        </div>
        <div class="desc">{{ $t("layoutDesign.general.imageSpot") }}</div>
        <div class="label">{{ $t("layoutDesign.general.imageUrl") }}</div>
        <div class="img-input-container" :id="'img_placeholder'">
          <div class="img-placeholder"></div>
          <b-form-input
            type="text"
            :value="placeholder"
            :placeholder="$t('layoutDesign.general.imageUrl')"
            @change="onPlaceholderChange"
          />
        </div>
        <b-popover :target="'img_placeholder'" triggers="hover" placement="top">
          <img
            :src="placeholder"
            :alt="placeholder"
            @error="replaceByDefault"
          />
        </b-popover>
      </div>
      <div class="img-column placeholder" ref="placeholder-row">
        <div :class="['img', scrollCheck($refs['placeholder-row'])]"></div>
      </div>
    </div>
    <div class="function-row">
      <div
        :class="[
          'info-column',
          {
            disabled: !$options.isStartOrMore(
              Object.values(getSelectedPlan)[0].key
            ),
          },
        ]"
      >
        <div class="sub-title">
          {{ $t("layoutDesign.general.icebreakerFeature") }}
        </div>
        <div class="desc">{{ $t("layoutDesign.general.moreThanOne") }}</div>
        <div class="label">
          {{ $t("layoutDesign.general.enableIcebreaker") }}
          <toggle-button
            class="toggle-button"
            color="#398ff4"
            :sync="true"
            :width="50"
            :value="icebreaker.isActive"
            @change="activationToggle"
          />
        </div>
        <div class="label">{{ $t("layoutDesign.general.maximum") }}</div>
        <b-form-input
          type="number"
          :value="parseInt(icebreaker.number)"
          @change="onChange"
          min="1"
          max="6"
        />
      </div>
      <div class="img-column icebreaker" ref="icebreaker-row">
        <div :class="['img', scrollCheck($refs['icebreaker-row'])]"></div>
      </div>
    </div>
    <div class="function-row">
      <div class="info-column">
        <div class="sub-title">
          {{ $t("layoutDesign.general.voiceSearch") }}
        </div>
        <div class="desc">{{ $t("layoutDesign.general.setupGlobal") }}</div>
        <div class="label">
          {{ $t("layoutDesign.general.enableVoice") }}
          <toggle-button
            class="toggle-button"
            color="#398ff4"
            :sync="true"
            :width="50"
            v-model="isActive"
          />
        </div>
        <div v-if="isActive" :class="{ disabled: !isActive }">
          <b-radio
            :checked="getCurrentConfiguration.layout.language.languageType"
            value="default"
            @change="(value) => radioClick('default')"
          >
            {{ $t("layoutDesign.general.html5") }}
          </b-radio>
          <b-radio
            :checked="getCurrentConfiguration.layout.language.languageType"
            value="google"
            @change="(value) => radioClick('google')"
          >
            Google Voice Search
          </b-radio>
        </div>
        <div class="language" v-if="isActive" :class="{ disabled: !isActive }">
          <div class="label">
            {{ $t("layoutDesign.general.chooseLanguage") }}
          </div>
          <select v-model="language" class="custom-select">
            <option
              v-for="option in $options.languageOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="info-column">
        <div class="sub-title">{{ $t("layoutDesign.general.0Null") }}</div>
        <div class="desc">{{ $t("layoutDesign.general.decide") }}</div>
        <div class="label">
          <toggle-button
            class="toggle-button"
            color="#398ff4"
            :sync="true"
            :width="50"
            v-model="isZeroOrNull"
          />
          <!-- <toggle-button class="toggle-button" color="#398ff4" :sync="true" :width="50" :value="getCurrentConfiguration.layout.isZeroOrNull" @change="zeroOrNullToggle"/> -->
        </div>
        <div class="label">{{ $t("layoutDesign.general.placeholder") }}</div>
        <!-- <b-form-input v-model="getCurrentConfiguration.layout.zeroOrNull" /> -->
        <b-form-input v-model="zeroOrNull" />
      </div>
    </div>
    <b-modal
      id="sort-facets"
      hide-footer
      hide-header
      size="lg"
      v-model="sortFacets"
    >
      <div class="title">
        {{ $t("layoutDesign.general.changeFacetsOrder") }}
      </div>
      <div class="close" @click="sortFacets = false"></div>
      <div class="desc">{{ $t("layoutDesign.general.chooseOrder") }}</div>
      <div class="sortable">
        <draggable v-model="facets" :move="onMove">
          <div
            v-for="(item, index) in facets"
            :key="index"
            :class="['drag-sortable', { fixed: !item.active }]"
          >
            <div class="icon"></div>
            {{ item.label }}
          </div>
        </draggable>
      </div>
      <div class="label">{{ $t("layoutDesign.general.addNewFacets") }}</div>
      <div class="button" @click="sortFacets = false">
        {{ $t("layoutDesign.general.confirm") }}
      </div>
    </b-modal>
    <b-modal
      id="facets-preview"
      hide-footer
      hide-header
      size="lg"
      v-model="facetsPreviews"
    >
      <div class="title">{{ $t("layoutDesign.general.facetsLayout") }}</div>
      <div class="close" @click="facetsPreviews = false"></div>
      <div class="desc">{{ $t("layoutDesign.general.legend") }}</div>
      <div class="preview checkbox">
        <div class="top">
          <div class="icon"></div>
          <div class="info">
            <div class="title">{{ $t("layoutDesign.general.checkbox") }}</div>
            <div class="desc">
              {{ $t("layoutDesign.general.defaultFacet") }}
            </div>
          </div>
        </div>
        <div class="img"></div>
      </div>
      <div class="preview buttons">
        <div class="top">
          <div class="icon"></div>
          <div class="info">
            <div class="title">{{ $t("layoutDesign.general.buttons") }}</div>
            <div class="desc">{{ $t("layoutDesign.general.anotherWay") }}</div>
          </div>
        </div>
        <div class="img"></div>
      </div>
      <div class="preview alphabetical">
        <div class="top">
          <div class="icon"></div>
          <div class="info">
            <div class="title">
              {{ $t("layoutDesign.general.alphabetical") }}
            </div>
            <div class="desc">{{ $t("layoutDesign.general.aList") }}</div>
          </div>
        </div>
        <div class="img"></div>
      </div>
      <div class="preview rating">
        <div class="top">
          <div class="icon"></div>
          <div class="info">
            <div class="title">{{ $t("layoutDesign.general.rating") }}</div>
            <div class="desc">{{ $t("layoutDesign.general.stars") }}</div>
          </div>
        </div>
        <div class="img"></div>
      </div>
      <div class="preview slider">
        <div class="top">
          <div class="icon"></div>
          <div class="info">
            <div class="title">{{ $t("layoutDesign.general.range") }}</div>
            <div class="desc">{{ $t("layoutDesign.general.inputText") }}</div>
          </div>
        </div>
        <div class="img"></div>
      </div>
      <div class="button" @click="facetsPreviews = false">
        {{ $t("layoutDesign.general.gotIt") }}
      </div>
    </b-modal>
    <b-modal
      id="sort-modal"
      hide-footer
      hide-header
      size="lg"
      v-model="sortModal"
    >
      <div class="title">{{ $t("layoutDesign.general.refineSorting") }}</div>
      <div class="close" @click="() => closeSortModal(null)"></div>
      <div
        class="desc"
        v-html="
          $t('layoutDesign.general.refineSelecting', {
            var1: editingSort.label,
          })
        "
      ></div>
      <b-input
        :placeholder="
          $t('layoutDesign.general.searchAmong', {
            var1: getWebsiteAttributes.length,
          })
        "
        v-model="modalFilter"
        class="input"
      >
      </b-input>
      <perfect-scrollbar class="list">
        <b-form-checkbox-group
          v-model="selectedListComputed"
          class="checkbox-list"
        >
          <b-form-checkbox
            class="list-item"
            v-for="item in filteredList"
            :value="item"
            :key="item"
          >
            {{ item }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </perfect-scrollbar>
      <div class="divider">
        {{ $t("layoutDesign.general.chooseOrderRefine") }}
      </div>
      <perfect-scrollbar class="sort-sortable">
        <draggable v-model="selectedListComputed">
          <div
            v-for="(item, index) in selectedListComputed"
            :key="index"
            :class="['drag-sortable']"
          >
            <div class="top-content">
              <div class="icon"></div>
              <div class="sort-label">{{ item }}</div>
              <b-select
                :options="sortOptions"
                :value="
                  selectedListFiltered(item)[0]
                    ? selectedListFiltered(item)[0].sort
                    : ''
                "
                @change="(value) => changeSortItem(value, item)"
              ></b-select>
            </div>
          </div>
        </draggable>
      </perfect-scrollbar>
      <div class="button" @click="() => closeSortModal(editingSort)">
        {{ $t("layoutDesign.general.confirm") }}
      </div>
    </b-modal>
    <b-modal
      id="labels-modal"
      hide-footer
      hide-header
      size="lg"
      v-model="labelsModal"
    >
      <div class="title">{{ $t("layoutDesign.general.uploadLabels") }}</div>
      <div class="close" @click="() => (labelsModal = false)"></div>
      <div class="desc">{{ $t("layoutDesign.general.uploadCSV") }}</div>
      <div
        class="button download"
        @click="() => $options.downloadJSON2CSV(dynamicFacets, 'facets_labels')"
      >
        {{ $t("layoutDesign.general.downloadSample") }}
        <span class="icon"></span>
      </div>
      <input
        ref="upload"
        type="file"
        accept=".csv, text/csv"
        @input="pickFile"
      />
      <div
        class="upload-container"
        @click="() => $refs.upload.click()"
        v-if="
          getCurrentConfiguration.layout.dynamicFacets &&
            getCurrentConfiguration.layout.dynamicFacets.filename == ''
        "
      >
        <div class="icon"></div>
        <div
          class="desc"
          v-html="$t('layoutDesign.general.uploadCSVData')"
        ></div>
        <div class="sub-desc">{{ $t("layoutDesign.general.CSVOnly") }}</div>
      </div>
      <template v-else>
        <div class="file-container">
          <div class="uploaded">
            <div class="label">
              {{
                getCurrentConfiguration.layout.dynamicFacets
                  ? getCurrentConfiguration.layout.dynamicFacets.filename
                  : ""
              }}
            </div>
            <div class="remove" @click="removeCSV"></div>
            <div
              class="download"
              @click="
                () =>
                  $options.downloadJSON2CSV(
                    getCurrentConfiguration.layout.dynamicFacets
                      ? getCurrentConfiguration.layout.dynamicFacets.csvData
                      : {},
                    'facets_labels'
                  )
              "
            ></div>
          </div>
          <div class="label">{{ $t("layoutDesign.general.filePreview") }}</div>
          <perfect-scrollbar class="preview">
            <textarea
              readonly="readonly"
              disabled="disabled"
              rows="20"
              :value="
                getCurrentConfiguration.layout.dynamicFacets
                  ? $options.convertJsonToCSV(
                      getCurrentConfiguration.layout.dynamicFacets.csvData
                    )
                  : ''
              "
            />
          </perfect-scrollbar>
        </div>
      </template>
    </b-modal>
  </perfect-scrollbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import lodash from "lodash";
import Utils from "@/utils/Utils.js";
import axios from "@/utils/Axios";

export default {
  name: "LayoutDesignGeneral",
  isStartOrMore: Utils.isStartOrMore,
  downloadJSON2CSV: Utils.downloadJSON2CSV,
  convertCSVToJson: Utils.convertCSVToJson,
  convertJsonToCSV: Utils.convertJsonToCSV,
  languageOptions: [
    { text: "Afrikaans", value: "af-ZA" },
    { text: "Bahasa Indonesia", value: "id-ID" },
    { text: "Bahasa Melayu", value: "ms-MY" },
    { text: "Català", value: "ca-ES" },
    { text: "Čeština", value: "cs-CZ" },
    { text: "Deutsch", value: "de-DE" },
    { text: "English - Australia", value: "en-AU" },
    { text: "English - Canada", value: "en-CA" },
    { text: "English - India", value: "en-IN" },
    { text: "English - New Zealand", value: "en-NZ" },
    { text: "English - South Africa", value: "en-ZA" },
    { text: "English - United Kingdom", value: "en-GB" },
    { text: "English - United States", value: "en-US" },
    { text: "Español - Argentina", value: "es-AR" },
    { text: "Español - Bolivia", value: "es-BO" },
    { text: "Español - Chile", value: "es-CL" },
    { text: "Español - Colombia", value: "es-CO" },
    { text: "Español - Costa Rica", value: "es-CR" },
    { text: "Español - Ecuador", value: "es-EC" },
    { text: "Español - El Salvador", value: "es-SV" },
    { text: "Español - España", value: "es-ES" },
    { text: "Español - Estados Unidos", value: "es-US" },
    { text: "Español - Guatemala", value: "es-GT" },
    { text: "Español - Honduras", value: "es-HN" },
    { text: "Español - México", value: "es-MX" },
    { text: "Español - Nicaragua", value: "es-NI" },
    { text: "Español - Panamá", value: "es-PA" },
    { text: "Español - Paraguay", value: "es-PY" },
    { text: "Español - Perú", value: "es-PE" },
    { text: "Español - Puerto Rico", value: "es-PR" },
    { text: "Español - República Dominicana", value: "es-DO" },
    { text: "Español - Uruguay", value: "es-UY" },
    { text: "Español - Venezuela", value: "es-VE" },
    { text: "Euskara", value: "eu-ES" },
    { text: "Français", value: "fr-FR" },
    { text: "Galego", value: "gl-ES" },
    { text: "Hrvatski", value: "hr_HR" },
    { text: "IsiZulu", value: "zu-ZA" },
    { text: "Íslenska", value: "is-IS" },
    { text: "Italiano - Italia", value: "it-IT" },
    { text: "Italiano - Svizzera", value: "it-CH" },
    { text: "Magyar", value: "hu-HU" },
    { text: "Nederlands", value: "nl-NL" },
    { text: "Norsk bokmål", value: "nb-NO" },
    { text: "Polski", value: "pl-PL" },
    { text: "Português - Brasil", value: "pt-BR" },
    { text: "Português - Portugal", value: "pt-PT" },
    { text: "Română", value: "ro-RO" },
    { text: "Slovenčina", value: "sk-SK" },
    { text: "Suomi", value: "fi-FI" },
    { text: "Svenska", value: "sv-SE" },
    { text: "Türkçe", value: "tr-TR" },
    { text: "български", value: "bg-BG" },
    { text: "Pусский", value: "ru-RU" },
    { text: "Српски", value: "sr-RS" },
    { text: "한국어", value: "ko-KR" },
    { text: "中文 - 普通话 (中国大陆)", value: "cmn-Hans-CN" },
    { text: "中文 - 普通话 (香港)", value: "cmn-Hans-HK" },
    { text: "中文 - 中文 (台灣)", value: "cmn-Hant-TW" },
    { text: "中文 - 粵語 (香港)", value: "yue-Hant-HK" },
    { text: "日本語", value: "ja-JP" },
    { text: "Lingua latīna", value: "la" },
  ],
  EMPTY_SORT: {
    label: "",
    selectedList: [],
  },
  data() {
    return {
      sortFacets: false,
      facetsPreviews: false,
      sortModal: false,
      labelsModal: false,
      facetLabel: "",
      sortLabel: "",
      scrollTop: 0,
      editLabelIndex: -1,
      sortLabelIndex: -1,
      modalFilter: "",
      selectedList: [],
      sortIndex: -1,
      tempChoiceList: [],
      color: "",
      editingSort: {
        label: "",
        selectedList: [],
      },
      sortOptions: [
        { value: "ASC", text: this.$t("layoutDesign.general.ascending") },
        { value: "DESC", text: this.$t("layoutDesign.general.descending") },
      ],
      facetTypeOptions: [
        {
          value: "checkbox-MC",
          text: this.$t("layoutDesign.general.checkboxMulti"),
        },
        {
          value: "checkbox-SC",
          text: this.$t("layoutDesign.general.checkboxSingle"),
        },
        {
          value: "button-SC",
          text: this.$t("layoutDesign.general.buttonSingle"),
        },
        {
          value: "button-MC",
          text: this.$t("layoutDesign.general.buttonMulti"),
        },
        {
          value: "alph-MC",
          text: this.$t("layoutDesign.general.alphabeticalMulti"),
        },
        {
          value: "alph-SC",
          text: this.$t("layoutDesign.general.alphabeticalSingle"),
        },
        { value: "slider", text: this.$t("layoutDesign.general.slider") },
        {
          value: "rating",
          text: this.$t("layoutDesign.general.ratingNumeric"),
        },
      ],
      categoriesFacetTypeOptions: [
        {
          value: "leafs-MC",
          text: this.$t("layoutDesign.general.catCheckboxMulti"),
        },
        {
          value: "leafs-SC",
          text: this.$t("layoutDesign.general.catCheckboxSingle"),
        },
        {
          value: "tree-MC",
          text: this.$t("layoutDesign.general.treeMulti"),
        },
        { value: "tree-SC", text: this.$t("layoutDesign.general.treeSingle") },
      ],
      priceFacetTypeOptions: [
        { value: "slider", text: this.$t("layoutDesign.general.slider") },
      ],
      facetSortOptions: [
        { value: "results", text: this.$t("layoutDesign.general.number") },
        { value: "ASC", text: this.$t("layoutDesign.general.asc") },
        { value: "DESC", text: this.$t("layoutDesign.general.desc") },
      ],
      categoryTreeViewOptions: [
        { value: 1, text: this.$t("layoutDesign.general.leaves") },
        { value: 2, text: this.$t("layoutDesign.general.2levels") },
        { value: 3, text: this.$t("layoutDesign.general.3levels") },
      ],
      categoryLevelList: Utils.categoryLevelList,
      categoryLevelList1: [
        { value: 1, text: this.$t("layoutDesign.general.first") },
        { value: 2, text: this.$t("layoutDesign.general.second") },
        { value: 3, text: this.$t("layoutDesign.general.penultimate") },
      ],
      alphabeticalFacetSortOptions: [
        { value: "ASC", text: this.$t("layoutDesign.general.asc") },
        { value: "DESC", text: this.$t("layoutDesign.general.desc") },
      ],
      dynamicFacetsOptions: [
        {
          value: "checkbox-MC",
          text: this.$t("layoutDesign.general.checkboxMulti"),
        },
        {
          value: "checkbox-SC",
          text: this.$t("layoutDesign.general.checkboxSingle"),
        },
        {
          value: "button-SC",
          text: this.$t("layoutDesign.general.buttonSingle"),
        },
        {
          value: "button-MC",
          text: this.$t("layoutDesign.general.buttonMulti"),
        },
      ],
      filename: "",
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentConfiguration",
      "getWebsiteAttributes",
      "getHistoryChanges",
      "getSelectedPlan",
    ]),
    computedCategoryLevel: {
      get() {
        return this["getCurrentConfiguration"].layout &&
          this["getCurrentConfiguration"].layout.categoryLevel
          ? this["getCurrentConfiguration"].layout.categoryLevel.level
          : this.categoryTreeViewOptions[0].value;
      },
    },
    hexColor: {
      get() {
        return this["getCurrentConfiguration"].layout &&
          this["getCurrentConfiguration"].layout.primaryColor
          ? this["getCurrentConfiguration"].layout.primaryColor
          : "#398FF4";
      },
      set(value) {
        this.pushHistoryChanges();
        var layout = lodash.cloneDeep(this["getCurrentConfiguration"].layout);
        layout.primaryColor = value;
        this.setCurrentConfigurationByField({ field: "layout", value: layout });
      },
    },
    computedCategoryLevelChoiceList: {
      get() {
        return this["getCurrentConfiguration"].layout &&
          this["getCurrentConfiguration"].layout.categoryLevel
          ? this["getCurrentConfiguration"].layout.categoryLevel.choiceList
          : this.categoryLevelList1;
      },
      set(value) {
        if (this.tempChoiceList.length == 0)
          this.tempChoiceList = lodash.cloneDeep(value);
        else {
          this.pushHistoryChanges();
          var layout = lodash.cloneDeep(this.getCurrentConfiguration.layout);
          layout.categoryLevel.choiceList = lodash.cloneDeep(value);
          layout.categoryLevel.list = lodash.cloneDeep(this.tempChoiceList);
          this.setCurrentConfigurationByField({
            field: "layout",
            value: layout,
          });
          this.tempChoiceList = [];
        }
      },
    },
    computedCategoryLevelList: {
      get() {
        return this["getCurrentConfiguration"].layout &&
          this["getCurrentConfiguration"].layout.categoryLevel
          ? this["getCurrentConfiguration"].layout.categoryLevel.list
          : this.categoryLevelList[3];
      },
      set(value) {
        if (this.tempChoiceList.length == 0)
          this.tempChoiceList = lodash.cloneDeep(value);
        else {
          this.pushHistoryChanges();
          var layout = lodash.cloneDeep(this.getCurrentConfiguration.layout);
          layout.categoryLevel.choiceList = lodash.cloneDeep(
            this.tempChoiceList
          );
          layout.categoryLevel.list = lodash.cloneDeep(value);
          this.setCurrentConfigurationByField({
            field: "layout",
            value: layout,
          });
          this.tempChoiceList = [];
        }
      },
    },
    icebreaker() {
      return this["getCurrentConfiguration"].layout &&
        this["getCurrentConfiguration"].layout.icebreaker
        ? this["getCurrentConfiguration"].layout.icebreaker
        : {};
    },
    placeholder() {
      return this["getCurrentConfiguration"].layout &&
        this["getCurrentConfiguration"].layout.placeholder
        ? this["getCurrentConfiguration"].layout.placeholder
        : "";
    },
    sort() {
      return this["getCurrentConfiguration"].layout &&
        this["getCurrentConfiguration"].layout.sort
        ? this["getCurrentConfiguration"].layout.sort
        : {};
    },
    filteredList() {
      return this["getWebsiteAttributes"]
        ? this["getWebsiteAttributes"].filter((item) =>
            item.includes(this.modalFilter)
          )
        : [];
    },
    dynamicFacets() {
      var dataToReturn = lodash.cloneDeep(this.getWebsiteAttributes);
      dataToReturn = dataToReturn
        .filter(
          (item) =>
            !Object.keys(
              this.getCurrentConfiguration.setup.config.dynamicFacets
                .selectedList
            ).includes(item)
        )
        .filter(
          (item) =>
            !Object.keys(this.getCurrentConfiguration.setup.facets).includes(
              item
            )
        );
      return dataToReturn.map((item) => ({ facet: item, label: "" }));
    },
    selectedListComputed: {
      get() {
        return this.selectedList;
      },
      set(value) {
        this.pushHistoryChanges();
        var editingSortSelectedList = [];
        value.forEach((item) => {
          var selectedListFiltered = this.selectedListFiltered(item);
          if (selectedListFiltered.length == 0)
            editingSortSelectedList.push({
              label: item,
              sort: this.sortOptions[0].value,
            });
          else editingSortSelectedList.push(selectedListFiltered[0]);
        });
        this.editingSort.selectedList = lodash.cloneDeep(
          editingSortSelectedList
        );
        this.selectedList = value;
      },
    },
    emptySort() {
      var emptySort = lodash.cloneDeep(this.$options.EMPTY_SORT);
      emptySort.label = this.$t("layoutDesign.general.confirm", {
        var1: this["getCurrentConfiguration"].layout.sort.values.length,
      });
      emptySort.active = true;
      emptySort.isDefault = false;
      emptySort.value = this.sortOptions[0];
      return emptySort;
    },
    isZeroOrNull: {
      set(value) {
        this.pushHistoryChanges();
        var layout = lodash.cloneDeep(this["getCurrentConfiguration"].layout);
        layout.isZeroOrNull = value;
        this.setCurrentConfigurationByField({ field: "layout", value: layout });
      },
      get() {
        return this.getCurrentConfiguration.layout.isZeroOrNull;
      },
    },
    zeroOrNull: {
      set(value) {
        this.pushHistoryChanges();
        var layout = lodash.cloneDeep(this["getCurrentConfiguration"].layout);
        layout.zeroOrNull = value;
        this.setCurrentConfigurationByField({ field: "layout", value: layout });
      },
      get() {
        return this.getCurrentConfiguration.layout.zeroOrNull;
      },
    },
    isActive: {
      set(value) {
        this.pushHistoryChanges();
        var layout = lodash.cloneDeep(this.getCurrentConfiguration.layout);
        layout.language.isActive = value;
        this.setCurrentConfigurationByField({ field: "layout", value: layout });
      },
      get() {
        return this.getCurrentConfiguration.layout.language.isActive;
      },
    },
    sortActive: {
      set(value) {
        this.pushHistoryChanges();
        var layout = lodash.cloneDeep(this["getCurrentConfiguration"].layout);
        layout.sort.active = value;
        this.setCurrentConfigurationByField({ field: "layout", value: layout });
      },
      get() {
        return this.getCurrentConfiguration.layout.sort.active;
      },
    },
    language: {
      set(value) {
        this.pushHistoryChanges();
        var layout = lodash.cloneDeep(this["getCurrentConfiguration"].layout);
        layout.language.language = value;
        this.setCurrentConfigurationByField({ field: "layout", value: layout });
      },
      get() {
        return this.getCurrentConfiguration.layout.language.language;
      },
    },
    facets: {
      set(value) {
        this.pushHistoryChanges();
        var layout = lodash.cloneDeep(this["getCurrentConfiguration"].layout);
        layout.facets = value;
        this.setCurrentConfigurationByField({ field: "layout", value: layout });
      },
      get() {
        return this.getCurrentConfiguration.layout.facets.map((facet) => {
          var valueToReturn = facet;
          if (facet.active) valueToReturn.fixed = false;
          else valueToReturn.fixed = true;
          return valueToReturn;
        });
      },
    },
    sortValues: {
      set(value) {
        this.pushHistoryChanges();
        var layout = lodash.cloneDeep(this["getCurrentConfiguration"].layout);
        layout.sort.values = value;
        this.setCurrentConfigurationByField({ field: "layout", value: layout });
      },
      get() {
        return this.getCurrentConfiguration.layout.sort.values;
      },
    },
    dynamicFacetsLayout() {
      return this.getCurrentConfiguration.layout.dynamicFacets ?? {};
    },
  },
  methods: {
    ...mapActions(["setCurrentConfigurationByField", "setHistoryChanges"]),
    activationToggle(value) {
      this.pushHistoryChanges();
      var layout = lodash.cloneDeep(this["getCurrentConfiguration"].layout);
      layout.icebreaker.isActive = value.value;
      this.setCurrentConfigurationByField({ field: "layout", value: layout });
    },
    setDynamicFacetLayout(type, value) {
      this.pushHistoryChanges();
      var layout = lodash.cloneDeep(this.getCurrentConfiguration.layout);
      layout.dynamicFacets[type] = value;
      this.setCurrentConfigurationByField({ field: "layout", value: layout });
    },
    withSearchToggle(facet, value) {
      this.pushHistoryChanges();
      facet.withSearch = value.value;
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || relatedElement.active) && draggedElement.active
      );
    },
    onMoveCategoryLevel(evt) {
      const relatedElement = evt.relatedContext.element;
      const draggedElement = evt.draggedContext.element;
      // const min=Math.min(...evt.relatedContext.list.map(item=>item.value));
      // const max=Math.max(...evt.relatedContext.list.map(item=>item.value));
      const isNotUltimo = draggedElement.value != this.categoryLevelList.length;
      const isShorterThenList =
        evt.from.className == "choice-group" &&
        evt.to.className == "level-group" &&
        evt.relatedContext.list.length <
          this["getCurrentConfiguration"].layout.categoryLevel.level;
      const fromLevelToChoice =
        evt.from.className == "level-group" &&
        evt.to.className == "choice-group";
      const fromToSame = evt.from.className == evt.to.className;
      const isOrdered =
        relatedElement &&
        draggedElement &&
        ((relatedElement.value > draggedElement.value &&
          !evt.willInsertAfter) ||
          (relatedElement.value < draggedElement.value && evt.willInsertAfter));
      return (
        isNotUltimo &&
        isOrdered &&
        (isShorterThenList || fromLevelToChoice || fromToSame)
      );
    },
    onChange(value) {
      if (parseInt(value) >= 1 && parseInt(value) <= 8) {
        this.pushHistoryChanges();
        var layout = lodash.cloneDeep(this["getCurrentConfiguration"].layout);
        layout.icebreaker.number = value;
        this.setCurrentConfigurationByField({ field: "layout", value: layout });
      }
    },
    onPlaceholderChange(value) {
      this.pushHistoryChanges();
      var layout = lodash.cloneDeep(this["getCurrentConfiguration"].layout);
      layout.placeholder = value;
      this.setCurrentConfigurationByField({ field: "layout", value: layout });
    },
    checkboxClick(value, index) {
      this.pushHistoryChanges();
      var layout = lodash.cloneDeep(this["getCurrentConfiguration"].layout);
      layout.facets[index].active = value;
      this.setCurrentConfigurationByField({ field: "layout", value: layout });
    },
    radioClick(value) {
      this.pushHistoryChanges();
      var layout = lodash.cloneDeep(this["getCurrentConfiguration"].layout);
      layout.language.languageType = value;
      this.setCurrentConfigurationByField({ field: "layout", value: layout });
    },
    sortClick(value, index) {
      this.pushHistoryChanges();
      var layout = lodash.cloneDeep(this["getCurrentConfiguration"].layout);
      layout.sort[index].active = value;
      this.setCurrentConfigurationByField({ field: "layout", value: layout });
    },
    editClick(index) {
      if (this.editLabelIndex == index) this.editLabelIndex = -1;
      else {
        this.editLabelIndex = index;
        this.$nextTick(() => {
          this.$refs["input_" + index][0].focus();
        });
      }
    },
    editSortClick(index) {
      if (this.sortLabelIndex == index) this.sortLabelIndex = -1;
      else {
        this.sortLabelIndex = index;
        this.$nextTick(() => {
          this.$refs["input_sort_" + index][0].focus();
        });
      }
    },
    setList(value, index) {
      var valueIndex = this.selectedList.indexOf(value);
      if (valueIndex == -1) this.selectedList[index] = value;
      else delete this.selectedList[index];
    },
    openSortModal(editingSort, index = -1) {
      this.editingSort = lodash.cloneDeep(editingSort);
      this.selectedList = editingSort.selectedList.map((item) => item.label);
      this.sortIndex = index;
      this.sortModal = true;
    },
    onHide() {
      var color = this.color.hex ?? this.color;
      if (color != this.hexColor) this.hexColor = color;
    },
    closeSortModal(editingSort = null) {
      this.pushHistoryChanges();
      this.sortModal = false;
      var layout = lodash.cloneDeep(this["getCurrentConfiguration"].layout);
      if (editingSort && this.sortIndex >= 0) {
        layout.sort.values[this.sortIndex] = editingSort;
        this.setCurrentConfigurationByField({ field: "layout", value: layout });
      } else if (editingSort) {
        layout.sort.values.push(editingSort);
        this.setCurrentConfigurationByField({ field: "layout", value: layout });
      }
      this.editingSort = lodash.cloneDeep(this.$options.EMPTY_SORT);
      this.sortIndex = -1;
      this.selectedList = [];
    },
    selectedListFiltered(value) {
      return this.editingSort.selectedList.filter(
        (item) => value == item.label
      );
    },
    changeSortItem(value, item) {
      this.pushHistoryChanges();
      var editingSortSelectedList = [];
      this.editingSort.selectedList.forEach((subItem) => {
        if (subItem.label == item) subItem.sort = value;
        editingSortSelectedList.push(subItem);
      });
      this.editingSort.selectedList = editingSortSelectedList;
    },
    deleteSort(index) {
      this.pushHistoryChanges();
      var layout = lodash.cloneDeep(this["getCurrentConfiguration"].layout);
      layout.sort.values.splice(index, 1);
      this.setCurrentConfigurationByField({ field: "layout", value: layout });
    },
    pushHistoryChanges() {
      var historyChanges = lodash.cloneDeep(this.getHistoryChanges);
      historyChanges.push(lodash.cloneDeep(this.getCurrentConfiguration));
      this.setHistoryChanges(historyChanges);
    },
    onFacetKeyDown(value) {
      if (value.length >= 3) this.facetLabel = value;
    },
    onSortKeyDown(value) {
      if (value.length >= 3) this.sortLabel = value;
    },
    changeFacetType(value, facet) {
      this.pushHistoryChanges();
      facet.type = value.target.value;
      if (facet.type == "alph-MC" || facet.type == "alph-SC")
        facet.filterSorting = "ASC";
    },
    onFacetLabelBlur(facet) {
      if (this.facetLabel !== facet.label) this.pushHistoryChanges();
      facet.label = this.facetLabel;
      this.editLabelIndex = -1;
    },
    onSortLabelBlur(sort) {
      if (this.sortLabel !== sort.label) this.pushHistoryChanges();
      sort.label = this.sortLabel;
      this.sortLabelIndex = -1;
    },
    replaceByDefault(e) {
      e.target.src = require("@/assets/icons/img-not found.svg");
    },
    onScroll() {
      this.scrollTop = this.$refs["layoutDesign-general"]?.$el.scrollTop;
    },
    scrollCheck(img) {
      if (this.scrollTop < img?.offsetTop - 10) return "";
      if (
        this.scrollTop >
        img?.offsetTop +
          img?.offsetHeight -
          img?.childNodes[0].offsetHeight -
          10
      )
        return "scrolled";
      return "fixed";
    },
    onCategoryLevelSelect(value) {
      this.pushHistoryChanges();
      var layout = lodash.cloneDeep(this.getCurrentConfiguration.layout);
      if (!layout.categoryLevel) layout.categoryLevel = {};
      layout.categoryLevel.level = parseInt(value.target.value);
      if (value.target.value == this.categoryTreeViewOptions[0].value) {
        layout.categoryLevel.choiceList = [];
        layout.categoryLevel.list = [this.categoryLevelList[3]];
      } else if (value.target.value == this.categoryTreeViewOptions[1].value) {
        layout.categoryLevel.choiceList = [
          this.categoryLevelList[1],
          this.categoryLevelList[2],
        ];
        layout.categoryLevel.list = [
          this.categoryLevelList[0],
          this.categoryLevelList[3],
        ];
      } else if (value.target.value == this.categoryTreeViewOptions[2].value) {
        layout.categoryLevel.choiceList = [this.categoryLevelList[1]];
        layout.categoryLevel.list = [
          this.categoryLevelList[0],
          this.categoryLevelList[2],
          this.categoryLevelList[3],
        ];
      }
      this.setCurrentConfigurationByField({ field: "layout", value: layout });
    },
    pickFile(evt) {
      var file = evt.target.files[0];
      this.filename = file.name;
      var fr = new FileReader();
      fr.addEventListener("load", () => {
        var layout = lodash.cloneDeep(this["getCurrentConfiguration"].layout);
        layout.dynamicFacets.filename = this.filename;
        layout.dynamicFacets.csvData = Utils.convertCSVToJson(fr.result);
        this.setCurrentConfigurationByField({ field: "layout", value: layout });
        this.$root.$emit("save-changes");
        var objectToSend = {};
        layout.dynamicFacets.csvData.forEach(
          (item) => (objectToSend[item.facet] = item.label)
        );
        axios.post(
          Utils.SHOPS + "/" + this.getCurrentConfiguration.id + Utils.FACETS,
          { facets: objectToSend }
        );
        evt.target.value = "";
      });
      fr.readAsText(file, "ISO-8859-1");
    },
    removeCSV() {
      axios.delete(
        Utils.SHOPS + "/" + this.getCurrentConfiguration.id + Utils.FACETS
      );
      var layout = lodash.cloneDeep(this["getCurrentConfiguration"].layout);
      layout.dynamicFacets.filename = "";
      layout.dynamicFacets.csvData = [];
      this.setCurrentConfigurationByField({ field: "layout", value: layout });
      this.$root.$emit("save-changes");
      this.$refs.upload.value = "";
    },
  },
  mounted() {
    this.color = this.hexColor;
    console.log(this["getCurrentConfiguration"]);
    axios.get(
      Utils.SHOPS + "/" + this.getCurrentConfiguration.key + Utils.FACETS
    );
  },
};
</script>

<style src="./LayoutDesignGeneral.less" lang="less" scoped></style>
