<template>
  <div class="wizard-start">
      <div class="title"> {{$t('wizard.start.welcome')}} </div>
      <div class="desc"> {{$t('wizard.start.firstConf')}} </div>
      <b-form-select v-model="selected" :options="optionsToShow"></b-form-select>
      <div class="mandatory" v-if="showError && !selected"> {{$t('wizard.start.mandatory')}} </div>
      <button :class="['btn',{shadow:!selected}]" @click="startWizard"> {{$t('wizard.start.wizard')}} </button>
      <div class="img"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Utils from '@/utils/Utils'
import axios from '@/utils/Axios'

export default {
  name: 'WizardStart',
  data(){
    return {
      selected: null,
      showError:false,
      options: [
        { value: null, name: this.$t('wizard.start.choose'), disabled:true }
      ]
    }
  },
  computed:{
    ...mapGetters(['getCurrentConfiguration']),
    optionsToShow(){
      return this.options.map(item=>{
        var itemToChange=item;
        itemToChange.text=item.name;
        itemToChange.value=item.id;
        return itemToChange;
        })
    }
  },
  methods:{
    ...mapActions(['setCurrentConfigurationByField','setCurrentConfiguration']),
    startWizard(){
      if(!this.selected)
        this.showError=true
      else {
        this.setCurrentConfigurationByField({field:"sector",value:this.selected})
        this.$router.push({name:'wizard-cms'})
      }
    }
  },
  async mounted(){ 
    this.setCurrentConfiguration(Utils.newEmptyConfig());
    await axios(Utils.INDUSTRIES)
    .then(response=>{ this.options=this.options.concat(response.data); })
  }
}
</script>

<style src="./WizardStart.less" lang="less" scoped></style>