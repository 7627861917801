<template>
  <div id="notifications-modal" @click="closeModal">
    <div class="modal-container" @click.stop="">
      <div class="title">
        {{ $t("notificationsModal.notificationCentre") }}
        <span v-if="getNewNotificationCounter > 0" @click="readAll">
          {{ $t("notificationsModal.readAll") }}
        </span>
      </div>
      <perfect-scrollbar
        class="notifications-container"
        @ps-y-reach-end="onReachEnd"
      >
        <div
          :class="[
            'notification-item-container',
            { new: !notification.readAt },
            {'with-detail':notification.detail && notification.detail!=='' && Object.keys(notification.detail).length>0},
            fromCodeToParam(notification.code, 'status')
          ]"
          v-for="notification in getNotifications"
          :key="'notification_' + notification.id"
        >
          <div class="notification-item">
            <div class="created">
              {{ fromTimestapToTimeAgo(notification.createdAt * 1000) }}
              <span> {{ $t("notificationsModal.new") }} </span>
            </div>
            <div class="title">
              {{
                $t("notificationsModal." + fromCodeToParam(notification.code, "title"))
              }}
            </div>
            <div
              class="desc"
              v-html="
                $t(
                  'notificationsModal.' + getMessage(notification),
                  getPayload(notification)
                )
              "
            ></div>
            <div class="button-container">
              <div
                class="redirect"
                v-if="fromCodeToParam(notification.code, 'redirect') != ''"
                @click.stop="() => redirect(notification)"
              >
                {{ $t("notificationsModal.viewIssue") }}
              </div>
              <div class="to-detail" v-if="notification.detail && notification.detail!=='' && Object.keys(notification.detail).length>0" @click="() => goToDetail(notification)"> {{ $t("notificationsModal.detail") }} </div>
            </div>
          </div>
        </div>
        <div class="end-notifications" v-if="!endNotification">
          🎉 {{ $t("notificationsModal.endNotifications") }}
        </div>
      </perfect-scrollbar>
      <div class="no-notifications" v-if="getNotifications.length == 0">
        📭 {{ $t("notificationsModal.noNotification") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Utils from "../../utils/Utils";
import axiosInstance from "../../utils/Axios";
import lodash from "lodash";

export default {
  name: "NotificationsModal",
  data() {
    return {
      page: 1,
      loadingMore: false
    };
  },
  computed: {
    ...mapGetters([
      "getNotifications",
      "getNewNotificationCounter",
      "getWebsites",
      "getSelectedCampaign",
      "getHistoryChanges",
      "getDisplayedWebsites",
    ]),
    endNotification() {
      return this.getNotifications.length == this.page * Utils.NOTIFICATION_PAGE_SIZE;
    },
  },
  methods: {
    ...mapActions([
      "setNotificationModalVisible",
      "setNewNotificationCounter",
      "setWebsites",
      "setNotificationInterval",
      "setNotifications",
      "setShowSaveConfigModal",
      "setPendingWebsite",
      "setOpenedWebsitesList",
      "setCurrentConfiguration",
      "setNotificationDetailModalVisible",
      "setSelectedNotification"
    ]),
    async goToDetail(notificationToRead) {
      this.readNotification(notificationToRead.id);
      this.setSelectedNotification(lodash.cloneDeep(notificationToRead))
      this.setNotificationDetailModalVisible(true);
      this.closeModal();
    },
    async readNotification(notificationId) {
      await axiosInstance.post(Utils.NOTIFICATIONS + "/" + notificationId + Utils.READ);
      await this.checkNotificationCounter();
      this.setNotifications(
        this.getNotifications.map((notification) => {
          let valueToReturn = lodash.cloneDeep(notification);
          if (notification.id == notificationId)
            valueToReturn.readAt = new Date().getTime();
          return valueToReturn;
        })
      );
    },
    fromCodeToParam(code, param) {
      return Object.keys(Utils.NOTIFICATIONS_CODES).includes(code)?Utils.NOTIFICATIONS_CODES[code][param]:Utils.NOTIFICATIONS_CODES['generic-error'][param];
    },
    getMessage(notification) {
      return Object.keys(Utils.NOTIFICATIONS_CODES).includes(notification.code)?Utils.NOTIFICATIONS_CODES[notification.code].message:Utils.NOTIFICATIONS_CODES['generic-error'].message;
    },
    getPayload(notification) {
      var payload = Object.keys(Utils.NOTIFICATIONS_CODES).includes(notification.code)?Utils.NOTIFICATIONS_CODES[notification.code].payload:Utils.NOTIFICATIONS_CODES['generic-error'].payload;
      var objectToReturn = {};
      payload.forEach((key) => {
        if (key == "shopId") {
          var websitesArray = this.getWebsites.filter(
            (website) => website.id == notification.payload.shopId
          );
          objectToReturn.shopName = Utils.beautifyWebsite(
            websitesArray.length > 0 ? websitesArray[0].url : ""
          );
        }
        // message=message.replace('{'+key+'}',notification.payload[key])
        else objectToReturn.key = notification.payload[key];
      });
      return objectToReturn;
    },
    onReachEnd() {
      if (this.endNotification && !this.loadingMore) {
        this.page++;
        this.loadingMore = true;
        axiosInstance
          .get(
            Utils.NOTIFICATIONS +
              "?" +
              Utils.FROM +
              "=" +
              ((this.page - 1) * Utils.NOTIFICATION_PAGE_SIZE + 1) +
              "&" +
              Utils.SIZE +
              "=" +
              Utils.NOTIFICATION_PAGE_SIZE
          )
          .then((response) => {
            let notifications = lodash.cloneDeep(this.getNotifications);
            this.setNotifications([...notifications, ...response.data]);
            this.loadingMore = false;
          });
      }
    },
    async readAll() {
      await axiosInstance.post(Utils.NOTIFICATIONS + Utils.BULK + Utils.READ);
      await this.checkNotificationCounter();
      this.setNotifications(
        this.getNotifications.map((notification) => {
          let valueToReturn = lodash.cloneDeep(notification);
          if (notification.readAt == null) valueToReturn.readAt = new Date().getTime();
          return valueToReturn;
        })
      );
    },
    async closeModal() {
      this.setNotificationModalVisible(false);
      await this.checkNotificationCounter();
      var interval = setInterval(() => {
        this.checkNotificationCounter();
      }, Utils.NOTIFICATION_INTERVAL_GAP);
      this.setNotificationInterval(interval);
      this.page = 1;
    },
    checkNotificationCounter() {
      axiosInstance.get(Utils.NOTIFICATIONS + Utils.COUNT).then((response) => {
        this.setNewNotificationCounter(response.data.unread);
      });
    },
    async redirect(notification) {
      this.readNotification(notification.id);
      var redirectName = Utils.NOTIFICATIONS_CODES[notification.code].redirect;
      var payload = Utils.NOTIFICATIONS_CODES[notification.code].payload;
      var withShopId = payload.some((key) => key == "shopId");
      if (withShopId)
        await this.websiteClick(
          this.getWebsites.filter(
            (website) => website.id == notification.payload.shopId
          )[0]
        );
      this.$router.push({ name: redirectName });
      this.closeModal();
    },
    async websiteClick(website) {
      if (this.getSelectedCampaign) this.$root.$emit("before-change-website", website);
      else if (this.getHistoryChanges.length > 0) {
        this.setShowSaveConfigModal(true);
        this.setPendingWebsite(website);
      } else {
        this.setOpenedWebsitesList(false);
        await this.setCurrentConfiguration(
          lodash.clone(
            Utils.getConfigurationFromWebsite(this.getDisplayedWebsites, website)
          )
        );
        await axiosInstance.get(Utils.SHOPS).then((response) => {
          this.setWebsites(response.data);
        });
        if (Utils.checkConfiguring(website))
          this.$router.push({ name: "wizard-settings" });
        else {
          this.$root.$emit("changed-website");
        }
      }
    },
    fromTimestapToTimeAgo(time) {
      var difference = new Date().getTime() - time;
      return this.timeDifference(difference);
    },
    timeDifference(elapsed) {
      var msPerMinute = 60 * 1000;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;

      if (elapsed < msPerMinute)
        return this.$t("notificationsModal.sago", { var1: Math.round(elapsed / 1000) });
      else if (elapsed < msPerHour)
        return this.$t("notificationsModal.mago", {
          var1: Math.round(elapsed / msPerMinute),
        });
      else if (elapsed < msPerDay)
        return this.$t("notificationsModal.hago", {
          var1: Math.round(elapsed / msPerHour),
        });
      else if (elapsed < msPerMonth)
        return this.$t("notificationsModal.dago", {
          var1: Math.round(elapsed / msPerDay),
        });
      else if (elapsed < msPerYear)
        return this.$t("notificationsModal.moago", {
          var1: Math.round(elapsed / msPerMonth),
        });
      else
        return this.$t("notificationsModal.yago", {
          var1: Math.round(elapsed / msPerYear),
        });
    },
  },
};
</script>

<style src="./NotificationsModal.less" lang="less" scoped></style>
