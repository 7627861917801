<template>
  <perfect-scrollbar
    class="billing-plans"
    ref="ps"
    v-if="!selectedPlanValue.isCustom"
  >
    <b-modal
      id="plan-modal"
      hide-footer
      hide-header
      size="lg"
      @hide="closeModal"
    >
      <div class="close" @click="closeModal"></div>
      <div class="title">
        {{ $t("billing.plans.plan", { var1: selectingPlan }) }}
      </div>
      <div
        class="desc"
        v-html="
          $t('billing.plans.modalDesc', { var1: selectedPlanValue.discount })
        "
      ></div>
      <div class="footer">
        <div class="button btn discard" @click="closeModal">
          {{ $t("billing.plans.cancel") }}
        </div>
        <div
          @click="!isLoading && updatePlan(selectingPlan)"
          :class="{ button: true, btn: true, save: true, loading: isLoading }"
        >
          {{ $t("billing.plans.subscribePlan") }}
          <b-spinner label="Spinning" v-if="isLoading"></b-spinner>
        </div>
      </div>
    </b-modal>

    <div class="trial-container">
      <div class="current" v-if="selectedPlanValue.isActive">
        <div class="icon"></div>
        <div
          class="label"
          v-html="$t('billing.plans.currently', { var1: selectedPlanKey })"
        ></div>
      </div>
      <div
        class="full-service"
        v-if="selectedPlanKey == 'trial' && selectedPlanValue.isRegular"
      >
        {{
          $t("billing.plans.fullService", {
            var1: getDateFormatYesterday(
              selectedPlanValue.subscriptionTimestamp * 1000
            ),
          })
        }}
      </div>
      <!-- <div class="full-service" v-if="actualPlan=='trial' && selectedPlanKey!='trial'"> {{$t('billing.plans.upgraded',{var1:getDateFormat(selectedPlanValue.subscriptionTimestamp*1000),var2:selectedPlanKey})}} </div> -->
      <div
        class="full-service"
        v-else-if="
          !selectedPlanValue.pendingPlan &&
            selectedPlanValue.isActive &&
            !getBillingInfo.isDeactivating
        "
      >
        {{
          $t("billing.plans.renewed", {
            var1: getDateFormatTomorrow(selectedPlanValue.endTimestamp * 1000),
          })
        }}
      </div>
      <div class="info" v-if="selectedPlanValue.pendingPlan">
        <div class="icon"></div>
        <div
          class="label"
          v-html="
            $t('billing.plans.thankYou', {
              var1: selectedPlanValue.pendingPlan.title,
            })
          "
        ></div>
      </div>
      <!-- <div class="info" v-if="selectedPlanKey=='trial' && selectedPlanValue.pendingPlan">
        <div class="icon"></div>
        <div class="label" v-html="$t('billing.plans.upgraded',{var1:getDateFormat(selectedPlanValue.subscriptionTimestamp*1000),var2:selectedPlanValue.pendingPlan.planKey})"></div>
      </div> -->
      <div class="warning-container err" v-if="checkInfo">
        <div class="info-box">
          <div class="icon"></div>
          <div class="label">
            {{
              selectedPlanKey == "trial"
                ? $t("billing.plans.updateBillingInfo")
                : $t("billing.overview.updateBillingInfoDanger")
            }}
          </div>
        </div>
      </div>
      <div
        class="warning-container err"
        v-else-if="
          !selectedPlanValue.isRegular &&
            selectedPlanKey == 'trial' &&
            !selectedPlanValue.pendingPlan
        "
      >
        <div class="info-box">
          <div class="icon"></div>
          <div class="label">{{ $t("billing.plans.selectPlan") }}</div>
        </div>
      </div>
    </div>
    <div class="title-container">
      <div class="title">{{ $t("billing.plans.aPlan") }}</div>
      <div class="subtitle">{{ $t("billing.plans.withAccelaSearch") }}</div>
      <div class="type">
        <div class="monthly">{{ $t("billing.plans.monthly") }}</div>
        <toggle-button
          class="toggle-button"
          color="#398ff4"
          :sync="true"
          :width="50"
          v-model="isYearly"
        />
        <div class="yearly">{{ $t("billing.plans.yearly") }}</div>
      </div>
      <div class="subtitle">{{ $t("billing.plans.save") }}</div>
    </div>
    <div class="cards-container">
      <div
        :class="[
          'cards',
          {
            disabled:
              checkInfo ||
              selectedPlanValue.pendingPlan ||
              !selectedPlanValue.isAutomatic,
          },
        ]"
      >
        <div
          :class="[
            'card',
            {
              selected:
                selectedPlan == planKey &&
                isMonthly == !isYearly &&
                selectedPlanValue.isActive,
            },
          ]"
          v-for="planKey in Object.keys(plans)"
          :key="'plan_' + planKey"
        >
          <div
            class="recommended"
            v-if="recommendedPlan == planKey && selectedPlan != recommendedPlan"
          >
            {{ $t("billing.plans.recommended") }}
          </div>
          <div
            class="selected-tag"
            v-if="
              selectedPlan == planKey &&
                isMonthly == !isYearly &&
                selectedPlanValue.isActive
            "
          >
            {{
              selectedPlanValue.pendingPlan
                ? $t("billing.plans.selectedPlan")
                : $t("billing.plans.subscribedPlan")
            }}
          </div>
          <div class="title">{{ plans[planKey].title }}</div>
          <div class="desc">
            {{ $t("billing.plans." + plans[planKey].desc) }}
          </div>
          <div
            class="price"
            v-if="!plans[planKey].isCustom && planKey != 'enterprise'"
          >
            €
            <span>{{
              plans[planKey].price[isYearly ? "yearly" : "monthly"]
            }}</span>
            /{{
              isYearly ? $t("billing.plans.year") : $t("billing.plans.month")
            }}
            + {{ $t("billing.plans.vat") }}
          </div>
          <div class="price" v-else>{{ $t("billing.plans.searchEngine") }}</div>
          <div
            :class="['cta', 'contact-us']"
            v-if="
              (selectedPlanKey != 'trial' &&
                disabled(planKey) &&
                selectedPlanValue.isActive) ||
                planKey == 'enterprise'
            "
          >
            <a
              :href="
                'mailto:sales@accelasearch.com?subject=' +
                  $t('billing.plans.mailSubject') +
                  '&body=' +
                  $t('billing.plans.mailText', { var1: getUserInfo.email })
              "
            >
              {{ $t("billing.plans.contactUs") }}
            </a>
          </div>
          <div
            :class="['cta']"
            v-else-if="
              planKey != selectedPlan ||
                isMonthly == isYearly ||
                !selectedPlanValue.isActive
            "
            @click="selectPlan(planKey)"
          >
            {{ $t("billing.plans.subscribePlan") }}
          </div>
          <div :class="['cta', 'selected']" v-else @click="deselectPlan()">
            {{
              selectedPlanValue.pendingPlan
                ? $t("billing.plans.selected")
                : $t("billing.plans.subscribed")
            }}
          </div>
        </div>
      </div>
    </div>
    <b-collapse v-model="showMore" id="show-collapse">
      <perfect-scrollbar class="table-container">
        <b-table
          class="table"
          borderless
          responsive
          striped
          :items="items"
          :fields="fields"
        >
          <template #cell(func)="data">
            <div class="func">
              <div class="collapse-header">
                {{ data.item.func.label }}
                <span
                  :class="{ expanded: collapseList[data.item.func.label] }"
                  @click="
                    setCollapseItem(
                      !collapseList[data.item.func.label],
                      data.item.func.label
                    )
                  "
                ></span>
              </div>
              <b-collapse v-model="collapseList[data.item.func.label]">
                {{ data.item.func.desc }}
              </b-collapse>
            </div>
          </template>
          <template #cell()="data">
            <div class="value" v-if="typeof data.value == 'boolean'">
              <div class="check" v-if="data.value"></div>
              <div class="empty" v-else>-</div>
            </div>
            <div class="value" v-else>
              {{ data.value }}
            </div>
          </template>
        </b-table>
      </perfect-scrollbar>
    </b-collapse>
    <div class="show-more">
      <div class="line"></div>
      <div class="button" @click="toggleExpansion">
        {{
          showMore
            ? $t("billing.plans.hideTable")
            : $t("billing.plans.showTable")
        }}
      </div>
      <div class="line"></div>
    </div>
  </perfect-scrollbar>
  <perfect-scrollbar class="billing-plans" v-else>
    <div class="title-container">
      <div class="title">{{ $t("billing.plans.customPlan") }}</div>
      <div class="subtitle">{{ $t("billing.plans.changePlan") }}</div>
    </div>
    <div class="cards-container">
      <div class="cards">
        <div class="card selected">
          <div class="title">{{ selectedPlanValue.title }}</div>
          <div class="desc">{{ selectedPlanValue.desc }}</div>
          <div class="price">
            €
            <span>{{
              selectedPlanValue.price[
                selectedPlanValue.isMonthly ? "monthly" : "yearly"
              ]
            }}</span>
            /{{
              !selectedPlanValue.isMonthly
                ? $t("billing.plans.year")
                : $t("billing.plans.month")
            }}
            + {{ $t("billing.plans.vat") }}
          </div>
          <div class="cta selected">{{ $t("billing.plans.subscribed") }}</div>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</template>

<script>
import lodash from "lodash";
import axios from "@/utils/Axios";
import Utils from "@/utils/Utils";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "BillingPlans",
  data() {
    return {
      isYearly: false,
      showMore: false,
      isLoading: false,
      recommendedPlan: "",
      collapseList: {},
      plans: {},
      interval: null,
      fields: [
        {
          key: "func",
          label: this.$t("billing.plans.func"),
          stickyColumn: true,
        },
        { key: "little", label: "Little" },
        { key: "start", label: "Start" },
        { key: "standard", label: "Standard" },
        { key: "advanced", label: "Advanced" },
        { key: "enterprise", label: "Enterprise" },
      ],
      items: [
        {
          func: {
            label: this.$t("billing.plans.queryMonth"),
            desc: this.$t("billing.plans.queryMonthDesc"),
          },
          little: "1.000",
          start: "10.000",
          standard: "100.000",
          advanced: "250.000",
          enterprise: ">250.000",
        },
        {
          func: {
            label: this.$t("billing.plans.search"),
            desc: this.$t("billing.plans.searchDesc"),
          },
          little: true,
          start: true,
          standard: true,
          advanced: true,
          enterprise: true,
        },
        {
          func: {
            label: this.$t("billing.plans.advancedSearch"),
            desc: this.$t("billing.plans.advancedSearchDesc"),
          },
          little: true,
          start: true,
          standard: true,
          advanced: true,
          enterprise: true,
        },
        {
          func: {
            label: this.$t("billing.plans.customLayout"),
            desc: this.$t("billing.plans.customLayoutDesc"),
          },
          little: true,
          start: true,
          standard: true,
          advanced: true,
          enterprise: true,
        },
        {
          func: {
            label: this.$t("billing.plans.highFreq"),
            desc: this.$t("billing.plans.highFreqDesc"),
          },
          little: true,
          start: true,
          standard: true,
          advanced: true,
          enterprise: true,
        },
        {
          func: {
            label: this.$t("billing.plans.icebreakerFilters"),
            desc: this.$t("billing.plans.icebreakerFiltersDesc"),
          },
          little: false,
          start: true,
          standard: true,
          advanced: true,
          enterprise: true,
        },
        {
          func: {
            label: this.$t("billing.plans.showcase"),
            desc: this.$t("billing.plans.showcaseDesc"),
          },
          little: false,
          start: true,
          standard: true,
          advanced: true,
          enterprise: true,
        },
        {
          func: {
            label: this.$t("billing.plans.promotions"),
            desc: this.$t("billing.plans.promotionsDesc"),
          },
          little: false,
          start: false,
          standard: true,
          advanced: true,
          enterprise: true,
        },
        {
          func: {
            label: this.$t("billing.plans.visualMachine"),
            desc: this.$t("billing.plans.visualMachineDesc"),
          },
          little: false,
          start: false,
          standard: true,
          advanced: true,
          enterprise: true,
        },
        {
          func: {
            label: this.$t("billing.plans.b2bSearch"),
            desc: this.$t("billing.plans.b2bSearchDesc"),
          },
          little: false,
          start: false,
          standard: false,
          advanced: true,
          enterprise: true,
        },
        {
          func: {
            label: this.$t("billing.plans.apiAS"),
            desc: this.$t("billing.plans.apiASDesc"),
          },
          little: false,
          start: false,
          standard: false,
          advanced: true,
          enterprise: true,
        },
        {
          func: {
            label: this.$t("billing.plans.support"),
            desc: this.$t("billing.plans.supportDesc"),
          },
          little: false,
          start: false,
          standard: false,
          advanced: false,
          enterprise: true,
        },
        {
          func: {
            label: this.$t("billing.plans.slippage"),
            desc: this.$t("billing.plans.slippageDesc"),
          },
          little: this.$t("billing.plans.everyQuery", {
            var1: "1.000",
            var2: "5.00",
          }),
          start: this.$t("billing.plans.everyQuery", {
            var1: "5.000",
            var2: "15.00",
          }),
          standard: this.$t("billing.plans.everyQuery", {
            var1: "50.000",
            var2: "75.00",
          }),
          advanced: this.$t("billing.plans.everyQuery", {
            var1: "100.000",
            var2: "100.00",
          }),
          enterprise: "-",
        },
      ],
      selectingPlan: "",
      cards: [],
    };
  },
  computed: {
    ...mapGetters([
      "getSelectedPlan",
      "getUserInfo",
      "getTotalMonthly",
      "getBillingInfo",
    ]),
    selectedPlanValue() {
      return Object.values(this.getSelectedPlan)[0];
    },
    selectedPlanKey() {
      return Object.keys(this.getSelectedPlan)[0];
    },
    plansArray() {
      return this.fields.map((item) => item.key).slice(1);
    },
    selectedPlan() {
      return this.selectedPlanValue.pendingPlan
        ? this.selectedPlanValue.pendingPlan.planKey
        : this.selectedPlanKey;
    },
    isMonthly() {
      return this.selectedPlanValue.pendingPlan
        ? this.selectedPlanValue.pendingPlan.isMonthly
        : this.selectedPlanValue.isMonthly;
    },
    checkInfo() {
      return (
        Utils.checkBillingInfo(this.getBillingInfo) ||
        (this.selectedPlanValue.isAutomatic && this.cards.length == 0)
      );
    },
  },
  methods: {
    ...mapActions(["setSelectedPlan"]),
    setCollapseItem(value, index) {
      var objectToReturn = lodash.cloneDeep(this.collapseList);
      objectToReturn[index] = value;
      this.collapseList = objectToReturn;
    },
    toggleExpansion() {
      this.showMore = !this.showMore;
      // setTimeout(()=>this.$refs.ps.$el.scrollTop=document.getElementById("show-collapse").offsetTop,300)
    },
    selectPlan(planKey) {
      if (this.selectedPlanKey == "trial") this.updatePlan(planKey);
      else {
        this.selectingPlan = planKey;
        this.$bvModal.show("plan-modal");
      }
    },
    closeModal() {
      this.selectingPlan = "";
      this.$bvModal.hide("plan-modal");
    },
    async updatePlan(planKey) {
      this.isLoading = true;
      await axios.put(Utils.CUSTOMERS + Utils.ME + Utils.PLAN, {
        key: planKey,
        isMonthly: !this.isYearly,
      });
      axios
        .get(Utils.CUSTOMERS + Utils.ME + Utils.PLAN)
        .then((response) => this.setSelectedPlan(response.data));
      this.closeModal();
      this.isLoading = false;
    },
    disabled(planKey) {
      return (
        this.plansArray.indexOf(planKey) <
          this.plansArray.indexOf(this.selectedPlan) || planKey == "enterprise"
      );
    },
    getDateFormat(timestamp) {
      var momentFormat = "L";
      return moment(timestamp).format(momentFormat);
    },
    getDateFormatTomorrow(timestamp) {
      var momentFormat = "L";
      return moment(timestamp)
        .add(1, "days")
        .format(momentFormat);
    },
    getDateFormatYesterday(timestamp) {
      var momentFormat = "L";
      return moment(timestamp)
        .add(-1, "days")
        .format(momentFormat);
    },
    calcRecommended() {
      var given = moment(this.selectedPlanValue.periodStartTimestamp * 1000);
      var current = moment();
      //Difference in number of days
      var daysPassed = moment.duration(current.diff(given)).asDays();
      var totalMonthly = 4000;
      // var forecastedQueries=this.getTotalMonthly/daysPassed*30;
      var forecastedQueries = (totalMonthly / daysPassed) * 30;
      var plans = Object.values(this.plans)
        .filter((plan) => plan.queries.threshold > forecastedQueries)
        .sort((a, b) => b.queries.threshold - a.queries.threshold);
      this.recommendedPlan = plans[0]?.key;
    },
    async deselectPlan() {
      if (this.selectedPlanKey == "trial") {
        await axios.delete(
          Utils.CUSTOMERS + Utils.ME + Utils.PLAN + Utils.PENDING
        );
        await axios
          .get(Utils.CUSTOMERS + Utils.ME + Utils.PLAN)
          .then((response) => this.setSelectedPlan(response.data));
      }
    },
  },
  async mounted() {
    this.items.forEach((item) => (this.collapseList[item.func.label] = false));
    moment.locale(this.getUserInfo.languageIso);
    await axios.get(Utils.PLANS).then((reponse) => (this.plans = reponse.data));
    await axios
      .get(Utils.CUSTOMERS + Utils.ME + Utils.PAYMENT + Utils.METHODS)
      .then((response) => (this.cards = response.data));
    this.isYearly = this.selectedPlanValue.pendingPlan
      ? !this.selectedPlanValue.pendingPlan.isMonthly
      : !this.selectedPlanValue.isMonthly;
    this.calcRecommended();
    this.interval = setInterval(() => {
      axios
        .get(Utils.CUSTOMERS + Utils.ME + Utils.PLAN)
        .then((response) => this.setSelectedPlan(response.data));
    }, 10000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style src="./BillingPlans.less" lang="less" scoped></style>
